// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import { JSONApi, DataHelperResponse } from "Types/JSONApi";
import Referral from "Types/Referrals";

type Response = JSONApi<Referral[], undefined, undefined, undefined, undefined>;

/**
 * @async
 * Checks an address for LGAs
 * @param addressID ID of the address to check for LGAs
 * @returns a DataHelperResponse tuple of LGA referral response, Related, and the full JSON response
 * @throws Networking error
 */
const getLGAsForAddress = async (
	addressID: string,
): Promise<DataHelperResponse<Referral[], undefined, Response>> => {
	const response = await getReq<
		JSONApi<Referral[], undefined, undefined, undefined, undefined>
	>([
		`${import.meta.env.VITE_BASE_URL}executive/addresses/${addressID}/lgas`,
		1,
	]);

	const [lgas, related, fullResponse] = transformAPIResponse(response, []);
	return [lgas, related, fullResponse];
};

export default getLGAsForAddress;
