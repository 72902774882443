import React from "react";
import { Formik, Form } from "formik";

// CC
import FIGPasswordStrength from "CCW-Components/CCW-FIGPasswordStrength";
import FIGPassword from "CCW-Components/CCW-FIGPassword";
import Terms from "CCW-Components/CCW-Terms";

// Hooks
import useAlert from "Hooks/UseAlert";

// Helpers
import acceptInvite from "Helpers/NetworkingHelpers/AcceptInvite";
import isError from "Helpers/TypeHelpers/IsError";

// Styles
import { Submit, FormContainer, CheckItems } from "./styles";

// Types
import type SetFirstPasswordProps from "./types";

// Form Data
import { InitialValues, ValidationSchema } from "./FormData";

const SetFirstPasswordForm: React.FC<SetFirstPasswordProps> = ({
	token,
	onSuccess,
	...props
}) => {
	// Hooks
	const [, reportAlert] = useAlert();

	return (
		<FormContainer id="set-password-form" {...props}>
			<Formik
				initialValues={InitialValues}
				validationSchema={ValidationSchema}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					try {
						await acceptInvite({ ...values, token });
						reportAlert("Password set head to login", "success");
						onSuccess(true);
					} catch (err) {
						reportAlert(
							isError(err)
								? err.message
								: "Action failed. Please contact support",
							"error",
						);
					}
				}}>
				{({ isSubmitting }) => {
					return (
						<Form>
							<FIGPasswordStrength
								id="set-initial-password"
								name="password"
								label="Password"
								type="password"
							/>
							<FIGPassword
								id="set-initial-confirm-password"
								name="confirmPassword"
								label="Confirm Password"
								type="password"
							/>
							<CheckItems>
								<Terms id="terms" name="tc" />
								<Terms
									id="newsletter"
									name="newsletter"
									customTerms="receive the monthly newsletter"
								/>
								<Terms
									id="marketingEmails"
									name="marketingEmails"
									customTerms="receive our marketing emails"
								/>
							</CheckItems>
							<Submit
								variant="primary"
								type="submit"
								loading={isSubmitting}
								block>
								Submit
							</Submit>
						</Form>
					);
				}}
			</Formik>
		</FormContainer>
	);
};

export default SetFirstPasswordForm;
