import styled from "@emotion/styled";

// CC
import FormCard from "CCW-Components/CCW-FormCard";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const SetContainer = styled.div(() => ({
	width: "100%",
	height: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	marginTop: "2rem",
	[MediaQueries[1]]: {
		marginTop: "0",
		justifyContent: "center",
	},
}));

export const Card = styled.div(({ theme }) => ({
	marginTop: "4rem",
	boxShadow: theme.shadow,
	borderRadius: "1.2rem",
	minWidth: "100%",
	minHeight: "40.4rem",
	[MediaQueries[1]]: {
		minWidth: "44.5rem",
	},
}));

export const LoginCard = styled.div(() => ({
	marginTop: "4rem",
	minWidth: "90%",
	[MediaQueries[1]]: {
		minWidth: "44.5rem",
	},
}));

export const Title = styled(FormCard)(() => ({
	height: "auto",
}));
