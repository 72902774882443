// CC
import Backlink from "CCW-Components/CCW-Backlink";
import Container from "CCW-Components/CCW-Container";
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import Paywall from "CCW-Components/CCW-Paywall";
import PaywallProps from "CCW-Components/CCW-Paywall/types";

// Styles
import { Flex } from "./styles";

export const PaywallPage: React.FC<PaywallProps> = ({ feature, ...props }) => {
	return (
		<DefaultPage {...props}>
			<Container>
				<Flex>
					<Backlink to={"back"}>Back</Backlink>
					<Paywall feature={feature} />
				</Flex>
			</Container>
		</DefaultPage>
	);
};

export default PaywallPage;
