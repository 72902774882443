import React from "react";
import { useField } from "formik";
import { useNavigate } from "react-router-dom";

// Assets
import { BusinessIcon, HomeIcon, SchoolIcon } from "Assets/SVG";

// Styles
import { Container, Button } from "./styles";
import { Header } from "../../styles";

// Types
import AccountTypeStepProps from "./types";
import { useTheme } from "@emotion/react";
import Platform from "Types/Platform";
import IconProps from "Types/IconProps";

export const AccountTypeStep: React.FC<AccountTypeStepProps> = ({ to }) => {
	// Hooks
	const theme = useTheme();
	const [, , accountTypeHelpers] = useField("accountType");
	const navigate = useNavigate();

	const options: { platform: Platform; icon: React.FC<IconProps> }[] = [
		{ platform: "home", icon: HomeIcon },
		{ platform: "business", icon: BusinessIcon },
		{ platform: "school", icon: SchoolIcon },
	];

	// Normal state
	return (
		<>
			<Container>
				<Header>Account Type</Header>
				<div className="flex flex-wrap justify-center gap-4 md:gap-8">
					{options.map(({ icon: Icon, platform }) => (
						<div
							key="option"
							className="flex flex-col items-center gap-3 rounded-2xl bg-neutral-50 py-11 px-16 md:gap-6 md:py-24 md:px-28
                            ">
							<Icon width="60" fill={theme.colors.Blueberry400} />
							<span className="text-f4 font-bold capitalize text-blueberry-400">
								{platform}
							</span>
							<Button
								className="mt-6"
								colorVariant="blueberry"
								border
								type="button"
								onClick={() => {
									accountTypeHelpers.setValue(platform);
									navigate(to);
								}}>
								Select
							</Button>
						</div>
					))}
				</div>
			</Container>
		</>
	);
};

export default AccountTypeStep;
