import styled from "@emotion/styled";

// Styles
export const Container = styled.div({
	display: "flex",
	flexDirection: "column",
	gap: "2rem",
});

export const AddressBox = styled.div(({ theme }) => ({
	borderBottom: `0.1rem solid ${theme.colors.D4}`,
	padding: "1rem 0",
	":first-of-type": {
		paddingTop: 0,
	},
	":last-of-type": {
		borderBottom: "none",
	},
}));

export const SiteName = styled.span(({ theme }) => ({
	...theme.webFonts.F5,
}));

export const AlignRight = styled.div({
	display: "flex",
	justifyContent: "end",
});
