import React, { useEffect } from "react";

// CC
import BlockLoader from "CCW-Components/CCW-BlockLoader";

// Hooks
import useQueries from "Hooks/UseQueries";
import verifyEmail from "Helpers/NetworkingHelpers/VerifyEmail";

// Helpers

// Types
import CheckTokenProps from "./types";

const CheckToken: React.FC<CheckTokenProps> = ({ handleResult }) => {
	// Hooks
	const { token } = useQueries(["token"]);

	useEffect(() => {
		const validate = async () => {
			try {
				if (!token) {
					handleResult("redirect");
					return;
				}
				await verifyEmail(token);
				handleResult("verified");
			} catch (err) {
				handleResult("unverified");
			}
		};
		validate();
	}, [handleResult, token]);

	return <BlockLoader message="Checking email..." />;
};

export default CheckToken;
