// Helpers
import postReq from "Helpers/NetworkingHelpers/PostReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import { JSONApi, DataHelperResponse, Create } from "Types/JSONApi";
import User from "Types/User";

type InviteAcceptanceDetails = {
	token: string;
	password: string;
	confirmPassword: string;
	tc: boolean;
	newsletter: boolean;
	marketingEmails: boolean;
};

type Relationships = {
	accounts: {
		links: { related: string };
		data: { type: "accounts"; id: string }[];
	};
};

type Response = JSONApi<User, Relationships, undefined, undefined, undefined>;

/**
 * @async
 * Accepts an invite for a user
 * @param inviteAcceptanceDetails User's details submitted upon acceptance
 * @returns a DataHelperResponse tuple of User and the full response
 * @throws Networking error
 */
const acceptInvite = async (
	inviteAcceptanceDetails: InviteAcceptanceDetails,
): Promise<DataHelperResponse<User, undefined, Response>> => {
	const requestBody: Create<InviteAcceptanceDetails> = {
		data: {
			type: "users",
			attributes: inviteAcceptanceDetails,
			relationships: undefined,
			meta: undefined,
		},
	};
	const response = await postReq<
		JSONApi<User, Relationships, undefined, undefined, undefined>
	>(
		`${import.meta.env.VITE_BASE_URL}executive/auth/accept-invite`,
		requestBody,
		1,
	);

	const [acceptedUser, related, fullResponse] = transformAPIResponse(
		response,
		{},
	);
	return [acceptedUser, related, fullResponse];
};

export default acceptInvite;
