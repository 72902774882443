import React, { useEffect, useMemo } from "react";
import { Formik, Form } from "formik";
import { Route, useLocation, useNavigate } from "react-router-dom";

// Hooks
import useAlert from "Hooks/UseAlert";

// Helpers
import signUp from "Helpers/NetworkingHelpers/SignUp";
import isError from "Helpers/TypeHelpers/IsError";
import { track } from "Helpers/TrackingHelpers/Events";
import updateSubscription from "Helpers/NetworkingHelpers/UpdateSubscription";
import createPaymentMethod from "Helpers/NetworkingHelpers/CreatePaymentMethod";
import updateAccount from "Helpers/NetworkingHelpers/UpdateAccount";

// CC Components
import TransitionRoutes from "CCW-Components/CCW-TransitionRoutes";
import PLink from "CCW-Components/CCW-PLink";

// Local Components
import UserDetailsStep from "../UserDetailsStep";
import PasswordStep from "../PasswordStep";
import OrganisationDetailsStep from "../OrganisationDetailsStep";
import AddressStep from "../AddressStep";
import PartnerPicker from "../PartnerPicker";
import FeedbackStep from "../FeedbackStep";
import SubmittingStep from "../SubmittingStep";
import SubscriptionStep from "../SubscriptionStep";
import CreditCardStep from "../CreditCardStep";
import AccountTypeStep from "../AccountTypeStep";

// Form Login
import { makeInitValues, stepValidations, FormValues } from "./FormLogic";

// Types
import type { SignUpWizardProps } from "./types";
import type SignUpDetails from "Helpers/NetworkingHelpers/SignUp/Types";

const SignUpWizard: React.FC<SignUpWizardProps> = ({
	onProgressChange,
	onSuccess,
	referral,
	accountType,
	isReferralError,
}) => {
	// Hooks
	const [, reportAlert] = useAlert();
	const location = useLocation();
	const navigate = useNavigate();

	const currentQuestionIndex =
		Number(location.pathname.split("/").slice(-1)[0]) - 1;

	// Helpers
	const serializeValues = (formValues: Partial<FormValues>): SignUpDetails =>
		({
			fName: formValues.fName,
			lName: formValues.lName,
			email: formValues.email,
			password: formValues.password,
			tc: formValues.tc,
			confirmPassword: formValues.confirmPassword,
			addressId: formValues.address?._id,
			marketingEmails: formValues.marketingEmails,
			newsletter: formValues.newsletter,
			...(formValues.organisationName && {
				name: formValues.organisationName,
				entityName: formValues.entityName,
			}),
			...(formValues.siteEmail && {
				phone: formValues.phone,
				siteEmail: formValues.siteEmail,
			}),
			...(accountType !== "home" && {
				phone:
					formValues.phone?.at(3) === "0"
						? formValues.phone.replace("0", "")
						: formValues.phone,
				salesCalls: true,
			}),
			lga:
				formValues.lga ||
				(referral?.type === "lga" && referral._id) ||
				undefined,
			referralCode:
				referral?.type !== "lga" && referral?.referralCode
					? referral.referralCode
					: undefined,
			accountType: formValues.accountType,
		} as SignUpDetails);

	const steps: {
		name: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		component: React.FC<any>;
		nextStep?:
			| string
			| { withPartner?: string; withoutPartner?: string }
			| { withCreditCard?: string; withoutCreditCard?: string }
			| { withHomeAccountType?: string; withoutHomeAccountType?: string };
		fullWidth?: boolean;
	}[] = useMemo(() => {
		const generateUrl = (stepNumber: number) => `/sign-up/${stepNumber}`;

		return [
			{
				name: "User Details",
				component: UserDetailsStep,
				nextStep: generateUrl(accountType ? 3 : 2),
			},
			{
				name: "Account Type",
				component: AccountTypeStep,
				nextStep: generateUrl(3),
				fullWidth: true,
			},
			{
				name: "Password",
				component: PasswordStep,
				nextStep: {
					withHomeAccountType: generateUrl(5),
					withoutHomeAccountType: generateUrl(4),
				},
			},
			{
				// Business and School
				name: "Organisation Details",
				component: OrganisationDetailsStep,
				nextStep: { withPartner: "/sign-up/6", withoutPartner: "/sign-up/7" },
			},
			{
				// Home only
				name: "Address",
				component: AddressStep,
				nextStep: {
					withPartner: generateUrl(6),
					withoutPartner: generateUrl(7),
				},
			},
			{
				name: "Partner Picker",
				component: PartnerPicker,
				nextStep: generateUrl(7),
			},
			{
				name: "Subscription",
				component: SubscriptionStep,
				nextStep: {
					withCreditCard: "/sign-up/8",
					withoutCreditCard: "/sign-up/9",
				},
				fullWidth: true,
			},
			{
				name: "Credit Card",
				component: CreditCardStep,
				nextStep: generateUrl(9),
			},
			{
				name: "Feedback",
				component: FeedbackStep,
				nextStep: generateUrl(10),
			},
			{
				name: "Submitting",
				component: SubmittingStep,
			},
		];
	}, [accountType]);

	// Code to run when step changes
	useEffect(() => {
		if (
			typeof currentQuestionIndex !== "number" ||
			isNaN(currentQuestionIndex) ||
			currentQuestionIndex < 0 ||
			currentQuestionIndex > steps.length - 1
		) {
			navigate("/sign-up/1");
			return;
		}

		const currentStepName = steps[currentQuestionIndex].name;

		onProgressChange &&
			onProgressChange(((currentQuestionIndex + 1) / steps.length) * 100);

		track(`SignUp: ${currentStepName}`);
	}, [currentQuestionIndex, navigate, onProgressChange, steps]);

	return (
		<Formik
			initialValues={makeInitValues(referral?.referralCode, accountType)}
			validationSchema={stepValidations[currentQuestionIndex]}
			onSubmit={async (values, { setSubmitting }) => {
				try {
					if (currentQuestionIndex !== steps.length - 1) return;
					const serializedValues = serializeValues(values);
					const [, , fullSignUpResponse] = await signUp(
						serializeValues(values),
					);

					if (values.plan !== "free") {
						const newAccountID =
							fullSignUpResponse?.data.relationships.accounts.data[0].id;

						// This is dirty but it's the only way to get the subID without fetching
						// The subscriptionURL has this shape: :base/executive/accounts/:accountID/subscriptions/:subscriptionID
						const subscriptionURL = fullSignUpResponse?.included.find(
							(singleInclude) => singleInclude.type === "accounts",
						)?.relationships.subscription.links.related;
						const newSubscriptionID =
							subscriptionURL?.split("/")?.[
								subscriptionURL.split("/").length - 1
							];
						if (
							values.stripeToken &&
							values.billingPeriod &&
							values.billingEmail &&
							newAccountID &&
							newSubscriptionID
						) {
							await updateAccount(newAccountID, {
								billingEmail: values.billingEmail,
							});
							await createPaymentMethod(newAccountID, {
								token: values.stripeToken,
								isDefault: true,
							});
							await updateSubscription(newAccountID, newSubscriptionID, {
								tier: values.plan,
								billingPeriod: values.billingPeriod,
							});
						} else {
							throw "Credit card details failed, please contact support to upgrade your subscription";
						}
					}
					onSuccess && onSuccess(serializedValues);
					navigate("/welcome");
				} catch (err) {
					navigate("/sign-up/1");
					setSubmitting(false);
					reportAlert(
						isError(err)
							? err.message
							: "Action failed. Please contact support",
						"error",
					);
				}
			}}>
			<Form>
				<TransitionRoutes configuration="animateAllRouteChanges">
					{steps.map((step, index) => (
						<Route
							key={step.name}
							path={`/${index + 1}`}
							element={
								<div
									className={`mx-auto ${
										step.fullWidth ? "" : "max-w-4xl px-6"
									}`}>
									{isReferralError && (
										<p className="mt-0 text-center text-base text-warning">
											There is an issue with your referral code.
											<br></br>
											<span className="text-base">
												Please contact us at{" "}
												<PLink to={"mailto:support@climateclever.org"}>
													support@climateclever.org{" "}
												</PLink>{" "}
												.
											</span>
										</p>
									)}
									<step.component to={step.nextStep} />
								</div>
							}
						/>
					))}
				</TransitionRoutes>
			</Form>
		</Formik>
	);
};

export default SignUpWizard;
