import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// CC
import CCButtonLink from "CCW-Components/CCW-ButtonLink";
import PLink from "CCW-Components/CCW-PLink";

// Styles
export const Container = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
}));

export const Card = styled.div(({ theme }) => ({
	borderRadius: "1.2rem",
	boxShadow: theme.shadow,
	padding: "4.5rem 3rem 4rem",
	width: "47.6rem",
	maxWidth: "100%",
	marginBottom: "3rem",
	background: theme.colors.White,
}));

export const Button = styled(CCButtonLink)(() => ({
	marginTop: "2rem",
}));

export const LeadInText = styled.p(({ theme }) => ({
	...theme.webFonts.F6,
	marginBottom: "3rem",
	padding: "0 3rem",
	textAlign: "center",
	zIndex: 1,
}));

export const Highlighted = styled.span(({ theme }) => ({
	color: theme.colors.primary,
	...theme.webFonts.bold,
}));

export const LoginText = styled.p(({ theme }) => ({
	...theme.webFonts.F6,
	color: theme.colors.D3,
	margin: "0 0 3.5rem",
	zIndex: 1,
}));

export const Link = styled(PLink)(({ theme }) => ({
	...theme.webFonts.F5Bold,
	textDecoration: "none",
}));

export const InternationalSection = styled.div(({ theme }) => ({
	display: "flex",
	marginBottom: "2rem",
	[MediaQueries[0]]: {
		padding: "0 3rem",
	},
	"& > svg": {
		minWidth: "2.8rem",
	},
	"& > p": {
		margin: "0",
		paddingLeft: "0.5rem",
		...theme.webFonts.F6,
		color: theme.colors.D3,
		[MediaQueries[0]]: {
			textAlign: "center",
		},
		"& > span": {
			color: theme.colors.D1,
			...theme.webFonts.bold,
		},
		"& > a": {
			cursor: "pointer",
			color: theme.colors.primary,
			textDecoration: "none",
			...theme.webFonts.bold,
			"&:hover": {
				opacity: "0.5",
			},
		},
	},
}));
