import styled from "@emotion/styled";

// CC
import CloseButtonLink from "CCW-Components/CCW-CloseButtonLink";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// Types
import SelectedSiteProps from "./types";

export const Backdrop = styled.div(({ theme }) => ({
	position: "fixed",
	width: "100vw",
	height: "100vh",
	top: "0",
	left: "0",
	zIndex: 1000,
	background: theme.colors.Black50,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
}));

export const Container = styled.div(({ theme }) => ({
	background: theme.colors.White,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	width: "34.4rem",
	padding: "2rem",
	borderRadius: "1.5rem",
	[MediaQueries[0]]: {
		justifyContent: "flex-start",
		width: "68.4rem",
		height: "auto",
		boxShadow: `0.5rem 0.5rem 3rem ${theme.colors.Black50}`,
		padding: "2.1rem 3rem 3rem 3rem",
	},
	"& > span": {
		alignSelf: "end",
	},
}));

export const Header = styled.div(() => ({
	width: "100%",
	padding: "0 0.5rem 3.3rem 0",
}));

export const FinishedMessage = styled.div(({ theme }) => ({
	...theme.webFonts.F4,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
}));

export const Title = styled.h1(({ theme }) => ({
	...theme.webFonts.F3Bold,
	color: theme.colors.primary,
	margin: "0",
	textAlign: "center",
	[MediaQueries[1]]: {
		...theme.webFonts.F2Bold,
	},
}));

export const CloseButton = styled(CloseButtonLink)(() => ({
	width: "fit-content",
	[MediaQueries[1]]: {
		paddingRight: "0",
	},
	"& > a ": {
		width: "2.8rem",
		height: "2.8rem",
		"& > svg": {
			width: "2rem",
		},
	},
}));

export const SiteList = styled.ul(({ theme }) => ({
	maxHeight: "34.2rem",
	overflowY: "auto",
	padding: "0",
	width: "30rem",
	borderRadius: "0.8rem",
	[MediaQueries[0]]: {
		width: "100%",
	},
	"& > li:not(:last-of-type)": {
		borderBottom: `1px solid ${theme.colors.Black15}`,
	},
}));

export const SiteItem = styled.li<SelectedSiteProps>(({ selected, theme }) => ({
	listStyle: "none",
	"& > button": {
		width: "100%",
		...theme.webFonts.F5Bold,
		border: "none",
		textAlign: "left",
		height: "3.8rem",
		padding: "0.9rem 2.4rem",
		background: selected ? theme.colors.primary : theme.colors.Black05,
		color: selected ? theme.colors.White : theme.colors.D3,
		"&:hover": {
			background: selected ? theme.colors.primary : theme.colors.Black15,
		},
	},
}));

export const Row = styled.div(() => ({
	display: "flex",
	justifyContent: "space-between",
	paddingTop: "5rem",
	flexDirection: "column",
	[MediaQueries[0]]: {
		flexDirection: "row",
	},
}));

export const ButtonContainer = styled.div(() => ({
	display: "flex",
	gap: "1.5rem",
	flexDirection: "column",
	[MediaQueries[0]]: {
		flexDirection: "row",
		gap: "2rem",
	},
	"& button": {
		minWidth: "12rem",
	},
}));
