import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";

// CC
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import PrivateRoute from "CCW-Components/CCW-PrivateRoute";
import Comms from "CCW-Components/CCW-Comms";
import NavBar from "CCW-Components/CCW-NavBar";
import FullPageAlert from "CCW-Components/CCW-FullPageAlert";
import AddressUpdateModal from "CCW-Components/CCW-AddressUpdateModal";
import TransitionRoutes from "CCW-Components/CCW-TransitionRoutes";
import PaywalledRoute from "CCW-Components/CCW-PaywalledRoute";

// Hooks
import useSession from "Hooks/UseSession";
import useEntities from "Hooks/UseAccountEntities";

// Pages
import LoginPage from "Pages/Login";
import SignUpPage from "Pages/SignUp";
import VerifyEmailPage from "Pages/VerifyEmail";
import WelcomePage from "Pages/Welcome";
import SetFirstPassword from "Pages/SetFirstPassword";
import ForgotPasswordPage from "Pages/ForgotPassword";
import ResetPasswordPage from "Pages/ResetPassword";
import Missing404Page from "Pages/Missing404";
import ConfirmEmailChange from "Pages/ConfirmEmailChange";
import Sites from "Pages/Sites";

// Helpers
import { initTracking } from "Helpers/TrackingHelpers/Events";
import checkSettingsCompletion from "Helpers/CompletedDetailsHelpers";

// Assets
import {
	BureaucraticIcon,
	SettingsIcon,
	PlayIcon,
	SharehubIcon,
	BookIcon,
} from "Assets/SVG";

// Lazy loaded
const DashboardRoutes = React.lazy(() => import("Pages/Dashboard"));
const MeasurePage = React.lazy(() => import("Pages/Measure"));
const ActionPage = React.lazy(() => import("Pages/Action"));
const CreateActionPlanPage = React.lazy(() => import("Pages/CreateActionPlan"));
const CompletionReportPage = React.lazy(() => import("Pages/CompletionReport"));
const TrackPage = React.lazy(() => import("Pages/Track/default-track"));
const SettingsPage = React.lazy(() => import("Pages/Settings"));
const ResourcesPage = React.lazy(() => import("Pages/Resources"));
const OffsetsPage = React.lazy(() => import("Pages/Offsets"));
const FeatureRequestPage = React.lazy(() => import("Pages/FeatureRequest"));
const Onboarding = React.lazy(() => import("Pages/Onboarding"));
const ReportsPage = React.lazy(() => import("Pages/Reports"));
const PublicPricing = React.lazy(() => import("Pages/PublicPricing"));
const SharehubPage = React.lazy(() => import("Pages/Sharehub"));

function MainApp() {
	// Hooks
	const [
		activeUser,
		sessionError,
		,
		{ activeAccount: account, activeEntity: entity },
	] = useSession();
	const [, , , , fullEntitiesResponse] = useEntities(account?._id);

	// Helpers
	useEffect(() => {
		initTracking();
	}, []);

	return (
		<BrowserRouter>
			{!sessionError && activeUser ? (
				<NavBar
					accountName={
						account && "name" in account
							? account.name
							: activeUser.fName + activeUser.lName
					}
					entityName={account && "name" in account ? account.name : undefined}
					profileCompleted={
						!!account && !!entity && checkSettingsCompletion(account, entity)
					}
					menuItems={[
						/** Update when there is any resources for business or homes */
						...(account?.accountType === "school"
							? [
									{
										to: "/resources",
										title: "Resources",
										icon: BookIcon,
									},
							  ]
							: []),
						...(account?.accountType !== "home"
							? [
									{
										to: "https://vimeo.com/878118902?share=copy",
										title: "View demo",
										icon: PlayIcon,
									},
									{
										to: "/sharehub/impact-report",
										title: "Impact Report",
										icon: SharehubIcon,
									},
									{
										to: "/sharehub",
										title: "Share Hub",
										icon: SharehubIcon,
									},
							  ]
							: []),

						{
							to: "/feature-requests",
							title: "Request a Feature",
							icon: BureaucraticIcon,
						},
						{ to: "/settings", title: "Settings", icon: SettingsIcon },
					]}
					isMultipleSites={
						!!fullEntitiesResponse?.meta?.siteLimit &&
						fullEntitiesResponse?.meta?.siteLimit > 1
					}
				/>
			) : null}
			<main>
				<AddressUpdateModal />
				<Comms user={activeUser} account={account} />
				<FullPageAlert />
				<Suspense
					fallback={
						<div className="h-screen w-full">
							<BlockLoader message="Loading page....." />
						</div>
					}>
					<TransitionRoutes isWithSentry={true}>
						<Route
							path="/*"
							element={
								<PrivateRoute user={activeUser}>
									<DashboardRoutes />
								</PrivateRoute>
							}
						/>
						<Route
							path="/action/*"
							element={
								<PrivateRoute user={activeUser}>
									<ActionPage />
								</PrivateRoute>
							}
						/>
						<Route
							path="/action/plans/create/step/*"
							element={
								<PrivateRoute user={activeUser}>
									<CreateActionPlanPage />
								</PrivateRoute>
							}
						/>
						<Route
							path={"/action/plans/:id/complete/:status/step/*"}
							element={
								<PrivateRoute user={activeUser}>
									<CompletionReportPage />
								</PrivateRoute>
							}
						/>

						<Route
							path="/feature-requests/*"
							element={
								<PrivateRoute user={activeUser}>
									<FeatureRequestPage />
								</PrivateRoute>
							}
						/>
						<Route
							path="/onboarding"
							element={
								<PrivateRoute user={activeUser}>
									<Onboarding />
								</PrivateRoute>
							}
						/>
						<Route
							path="/set-reset-password"
							element={<ForgotPasswordPage />}
						/>
						<Route path="/password-reset" element={<ResetPasswordPage />} />
						<Route path="/health" element={<div>Ok</div>} />
						<Route path="/login" element={<LoginPage />} />
						<Route
							path="/measure/*"
							element={
								<PrivateRoute user={activeUser}>
									<MeasurePage />
								</PrivateRoute>
							}>
							<Route
								path=":stream/*"
								element={
									<PrivateRoute user={activeUser}>
										<MeasurePage />
									</PrivateRoute>
								}
							/>
						</Route>
						<Route
							path="/offsets/*"
							element={
								<PrivateRoute user={activeUser}>
									<OffsetsPage />
								</PrivateRoute>
							}
						/>
						{/* Temporary endpoint to allow website to render table through a iframe */}
						<Route path="/public/pricing" element={<PublicPricing />} />
						<Route
							path="/settings/*"
							element={
								<PrivateRoute user={activeUser}>
									<SettingsPage />
								</PrivateRoute>
							}
						/>
						{account?.accountType !== "home" && (
							<>
								<Route
									path="/reports/*"
									element={
										<PrivateRoute user={activeUser}>
											<PaywalledRoute feature="report">
												<ReportsPage />
											</PaywalledRoute>
										</PrivateRoute>
									}
								/>
								<Route
									path="/sharehub/*"
									element={
										<PrivateRoute user={activeUser}>
											<PaywalledRoute feature="sharehub">
												<SharehubPage />
											</PaywalledRoute>
										</PrivateRoute>
									}
								/>
							</>
						)}
						<Route
							path="/resources/*"
							element={
								<PrivateRoute user={activeUser}>
									<ResourcesPage />
								</PrivateRoute>
							}
						/>
						<Route path="/set-first-password" element={<SetFirstPassword />} />
						<Route path="/sites" element={<Sites />} />
						<Route
							path="/sign-up/*"
							element={<SignUpPage wizardType="regular" />}
						/>
						<Route
							path="/track/*"
							element={
								<PrivateRoute user={activeUser}>
									<TrackPage />
								</PrivateRoute>
							}
						/>
						<Route path="/verify" element={<VerifyEmailPage />} />
						<Route
							path="/verify-update"
							element={
								<PrivateRoute user={activeUser}>
									<ConfirmEmailChange />
								</PrivateRoute>
							}
						/>

						<Route path="/welcome" element={<WelcomePage />} />
						<Route element={<Missing404Page />} />
					</TransitionRoutes>
				</Suspense>
			</main>
		</BrowserRouter>
	);
}

export default MainApp;
