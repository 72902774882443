import styled from "@emotion/styled";

// CC
import CCButton from "CCW-Components/CCW-CCButton";

// Styles
export const Container = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
}));

export const Card = styled.div(({ theme }) => ({
	borderRadius: "1.2rem",
	boxShadow: theme.shadow,
	padding: "4.5rem 3rem 4rem",
	marginBottom: "3rem",
	background: theme.colors.White,
}));

export const Button = styled(CCButton)(() => ({
	marginTop: "2rem",
}));
