import styled from "@emotion/styled";
import CCButtonLink from "CCW-Components/CCW-ButtonLink";

// Styles
export const Container = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "stretch",
	gap: "4rem",
	width: "54rem",
	maxWidth: "100%",
}));

export const Button = styled(CCButtonLink)(() => ({
	marginTop: "2rem",
}));

export const MarginTop = styled.div(() => ({
	marginTop: "1.8rem",
	textAlign: "right",
}));
