import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useField } from "formik";

// CC
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import Container from "CCW-Components/CCW-Container";
import PLink from "CCW-Components/CCW-PLink";

// Local components
import SimplePricingTable from "Pages/Settings/Components/SimplePricingTable";

// Hooks
import useSubscriptionPlans from "Hooks/UseSubscriptionPlans";

// Types
import type SubscriptionStepProps from "./types";
import type { SubscriptionPeriod } from "Types/Subscription";
import type { FormValues } from "../SignUpWizard/FormLogic";

export const SubscriptionStep: React.FC<SubscriptionStepProps> = ({ to }) => {
	// Hooks
	const [, , billingPeriodHelpers] =
		useField<FormValues["billingPeriod"]>("billingPeriod");
	const [{ value: accountType }] =
		useField<FormValues["accountType"]>("accountType");
	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search);
	const selectedSubscriptionPeriod: SubscriptionPeriod =
		(searchParams.get("period") as SubscriptionPeriod) || "yearly";
	const [plans, plansError] = useSubscriptionPlans(
		accountType,
		selectedSubscriptionPeriod,
	);

	useEffect(() => {
		if (plansError) {
			// If plans errors, don't hold them up, just skip the step
			navigate(to.withoutCreditCard);
		}
	}, [navigate, plansError, to.withoutCreditCard]);

	// Loading
	if (!plans) return <BlockLoader message="Loading subscription plans..." />;

	// Normal state
	return (
		<>
			<DefaultPage defaultPadding>
				<SimplePricingTable
					{...{
						onSelect: (tier) => {
							// Plan is already automatically set by the table, so we only need to set the period
							billingPeriodHelpers.setValue(selectedSubscriptionPeriod);
							navigate(
								tier === "free" ? to.withoutCreditCard : to.withCreditCard,
							);
						},
						accountType,
						plans,
					}}
				/>
				{accountType !== "home" && (
					<Container>
						<p className="text-f5 mt-10 mb-4 text-center text-neutral-600">
							<i>
								Enterprise plans available on request
								<p className="text-f6 mt-5 mb-4 text-center text-neutral-600">
									Please{"  "}
									<PLink to={"https://nut.sh/ell/forms/327184/YsHS15"}>
										<i>register your interest</i>
									</PLink>{" "}
									or contact us directly at{"  "}
									<PLink to={"mailto:support@climateclever.org"}>
										<i> support@climateclever.org</i>
									</PLink>
									{"  "}
									for a personalised quote.
								</p>
							</i>
						</p>
					</Container>
				)}
			</DefaultPage>
		</>
	);
};

export default SubscriptionStep;
