import styled from "@emotion/styled";
import ButtonLoader from "CCW-Components/CCW-ButtonLoader";

// Styles
export const Container = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "stretch",
	gap: "4rem",
}));

export const Button = styled(ButtonLoader)(() => ({
	marginTop: "2rem",
	display: "inline",
}));

export const MarginTop = styled.div(() => ({
	marginTop: "1.8rem",
	textAlign: "right",
}));
