// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import { JSONApi, DataHelperResponse } from "Types/JSONApi";

type Addresses = { description: string; _id: string }[];

type Response = JSONApi<Addresses, undefined, undefined, undefined, undefined>;

/**
 * @async
 * Gets address suggestions
 * @param addressGuess a guessed address e.g. 123 St Georges Tce
 * @returns a DataHelperResponse tuple of Addresses, Related, and the full response
 * @throws Networking error
 */
const getAddressSuggestions = async (
	addressGuess: string,
): Promise<DataHelperResponse<Addresses, undefined, Response>> => {
	const response = await getReq<
		JSONApi<Addresses, undefined, undefined, undefined, undefined>
	>([
		`${
			import.meta.env.VITE_BASE_URL
		}executive/addresses?address=${addressGuess}`,
		1,
	]);

	const [addresses, related, fullResponse] = transformAPIResponse(response, []);
	return [addresses, related, fullResponse];
};

export default getAddressSuggestions;
