import { useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";

/**
 * @deprecated Please don't use this hook, as it has re-rendering issues
 * Get queries from the current URL
 * @param {Array} queries An array of query strings
 * @param {Object} defaultValues An optional default values object for keys if there is not one in the url
 */
const useQueries = (
	queries: string[],
	defaultValues?: { [key: string]: string },
): { [key: string]: string | undefined } => {
	// Hooks
	const location = useLocation();

	// Helpers
	const checkDefault = useCallback(
		(query: string, defaults?: { [key: string]: string }) => {
			return defaults != null && defaults[query] != null
				? defaults[query]
				: undefined;
		},
		[],
	);

	return useMemo(() => {
		const foundQueries: { [key: string]: string | undefined } = {};
		queries.forEach((query) => {
			const url = new URLSearchParams(location.search);
			const queryString = url.get(query);
			queryString != null
				? (foundQueries[query] = queryString)
				: (foundQueries[query] = checkDefault(query, defaultValues));
		});
		return foundQueries;
	}, [checkDefault, defaultValues, location.search, queries]);
};

export default useQueries;
