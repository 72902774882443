import React from "react";

// CC
import Checkbox from "../CCW-CheckBox";

// Styles
import {
	SecondaryText,
	PickerContainer,
	PartnerButton,
	OptOutContainer,
} from "./styles";

// Types
import type PartnerPickerProps from "./types";

export const PartnerPicker: React.FC<PartnerPickerProps> = ({
	partners,
	onSelect,
	selectedPartnerID,
	...props
}) => {
	return (
		<div {...props}>
			<PickerContainer>
				{partners?.map((partner) => (
					<PartnerButton
						key={partner._id}
						partner={partner}
						onClick={() => onSelect(partner._id)}
						selected={selectedPartnerID === partner._id}></PartnerButton>
				))}
			</PickerContainer>
			<OptOutContainer>
				<Checkbox
					id="partner-opt-out"
					label={
						<>
							<SecondaryText>
								I don&apos;t want to become part of a council area
							</SecondaryText>
						</>
					}
					onChange={() => onSelect(selectedPartnerID ? null : partners[0]._id)}
					checked={!selectedPartnerID}
				/>
			</OptOutContainer>
		</div>
	);
};

export default PartnerPicker;
