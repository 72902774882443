import styled from "@emotion/styled";

// Helpers
import mediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// Base Styles
import CCPartnerButton from "CCW-Components/CCW-PartnerButton";

export const SecondaryText = styled.span(({ theme }) => ({
	...theme.webFonts.F5,
	color: theme.colors.D3,
}));

export const PickerContainer = styled.div(() => ({
	marginTop: "2.4rem",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "space-between",
	gap: "1.6rem",
	[mediaQueries[2]]: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
}));

export const PartnerButton = styled(CCPartnerButton)(() => ({
	width: "100%",
	[mediaQueries[2]]: {
		maxWidth: "26rem",
	},
}));

export const OptOutContainer = styled.div(() => ({
	marginTop: "1.5rem",
	display: "flex",
	alignItems: "center",
	[mediaQueries[2]]: {
		marginTop: "2rem",
	},
}));
