import React from "react";

// Types
import BackgroundShapeProps from "./types";

// Hooks
import usePlatform from "Hooks/UsePlatform";

const BackgroundShape: React.FC<BackgroundShapeProps> = ({
	width,
	height,
	side,
	...props
}) => {
	// Hooks
	const platform = usePlatform();

	return (
		<svg
			width={width}
			height={height}
			transform={side === "left" ? "rotate(144)" : "rotate(136)"}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<rect
				x="0"
				y="0"
				width={width}
				height={height}
				rx="120"
				fill={`url(#${platform}-welcome-signup)`}
			/>
			<defs>
				<linearGradient
					id="business-welcome-signup"
					x1="0%"
					y1="0%"
					x2="79.6%"
					y2="114.1%"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#007CBE" />
					<stop offset="1" stopColor="#884EB1" />
				</linearGradient>
				<linearGradient
					id="home-welcome-signup"
					x1="0%"
					y1="100%"
					x2="114.1%"
					y2="20.3%"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#E97E42" />
					<stop offset="1" stopColor="#884EB1" />
				</linearGradient>
				<linearGradient
					id="school-welcome-signup"
					x1="0%"
					y1="0%"
					x2="78.2%"
					y2="115.8%"
					gradientUnits="userSpaceOnUse">
					<stop offset="0.171875" stopColor="#007CBE" />
					<stop offset="1" stopColor="#884EB1" />
				</linearGradient>
				<linearGradient
					id="partner-welcome-signup"
					x1="0%"
					y1="0%"
					x2="78.2%"
					y2="115.8%"
					gradientUnits="userSpaceOnUse">
					<stop offset="0.171875" stopColor="#007CBE" />
					<stop offset="1" stopColor="#884EB1" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default BackgroundShape;
