import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { useNavigate } from "react-router-dom";

// CC
import validatePartnerToken from "Helpers/NetworkingHelpers/CreateValidatePartnerToken";

// Hooks
import useQueries from "Hooks/UseQueries";

// Styles
import { ErrorContainer, ErrorHeader, ErrorTitle, ErrorBody } from "./styles";

// Types
import PartnerTokenValidationStepProps from "./types";
import type ValidatedDataPartner from "Types/ValidatedDataPartner";
import BlockLoader from "CCW-Components/CCW-BlockLoader";

export const PartnerTokenValidationStep: React.FC<
	PartnerTokenValidationStepProps
> = ({ to, onPartnerTokenSuccess }) => {
	// Hooks
	const [partnerField, , partnerFieldHelpers] = useField("partner");
	const [, , tokenFieldHelpers] = useField("token");
	const { token } = useQueries(["token"]);
	const [isTokenInvalid, setIsTokenInvalid] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (token && !partnerField.value) {
			(async () => {
				try {
					const validatedPartner: ValidatedDataPartner =
						await validatePartnerToken(token);
					if (validatedPartner?.data?.partner) {
						partnerFieldHelpers.setValue(validatedPartner.data.partner);
						tokenFieldHelpers.setValue(token);
						onPartnerTokenSuccess(validatedPartner.data.partner);
						navigate(to);
					} else {
						throw "fail";
					}
				} catch (err) {
					setIsTokenInvalid(true);
				}
			})();
		}
	}, [
		navigate,
		onPartnerTokenSuccess,
		partnerField.value,
		partnerFieldHelpers,
		tokenFieldHelpers,
		to,
		token,
	]);

	if (isTokenInvalid) {
		return (
			<ErrorContainer>
				<ErrorHeader>
					<ErrorTitle>Sorry, your token has expired</ErrorTitle>
				</ErrorHeader>
				<ErrorBody>Please contact us to have a new token resent</ErrorBody>
			</ErrorContainer>
		);
	}

	return <BlockLoader message="Please wait while we validate your token..." />;
};

export default PartnerTokenValidationStep;
