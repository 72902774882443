// Helpers
import patchReq from "../PatchReq";

// Types
import Entity from "Types/Entity";

import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import { JSONApi, DataHelperResponse, Update } from "Types/JSONApi";

type Relationships = {
	accounts: {
		links: { related: string };
		data: { type: "accounts"; id: string }[];
	};
	entities: {
		links: { related: string };
		data: { type: "entities"; id: string }[];
	};
};

type Response = JSONApi<Entity, Relationships, undefined, undefined, undefined>;

/**
 * @async
 * Updates an entity
 * @param entityID ID of the entity to update
 * @param addressID The ID of the new address
 * @returns a DataHelperResponse tuple of Entity and the full response
 * @throws Networking error
 */
const updateEntityAddress = async (
	entityID: string,
	addressID: string,
): Promise<DataHelperResponse<Entity, undefined, Response>> => {
	const requestBody: Update<{ addressId: string }> = {
		data: {
			id: entityID,
			type: "entities",
			attributes: { addressId: addressID },
			relationships: undefined,
			meta: undefined,
		},
	};

	const response = await patchReq<Response>(
		`${import.meta.env.VITE_BASE_URL}executive/entities/${entityID}/address`,
		requestBody,
		1,
	);

	return transformAPIResponse(response, {});
};

export default updateEntityAddress;
