import React, { useState, useEffect, PropsWithChildren } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// CC
import AppError from "../CCW-AppError";
import BlockLoader from "../CCW-BlockLoader";

// Helpers
import isError from "Helpers/TypeHelpers/IsError";

const StripeWrapper: React.FC<PropsWithChildren<unknown>> = (props) => {
	// State
	const [stripe, setStripe] = useState<Stripe | null>(null);
	const [error, setError] = useState<Error | null>(null);

	// Helpers
	useEffect(() => {
		const getStripe = async () => {
			if (!stripe) {
				try {
					const stripeResolved = await loadStripe(
						import.meta.env.VITE_STRIPE_FLAG === ("development" as string)
							? (import.meta.env.VITE_STRIPE_PUBLIC_KEY_DEV as string)
							: (import.meta.env.VITE_STRIPE_PUBLIC_KEY_PROD as string),
					);

					setStripe(stripeResolved);
				} catch (error) {
					if (isError(error)) {
						setError(
							new Error(
								"There was an error loading this page. Please try again or contact support.",
							),
						);
					}
				}
			}
		};

		getStripe();
	}, [stripe]);

	// Error State
	if (error) {
		return <AppError error={error} />;
	}

	// Loading State
	if (import.meta.env.VITEST !== "true" && !stripe)
		return <BlockLoader message="Loading..." />;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return <Elements stripe={stripe}>{props.children}</Elements>;
};

export default StripeWrapper;
