import styled from "@emotion/styled";
import mediaQueries from "Helpers/WebUIHelpers/Breakpoints";

import { ContainerProps, DisabledProps } from "./types";

export const Container = styled.div<ContainerProps>(
	({ hasLabel, isColumn }) => ({
		display: "flex",
		flexDirection: isColumn ? "column" : "row",
		alignItems: isColumn ? "start" : "center",
		justifyContent: hasLabel ? "space-between" : "center",
		gap: "0.8rem 1.5rem",
	}),
);

export const Label = styled.label<{
	showLabel: boolean;
	hasBoldStyles?: boolean;
}>(({ theme, showLabel, hasBoldStyles }) => ({
	...theme.webFonts.F6,
	display: showLabel ? "flex" : "none",
	flexDirection: "column",
	color: theme.colors.grey500,
	...(hasBoldStyles && {
		color: theme.colors.D1,
		...theme.webFonts.bold,
	}),
	[mediaQueries[1]]: {
		flexDirection: "row",
	},
}));

export const MainLabel = styled.span({
	marginRight: "1rem",
});

export const SubLabel = styled.span(({ theme }) => ({
	...theme.webFonts.F6,
	...theme.webFonts.italic,
	color: theme.colors.D1,
}));

const xPaddingWidth = 16;

export const Button = styled.button<
	DisabledProps & {
		selectedTextWidth: number;

		invertColors: boolean;
	}
>(({ theme, disabled, selectedTextWidth, invertColors }) => {
	const gutterWidth = 4;
	return {
		position: "relative",
		display: "flex",
		padding: "3px",
		cursor: disabled ? "default" : "pointer",
		background: invertColors ? theme.colors.Grape400 : theme.colors.White,
		color: invertColors ? theme.colors.grey50 : theme.colors.Grape400,
		opacity: disabled ? 0.5 : 1,
		border: "none",
		borderRadius: "200px", // Obscenely large border radius to make a pill shape no matter the content
		...(!invertColors && { boxShadow: theme.shadow }),
		// The pill that moves
		"&:before": {
			content: "''",
			position: "absolute",
			top: `${gutterWidth}px`,
			left: `${gutterWidth}px`,
			bottom: `${gutterWidth}px`,
			width: selectedTextWidth + xPaddingWidth * 2 + "px",
			borderRadius: "200px", // Obscenely large border radius to make a pill shape no matter the content
			background: invertColors ? theme.colors.grey50 : theme.colors.Grape400,
			transition: "left 0.4s, transform 0.4s, width 0.1s",
		},
		"&[aria-checked=true]:before": {
			left: `calc(100% - ${gutterWidth}px)`,
			transform: "translateX(-100%)",
		},
		"&[aria-checked=true] > .right-button, &[aria-checked=false] > .left-button":
			{
				color: invertColors ? theme.colors.Grape400 : theme.colors.White,
			},
	};
});

export const ToggleState = styled.span(({ theme }) => ({
	...theme.webFonts.F7Bold,
	position: "relative",
	padding: `7px ${xPaddingWidth}px`,
	transition: "color 0.3s",
}));

export const CheckBox = styled.input<{
	colorType: "primary" | "secondary";
	invertColors: boolean;
}>(({ theme, colorType, invertColors }) => ({
	display: "none",
	"&:checked": {
		"& + .switch": {
			backgroundColor: invertColors
				? theme.colors.grey50
				: colorType === "primary"
				? theme.colors.Blue200
				: theme.colors.Grape200,
			"&::after": {
				left: "1.5rem",
				backgroundColor: invertColors
					? theme.colors.grey50
					: colorType === "primary"
					? theme.colors.Blue400
					: theme.colors.Grape400,
			},
		},
	},
}));

export const Switch = styled.label(({ theme }) => ({
	cursor: "pointer",
	position: "relative",
	display: "inline-block",
	width: "3rem",
	height: "1rem",
	backgroundColor: theme.colors.grey200,
	borderRadius: "2rem",
	padding: "0 0.1rem",
	flexShrink: 0,
	"&::after": {
		content: "''",
		position: "absolute",
		width: "1.6rem",
		height: "1.6rem",
		borderRadius: "50%",
		backgroundColor: "white",
		top: "-0.3rem",
		left: "-0.1rem",
		transition: "all 0.2s",
		boxShadow: theme.shadow,
	},
}));
