import { Theme } from "@emotion/react";
import styled from "@emotion/styled";

// Styles
import ButtonLoaderCC from "../CCW-ButtonLoader";

export const Container = styled.div(() => ({
	minWidth: "25rem",
	"& .StripeElement ": {
		width: "100%",
	},
}));

export const FakeInput = styled.div<{ active: boolean }>(
	({ theme, active }) => ({
		width: "100%",
		height: "4rem",
		padding: "0",
		border: `1px solid ${active ? theme.colors.primary : theme.colors.D4}`,
		backgroundColor: "white",
		borderRadius: "0.5rem",
	}),
);

export const InnerDiv = styled.div(() => ({
	position: "relative",
	height: "4rem",
	display: "flex",
	alignItems: "center",
	marginLeft: "1.5rem",
}));

export const stripeStyle = ({ theme }: { theme: Theme }) => ({
	style: {
		base: {
			fontFamily: "Source Sans Pro, Helvetica, Arial, sans serif",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "16px",
			iconColor: theme.colors.D3,
			color: theme.colors.D1,
			"::placeholder": { color: theme.colors.D4 },
		},
		invalid: {
			iconColor: theme.colors.Red,
			color: theme.colors.Red,
		},
	},
});

export const Grid = styled.div(() => ({
	display: "grid",
	gap: "2.5rem",
	gridTemplateColumns: "1fr 1fr",
	alignItems: "end",
	justifyItems: "start",
	"& > div": {
		width: "100%",
	},
	"& > div:first-of-type": {
		gridColumn: "span 2",
	},
}));

export const Error = styled.span(({ theme }) => ({
	color: theme.colors.Red,
	...theme.webFonts.F6,
	position: "absolute",
	left: "0px",
	bottom: "-2rem",
	whiteSpace: "nowrap",
	overflow: "hidden",
}));

export const ButtonLoader = styled(ButtonLoaderCC)({
	height: "4rem",
});

export const EmailSection = styled.div(() => ({
	marginBottom: "2rem",
}));
