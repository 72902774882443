import styled from "@emotion/styled";

export const Button = styled.button<{ selected: boolean }>(
	({ theme, selected }) => ({
		cursor: "pointer",
		background: theme.colors.White,
		color: theme.colors.White,
		boxShadow: theme.shadow,
		border: selected ? `3px solid ${theme.colors.primary}` : "none",
		borderRadius: "0.8rem",
		outline: "none",
		height: "8.4rem",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"&:hover,:focus": {
			boxShadow: theme.shadow,
			outline: `1px solid ${theme.colors.primary}`,
		},
		"&:active": {
			boxShadow: `0 0 0.1rem 0 ${theme.colors.D3}`,
		},
	}),
);

export const PartnerImage = styled.img(() => ({
	maxHeight: "100%",
	background: "transparent",
	overflow: "clip",
}));

export const PartnerName = styled.h2(({ theme }) => ({
	...theme.webFonts.F3Bold,
	color: theme.colors.Black60,
}));
