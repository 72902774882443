import styled from "@emotion/styled";

// Assets
import BaseEmailIcon from "Assets/SVG/EmailIcon";
import BackgroundShape from "../../Assets/BackgroundShape";

// Helpers
import MediaQueries, { mediaQueries } from "Helpers/WebUIHelpers/Breakpoints";

export const Container = styled.div(() => ({
	position: "relative",
	width: "100%",
	height: "100vh",
	overflow: "clip",
	display: "flex",
	justifyContent: "center",
	"& > #welcome-background": {
		display: "none",
		[MediaQueries[2]]: {
			display: "block",
			position: "absolute",
			right: "0px",
			bottom: "0px",
			top: "0px",
		},
	},
}));

export const LeftBgShape = styled(BackgroundShape)(() => ({
	position: "absolute",
	left: "-900000px",

	[mediaQueries[1]]: {
		top: "-200px",
		left: "-900px",
	},
}));

export const RightBgShape = styled(BackgroundShape)(() => ({
	position: "absolute",
	top: "200px",
	right: "-950px",
	[mediaQueries[1]]: {
		top: "200px",
		right: "-800px",
	},
}));

export const EmailIcon = styled(BaseEmailIcon)(({ theme }) => ({
	"& .primary-fill": {
		fill: theme.colors.primary,
	},
	"& .secondary-fill": {
		fill: theme.colors.secondary,
	},
}));

export const ContentContainer = styled.div(() => ({
	position: "relative",
	paddingBottom: "12rem",
	paddingTop: "14rem",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	textAlign: "center",
}));

export const Title = styled.div(({ theme }) => ({
	...theme.webFonts.F1Bold,
	color: theme.colors.D1,
	margin: "3rem 0 0 0",
	width: "31.5rem",
	[mediaQueries[1]]: {
		width: "55rem",
	},
}));

export const Highlighted = styled.span(({ theme }) => ({
	color: theme.colors.secondary,
}));

export const Info = styled.p(({ theme }) => ({
	...theme.webFonts.F5,
	color: theme.colors.D2,
	margin: "2rem 0 4rem 0",
	width: "34.3rem",
	[mediaQueries[1]]: {
		width: "46.4rem",
	},
}));

export const InfoBold = styled.b(({ theme }) => ({
	color: theme.colors.D1,
}));

export const AppErrorContainer = styled.div(({ theme }) => ({
	position: "absolute",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	backgroundColor: theme.colors.White,
	bottom: 0,
	top: 0,
	left: 0,
	right: 0,
}));
