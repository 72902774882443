import * as Yup from "yup";
import { AddressRecommendation } from "Types/Address";
import Referral from "Types/Referrals";
import { Tier } from "Types/Tiers";
import { AccountType, BillingPeriod } from "Types/Account";

export type FormValues = {
	fName: string;
	lName: string;
	email: string;
	password: string;
	tc: boolean;
	confirmPassword: string;
	organisationName?: string;
	entityName?: string;
	phone?: string;
	siteEmail?: string;
	coupon?: string;
	address: AddressRecommendation;
	LGAOptions?: Referral[];
	referralCode?: string;
	lga?: string;
	salesCalls?: boolean;
	plan: Exclude<Tier, "enterprise">;
	billingPeriod?: BillingPeriod;
	stripeToken?: string;
	billingEmail?: string;
	accountType: AccountType;
	marketingEmails: boolean;
	newsletter: boolean;
};

/**
 * Initial values for the Formik form
 */
export const makeInitValues = (
	referralCode?: string,
	accountType?: AccountType,
): FormValues => ({
	fName: "",
	lName: "",
	email: "",
	password: "",
	confirmPassword: "",
	tc: false,
	entityName: "",
	address: {
		_id: "",
		description: "",
	},
	LGAOptions: undefined,
	lga: undefined, // Gets set in component logic
	referralCode: referralCode || "",
	plan: "" as Exclude<Tier, "enterprise">,
	billingPeriod: "yearly",
	stripeToken: "",
	billingEmail: "",
	accountType: accountType as AccountType,
	// School & Business only
	organisationName: "",
	// School only
	siteEmail: "",
	marketingEmails: false,
	newsletter: false,
});

/**
 * Array of validation objects for the Formik form
 */
export const stepValidations = [
	Yup.object({
		fName: Yup.string().required("Required"),
		lName: Yup.string().required("Required"),
		email: Yup.string().email("Invalid email address").required("Required"),
	}),
	Yup.object({
		accountType: Yup.string().oneOf(["school", "home", "business"]),
	}),
	Yup.object({
		password: Yup.string().min(8, "Password must be at least 8 characters"),
		confirmPassword: Yup.string().when("password", {
			is: (value: string) => !!value,
			then: (schema) =>
				schema
					.oneOf([Yup.ref("password")], "Passwords do not match")
					.required("Please confirm your new password"),
		}),
		tc: Yup.bool().oneOf([true], "You must accept the terms and data policy"),
	}),
	Yup.object({
		organisationName: Yup.string().required("This field is required"),
		entityName: Yup.string().required(
			"Please enter the name of your main site/campus/house",
		),
		// Home and bill buddy have their own address step
		address: Yup.object().when("accountType", {
			is: (accountType: AccountType) => accountType !== "home",
			then: (schema) =>
				schema.shape({
					_id: Yup.string().required("Please enter an address"),
					description: Yup.string().required("Please enter an address"),
				}),
		}),
		// School needs a phone number and a campus email in this step. Optional for business.
		phone: Yup.string()
			.matches(
				/^((\+61\s?))[1-9]{1}[0-9]{7,8}$/,
				"Phone number provided is not valid",
			)
			.when("accountType", {
				is: (accountType: AccountType) => accountType !== "home",
				then: (schema) => schema.required("Phone number is required"),
			}),
		// School needs a campus email address
		siteEmail: Yup.string().when("accountType", {
			is: "school",
			then: (schema) =>
				schema.email("Invalid email address").required("Required"),
		}),
		// Optional sales calls for all, though only rendered in business
		salesCalls: Yup.boolean(),
		marketingEmails: Yup.boolean(),
		newsletter: Yup.boolean(),
	}),
	Yup.object({
		// This step only applies to home
		address: Yup.object({
			_id: Yup.string().required("Please enter an address"),
			description: Yup.string().required("Please enter an address"),
		}),
	}),
	Yup.object({
		lga: Yup.string(),
	}),
];
