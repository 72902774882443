import styled from "@emotion/styled";

// Helpers
import mediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Container = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
}));

export const ErrorContainer = styled.div(({ theme }) => ({
	background: theme.colors.White,
	borderRadius: "1.2rem",
	width: "100%",
	boxShadow: theme.shadow,
	[mediaQueries[1]]: {
		minWidth: "47.6rem",
	},
}));

export const ErrorHeader = styled.div<{
	isSimpleDesign?: boolean;
	subheading?: boolean;
}>(({ theme, isSimpleDesign, subheading }) => ({
	height: subheading ? "14.4rem" : "12.1rem",
	padding: "0.4rem",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	borderBottom: !isSimpleDesign ? `0.1rem solid ${theme.colors.D4}` : "",
}));

export const ErrorTitle = styled.h1<{ isSimpleDesign?: boolean }>(
	({ theme, isSimpleDesign }) => ({
		...theme.webFonts.h2,
		color: isSimpleDesign ? theme.colors.D1 : theme.colors.primary,
		textAlign: "center",
		margin: "0.175rem",
	}),
);

export const ErrorBody = styled.div(({ theme }) => ({
	...theme.webFonts.F3Bold,
	background: theme.colors.Black05,
	padding: "5rem 6rem",
	textAlign: "center",
	[mediaQueries[1]]: {
		padding: "6rem 10.5rem",
	},
}));
