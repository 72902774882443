import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import type { JSONApi, DataHookResponse, ResourceObject } from "Types/JSONApi";
import type Account from "Types/Account";
import type Entity from "Types/Entity";

type Relationships = {
	accounts: {
		links: { related: string };
		data: { type: "accounts"; id: string }[];
	};
	entities: {
		// If there are more entities it means the entity we are fetching is not the main one
		links: { related: string };
		data: { type: "entities"; id: string }[];
	};
};

type Included = ResourceObject<
	Account | Entity,
	undefined,
	"accounts" | "entities"
>[];

type Related = {
	accounts: Account[];
	entities: Entity[];
};

type Response = JSONApi<Entity, Relationships, Included, undefined, Related>;

/**
 * Get the entity for a specific entity ID
 * @param entityID entity ID of the entity you want to fetch
 * @returns a DataHookResponse tuple of Entity, Error, Mutate, Related, and the full response
 */
const useEntity = (
	entityID: string | undefined,
): DataHookResponse<Entity, Related, Response> => {
	const { data, error, mutate } = useSWR<
		JSONApi<Entity, Relationships, Included, undefined, Related>,
		Error
	>(
		entityID
			? [`${import.meta.env.VITE_BASE_URL}executive/entities/${entityID}`, 3]
			: null,
		getReq,
		{
			shouldRetryOnError: false,
		},
	);

	const [entity, related, fullJSONApiResponse] = transformAPIResponse(data, {});

	return [entity, error, mutate, related, fullJSONApiResponse];
};

export default useEntity;
