// Hooks
import { PaywalledFeature } from "CCW-Components/CCW-Paywall/types";
import useAccountEntities from "Hooks/UseAccountEntities";
import usePlatform from "Hooks/UsePlatform";
import useSession from "Hooks/UseSession";
import Platform from "Types/Platform";
import { Tier } from "Types/Tiers";

export const getAllowedFeatures = (currentTier: Tier, platform: Platform) => {
	// Order is important here
	const tiers: Tier[] = [
		"free",
		"small-business",
		"basic",
		"standard",
		"pro",
		"enterprise",
	];

	const tierRequirementPerFeature: {
		[feature in PaywalledFeature]: Tier | { [platform in Platform]?: Tier };
	} = {
		/** Features */
		sites: {
			home: "pro", // pro just to turn it off always
			business: "small-business",
			school: "basic",
		},
		sharehub: "standard",
		thirdPartyOffsets: {
			home: "basic",
			business: "small-business",
			school: "standard",
		},
		report: "standard",
		historicReport: "pro",
		/** Emission Types */
		electricity: "free",
		water: { home: "free", business: "small-business", school: "basic" },
		waste: "basic",
		gas: { home: "free", business: "small-business", school: "basic" },
		lpg: "basic",
		transport: {
			home: "basic",
			business: "small-business",
			school: "standard",
		},
		flight: { home: "basic", business: "small-business", school: "standard" },
		paper: { home: "basic", business: "small-business", school: "standard" },
		accommodation: {
			home: "basic",
			business: "small-business",
			school: "standard",
		},
		"stationary-fuels": {
			home: "basic",
			business: "small-business",
			school: "standard",
		},
		catering: { home: "basic", business: "small-business", school: "standard" },
		equipment: {
			home: "basic",
			business: "small-business",
			school: "standard",
		},
	};

	const currentTierIndex = tiers.indexOf(currentTier);

	const allowedFeatures: PaywalledFeature[] = Object.entries(
		tierRequirementPerFeature,
	)
		.filter(
			([feature, requiredTierOrTierByPlatform]) =>
				currentTierIndex >=
				tiers.indexOf(
					(typeof requiredTierOrTierByPlatform === "string"
						? requiredTierOrTierByPlatform
						: requiredTierOrTierByPlatform[platform]) as Tier,
				),
		)
		.map(([feature]) => feature as PaywalledFeature);

	return allowedFeatures;
};

/**
 * Hook for seeing if active user passes permission tests
 * @param feature which feature do you want to check
 * @returns a boolean of whether or not the user passed the test
 */
const useFeatureAccess = (): PaywalledFeature[] => {
	const [, , , { activeAccount }] = useSession();
	const platform = usePlatform();
	const [, , , , fullEntitiesResponse] = useAccountEntities(activeAccount?._id);

	const sitesRemaining = fullEntitiesResponse?.meta
		? fullEntitiesResponse.meta?.siteLimit -
		  fullEntitiesResponse.meta?.siteCount
		: null;
	if (!activeAccount) {
		return [];
	}

	return getAllowedFeatures(activeAccount.tier, platform).filter(
		(feature) => feature !== "sites" || (sitesRemaining && sitesRemaining > 0), // Filter out sites if there are no sitesRemaining
	);
};

export default useFeatureAccess;
