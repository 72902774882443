import React from "react";

// Types
import type IconProps from "Types/IconProps";

export const TiersIcon: React.FC<IconProps & { tier: string }> = ({
	width,
	height,
	className = "tier-icon",
	id,
	tier,
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "120px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 120 120"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			{tier === "free" && (
				<>
					<g clipPath="url(#clip0_4378_246)">
						<path
							d="M60 120C93.138 120 120 93.1394 120 60.003C120 26.8667 93.144 0 60 0C26.856 0 0 26.8607 0 59.997C0 93.1333 26.862 119.994 60 119.994V120Z"
							fill="white"
						/>
						<path
							d="M92.802 110.25C93.66 109.692 94.5 109.105 95.322 108.499C95.346 108.481 95.37 108.463 95.394 108.445C96.198 107.857 96.984 107.251 97.758 106.621C97.8 106.591 97.836 106.555 97.878 106.525C98.64 105.901 99.39 105.265 100.122 104.605C100.158 104.569 100.194 104.539 100.236 104.503C100.992 103.819 101.736 103.122 102.456 102.402C99.822 101.226 96.732 99.871 92.1 99.817C89.058 99.781 86.214 99.601 82.998 97.4171C81.954 96.7092 80.706 96.0612 79.362 95.5812C76.584 94.4593 73.26 94.2853 70.536 95.9172C67.086 97.8431 66.36 103.519 59.412 104.011C53.178 103.819 50.946 98.6831 48.942 96.9851C45.534 94.0993 41.322 94.5193 37.116 96.7152C33.738 98.4731 30.282 99.775 26.574 99.535C21.114 99.181 18.522 100.032 16.374 101.19C27.312 112.775 42.81 120 60 120C72.102 120 83.364 116.418 92.79 110.256C92.79 110.256 92.796 110.256 92.802 110.25Z"
							fill="#DE9351"
						/>
						<path
							d="M102.456 102.403C99.822 101.227 96.732 99.877 92.1 99.823C89.058 99.787 86.214 99.607 82.998 97.4231C79.782 95.2392 74.472 93.5653 70.536 95.9232C70.536 95.9232 76.194 102.325 84.282 104.125C89.826 105.355 92.1 108.888 92.79 110.256C96.276 107.976 99.522 105.337 102.456 102.403Z"
							fill="#DA853B"
						/>
						<path
							d="M56.2834 91.5881C56.3342 91.8056 56.3732 92.0244 56.4412 92.2337C56.6134 92.7989 56.8371 92.9468 57.42 92.8692C57.8607 92.8151 58.3051 92.7424 58.7333 92.6354C60.574 92.1676 62.0644 91.2592 62.8149 89.3958C63.6724 87.2715 62.938 85.4756 60.8559 84.7157C60.2446 84.5126 59.6617 84.5903 59.0652 84.8025C58.155 85.1198 57.4943 85.745 56.939 86.4902C55.7657 88.0609 55.8474 89.81 56.2841 91.5941L56.2834 91.5881Z"
							fill="#EFCC83"
						/>
						<path
							d="M62.8149 89.3958C62.9496 89.0649 63.0426 88.7392 63.1006 88.4238C63.1217 88.3003 63.1428 88.1768 63.152 88.0547C63.1566 87.9937 63.1612 87.9327 63.1658 87.8717C63.1712 87.7683 63.1714 87.6715 63.165 87.5695C63.1613 87.5398 63.1576 87.51 63.154 87.4802C63.0607 86.2282 62.2733 85.2308 60.8559 84.7157C60.9654 85.0166 61.2799 85.8545 61.298 86.4447C61.4221 88.1946 60.1595 90.1208 58.6272 90.7865C57.8756 91.1146 57.0676 91.3286 56.2841 91.5941C56.335 91.8115 56.374 92.0304 56.442 92.2397C56.6142 92.8049 56.8378 92.9527 57.4207 92.8751C57.8614 92.821 58.3059 92.7483 58.734 92.6413C60.5747 92.1735 62.0651 91.2651 62.8156 89.4018L62.8149 89.3958Z"
							fill="#E2BF80"
						/>
						<path
							d="M44.8417 64.4788C44.6257 64.5148 44.4037 64.5328 44.1877 64.5868C43.6117 64.7188 43.4437 64.9228 43.4797 65.5108C43.5037 65.9548 43.5337 66.4048 43.6117 66.8368C43.9357 68.7088 44.7277 70.2628 46.5277 71.1568C48.5797 72.1708 50.4217 71.5828 51.3457 69.5668C51.5977 68.9728 51.5617 68.3848 51.3997 67.7788C51.1537 66.8488 50.5777 66.1408 49.8817 65.5288C48.4057 64.2388 46.6597 64.1848 44.8477 64.4848L44.8417 64.4788Z"
							fill="#EFCC83"
						/>
						<path
							d="M46.5278 71.1628C46.8458 71.3188 47.1638 71.4388 47.4698 71.5228C47.5958 71.5528 47.7158 71.5828 47.8358 71.6008C47.8958 71.6128 47.9558 71.6188 48.0158 71.6248C48.1178 71.6368 48.2138 71.6428 48.3158 71.6488C48.3458 71.6488 48.3758 71.6488 48.4058 71.6488C49.6598 71.6488 50.7158 70.9408 51.3398 69.5728C51.0278 69.6568 50.1698 69.9088 49.5818 69.8788C47.8298 69.8668 46.0057 68.4628 45.4597 66.8788C45.1897 66.0988 45.0398 65.2828 44.8358 64.4848C44.6198 64.5208 44.3977 64.5388 44.1817 64.5928C43.6057 64.7248 43.4377 64.9288 43.4737 65.5168C43.4977 65.9608 43.5278 66.4108 43.6058 66.8428C43.9298 68.7148 44.7218 70.2688 46.5218 71.1628H46.5278Z"
							fill="#E2BF80"
						/>
						<path
							d="M52.491 51.9915C52.4889 52.2105 52.474 52.4333 52.4897 52.6554C52.5256 53.2466 52.7043 53.4426 53.2906 53.5033C53.7331 53.5509 54.1766 53.5925 54.6225 53.5859C56.5206 53.5672 58.1798 53.0373 59.3513 51.4039C60.6846 49.5431 60.3948 47.6277 58.5588 46.3962C58.0165 46.0508 57.4302 45.99 56.8032 46.0561C55.8425 46.1487 55.0501 46.6 54.3363 47.193C52.8242 48.4435 52.4914 50.1577 52.49 51.9974L52.491 51.9915Z"
							fill="#EFCC83"
						/>
						<path
							d="M59.3512 51.4038C59.5611 51.1119 59.7286 50.8185 59.8586 50.5307C59.91 50.4118 59.9545 50.2978 59.993 50.1827C60.0153 50.1257 60.0316 50.0677 60.0479 50.0096C60.0774 49.9113 60.1011 49.8119 60.1177 49.7173C60.1229 49.6878 60.1282 49.6582 60.1334 49.6287C60.3393 48.3917 59.8067 47.2315 58.5529 46.3951C58.5884 46.7121 58.6934 47.6019 58.5736 48.1778C58.2812 49.9053 56.5964 51.4786 54.9503 51.7611C54.1402 51.8985 53.3084 51.9163 52.4851 51.9905C52.483 52.2094 52.4681 52.4322 52.4838 52.6543C52.5196 53.2455 52.6983 53.4415 53.2847 53.5023C53.7271 53.5498 54.1706 53.5915 54.6166 53.5848C56.5147 53.5661 58.1739 53.0362 59.3453 51.4028L59.3512 51.4038Z"
							fill="#E2BF80"
						/>
						<path
							d="M107.185 30.5745L111.354 29.5965C111.462 29.5725 111.57 29.5305 111.672 29.4885C107.989 23.2668 103.213 17.7651 97.6091 13.2533C97.3152 13.3433 97.0212 13.4333 96.7272 13.5233C89.3955 15.6532 83.0179 19.487 80.45 22.0129C78.2421 24.3168 75.5002 27.8446 73.0164 31.3124C70.8325 34.3003 68.6786 37.4141 66.3687 39.88C64.7188 41.6379 65.3727 43.8218 66.6807 45.3037C68.3126 46.9837 71.3844 47.7696 75.0563 44.7098C76.9942 43.0179 79.346 39.58 81.7039 36.1422C81.8059 35.9802 82.0039 35.6622 82.0999 35.5002C82.5979 34.6963 83.8038 34.5523 84.5058 35.2122C85.0518 35.7702 85.0038 36.7422 84.6018 37.3841C84.4998 37.5461 83.8218 38.7341 83.7198 38.8961C81.8959 41.6619 80.8199 43.4138 79.2 45.3037C78.54 46.0057 78.0481 46.5697 77.3581 47.1396C76.0322 48.2436 74.3583 49.3895 74.3583 49.3895C70.8424 51.6694 63.2999 54.9901 61.5 56.1C56.7 59.06 59.601 65.4987 65.6907 63.9328C71.7504 62.2349 91.5434 55.2572 97.0692 47.7816C99.553 44.3138 102.685 38.3561 105.013 32.4584C105.481 31.5224 106.267 30.7905 107.179 30.5745H107.185Z"
							fill="#DAAD8C"
						/>
					</g>
					<defs>
						<clipPath id="clip0_4378_246">
							<rect width="120" height="120" fill="white" />
						</clipPath>
					</defs>
				</>
			)}
			{(tier === "basic" || tier === "small-business") && (
				<>
					<g clipPath="url(#clip0_1_142)">
						<path
							d="M88.7097 106.793C85.7058 106.372 83.1873 103.961 81.6179 102.715C79.2719 100.849 76.3004 100.181 70.9829 101.507C64.8996 103.023 61.3833 101.41 56.9772 99.938C48.37 97.0689 43.101 101.604 40.4261 102.877C37.7511 104.15 33.372 104.835 29.3596 105.52C26.7709 105.962 24.8007 106.668 23.3176 107.488C33.4565 115.33 46.1966 120 60.0027 120C73.8088 120 85.9021 115.572 95.9116 108.082C93.9647 107.477 91.5465 107.186 88.7097 106.793Z"
							fill="#DE9351"
						/>
						<path
							d="M88.7097 106.793C85.7058 106.372 83.1873 103.961 81.6179 102.715C79.2719 100.849 76.3004 100.181 70.9829 101.507C70.9829 101.507 76.2141 107.272 81.9037 108.561C84.7405 109.203 86.9058 111.004 88.4536 112.87C91.0638 111.462 93.5494 109.85 95.9116 108.081C93.9647 107.477 91.5465 107.192 88.7097 106.798V106.793Z"
							fill="#DA853B"
						/>
						<path
							opacity="0.8"
							d="M82.3945 60.267C80.8467 60.267 74.4991 68.6262 75.2326 74.483C75.7126 78.3229 78.436 81.4939 82.3945 81.4939C86.353 81.4939 89.0818 78.3282 89.5564 74.483C90.2791 68.6262 84.0663 60.267 82.3945 60.267V60.267Z"
							fill="#2BABF0"
						/>
						<path
							d="M80.1025 81.1056C80.809 81.3536 81.5748 81.4885 82.3945 81.4885C86.353 81.4885 89.0818 78.3228 89.5564 74.4776C90.2791 68.6207 84.0663 60.2616 82.3945 60.2616C82.044 60.2616 81.4507 60.6876 80.7496 61.4103C80.7496 61.4103 86.3098 67.4936 86.3098 73.1131C86.3098 77.0824 83.8992 79.4283 81.9469 80.4261C81.3159 80.7496 80.1025 81.1002 80.1025 81.1002V81.1056Z"
							fill="#2BABF0"
						/>
						<path
							d="M80.809 79.6333C81.0786 79.6333 81.3213 79.4607 81.4076 79.1964C81.5154 78.8675 81.3321 78.5115 80.9977 78.4037C78.01 77.4545 77.8913 74.4614 77.8859 74.3319C77.8752 73.9922 77.5947 73.7171 77.255 73.7171C77.2496 73.7171 77.2442 73.7171 77.2388 73.7171C76.8936 73.7279 76.6186 74.0138 76.624 74.3589C76.6563 75.7395 77.5246 78.6194 80.6094 79.6009C80.6741 79.6225 80.7389 79.6333 80.8036 79.6333H80.809Z"
							fill="#A8E1FF"
						/>
						<path
							opacity="0.8"
							d="M71.4089 21.5721C69.2086 21.5721 60.1861 33.4584 61.2269 41.7852C61.9064 47.2483 65.784 51.7515 71.4089 51.7515C77.0338 51.7515 80.9222 47.2483 81.5909 41.7852C82.6156 33.4584 73.7819 21.5721 71.4089 21.5721Z"
							fill="#2BABF0"
						/>
						<path
							d="M68.1569 51.2013C69.16 51.5572 70.2494 51.746 71.4143 51.746C77.0446 51.746 80.9276 47.2428 81.5963 41.7797C82.621 33.4529 73.7872 21.5667 71.4143 21.5667C70.9182 21.5667 70.0768 22.1707 69.0791 23.2008C69.0791 23.2008 76.9853 31.8512 76.9853 39.8382C76.9853 45.4847 73.5607 48.8176 70.7833 50.2359C69.8827 50.6944 68.1623 51.1959 68.1623 51.1959L68.1569 51.2013Z"
							fill="#2BABF0"
						/>
						<path
							d="M69.16 49.1088C69.5376 49.1088 69.8935 48.8661 70.0121 48.4832C70.1631 48.014 69.9043 47.5071 69.4297 47.3561C65.1854 46.0024 65.0128 41.7473 65.0074 41.5694C64.9912 41.084 64.5975 40.6957 64.1122 40.6957C64.1068 40.6957 64.096 40.6957 64.0906 40.6957C63.5944 40.7065 63.2062 41.1164 63.2169 41.6071C63.2601 43.5756 64.5005 47.6635 68.885 49.0603C68.9767 49.0872 69.0684 49.1034 69.16 49.1034V49.1088Z"
							fill="#A8E1FF"
						/>
						<path
							opacity="0.8"
							d="M43.4461 39.7034C40.0701 39.7034 26.2532 57.9102 27.8441 70.6647C28.8904 79.0347 34.8227 85.9269 43.4461 85.9269C52.0696 85.9269 58.0181 79.0293 59.0481 70.6647C60.6229 57.9048 47.0864 39.7034 43.4461 39.7034Z"
							fill="#2BABF0"
						/>
						<path
							d="M38.4576 85.091C39.9892 85.6357 41.661 85.9269 43.4461 85.9269C52.0696 85.9269 58.0181 79.0293 59.0481 70.6647C60.6229 57.9048 47.0864 39.7034 43.4461 39.7034C42.6857 39.7034 41.3914 40.631 39.8652 42.2111C39.8652 42.2111 51.9725 55.4618 51.9725 67.6931C51.9725 76.3435 46.7251 81.4507 42.4754 83.6241C41.1002 84.3252 38.4576 85.091 38.4576 85.091Z"
							fill="#2BABF0"
						/>
						<path
							d="M40 81.8822C40.5824 81.8822 41.1217 81.51 41.3051 80.9276C41.537 80.2049 41.1379 79.4337 40.4153 79.2018C33.9113 77.1309 33.6524 70.6108 33.6416 70.3357C33.6201 69.5915 33.0107 68.9983 32.2718 68.9983C32.261 68.9983 32.2502 68.9983 32.2341 68.9983C31.479 69.0198 30.8804 69.64 30.8966 70.395C30.9613 73.4097 32.8596 79.671 39.5793 81.8121C39.7196 81.8552 39.8598 81.8768 39.9946 81.8768L40 81.8822Z"
							fill="#A8E1FF"
						/>
					</g>
					<defs>
						<clipPath id="clip0_1_142">
							<rect width="120" height="120" fill="white" />
						</clipPath>
					</defs>
				</>
			)}
			{tier === "standard" && (
				<>
					<g clipPath="url(#clip0_1_141)">
						<path
							opacity="0.8"
							d="M66.5667 66.9987C64.9167 63.6448 64.5328 60.117 65.0547 56.4512C65.9547 50.1575 70.1365 46.9117 75.7222 44.9918C79.64 43.6478 83.7138 43.1438 87.8176 42.8859C89.6775 42.7719 91.5434 42.7479 93.4093 42.7119C94.8792 42.6879 95.4252 43.2398 95.4132 44.7098C95.3652 50.1095 95.0892 55.4852 93.6433 60.729C92.9533 63.2428 91.9754 65.6307 90.4575 67.7726C88.2196 70.9465 85.0817 72.6324 81.3139 73.1303C76.8501 73.7183 72.6624 72.9684 69.0025 70.1485C69.0025 70.1485 67.2266 68.3906 66.5607 66.9927L66.5667 66.9987Z"
							fill="#58B647"
						/>
						<path
							d="M91.7114 42.7479C92.2754 42.7359 92.8393 42.7299 93.4033 42.7179C94.8732 42.6939 95.4192 43.2458 95.4072 44.7158C95.3592 50.1155 95.0832 55.4912 93.6373 60.735C92.9473 63.2488 91.9694 65.6367 90.4515 67.7786C88.2136 70.9524 85.0757 72.6384 81.3079 73.1363C77.6361 73.6223 74.1443 73.1963 70.9824 71.4564C70.9824 71.4564 81.7159 72.6504 87.2116 64.0468C92.3354 56.0312 91.7054 42.7539 91.7054 42.7539L91.7114 42.7479Z"
							fill="#58B647"
						/>
						<path
							opacity="0.8"
							d="M53.4993 64.4368C48.9635 67.1546 44.1698 67.7186 39.154 66.0807C35.3262 64.8328 32.9623 61.9889 31.2704 58.4851C29.5545 54.9333 28.6306 51.1534 28.1206 47.2716C27.7666 44.5598 27.5566 41.8299 27.3226 39.1C27.2146 37.8701 27.7546 37.2161 28.9066 37.1621C34.4443 36.8862 39.97 36.8682 45.4237 38.0921C47.7636 38.6201 50.0015 39.412 52.0954 40.612C55.5572 42.5979 57.5071 45.6457 58.2571 49.4975C59.001 53.3133 58.7071 57.0332 57.0091 60.579C56.7212 61.1789 56.3012 61.7069 55.9412 62.2649C55.6292 62.6009 54.4833 63.8788 53.4933 64.4308L53.4993 64.4368Z"
							fill="#58B647"
						/>
						<path
							d="M39.154 66.0747C44.1698 67.7126 48.9635 67.1546 53.4993 64.4308C54.4893 63.8728 55.6352 62.6009 55.9472 62.2649C56.3072 61.7009 56.7331 61.1729 57.0151 60.579C58.707 57.0331 59.007 53.3133 58.2631 49.4975C57.5131 45.6457 55.5632 42.5979 52.1014 40.612C52.1014 40.612 62.5528 61.9409 39.16 66.0747H39.154Z"
							fill="#58B647"
						/>
						<path
							d="M60.255 101.197C60.255 101.197 60.225 101.197 60.213 101.197C59.355 101.173 58.6771 100.471 58.6771 99.619C58.6771 98.8331 58.6951 94.6573 58.7251 93.8953C58.7251 90.7215 58.5151 84.5298 57.1291 77.7621C55.3232 68.9426 51.0994 57.2791 41.2239 51.7954C40.462 51.3694 40.186 50.4095 40.606 49.6475C41.0259 48.8856 41.9919 48.6096 42.7539 49.0296C51.4234 53.8353 57.4711 63.5788 60.237 77.1921C60.489 78.4281 60.705 79.646 60.885 80.828C63.0808 73.6103 66.5667 68.2406 69.6865 64.5508C75.1462 58.0831 80.588 55.2992 80.816 55.1792C81.5959 54.7833 82.5439 55.1012 82.9399 55.8752C83.3298 56.6552 83.0178 57.6031 82.2439 57.9931C82.1899 58.0171 77.0601 60.669 72.0084 66.6927C65.7387 74.1683 62.3429 83.2698 61.8929 93.7753C61.8929 95.2752 61.8449 99.583 61.8389 99.691C61.7909 100.531 61.0949 101.185 60.261 101.185L60.255 101.197Z"
							fill="#4C8534"
						/>
						<path
							d="M96.7632 101.197C92.1074 101.197 89.1975 99.427 85.7057 97.4891C81.1999 94.9873 76.8982 94.8433 73.0224 95.7252C69.7345 96.4752 67.6766 97.6571 62.6489 98.5991C58.1311 99.445 55.1193 98.6291 52.0354 97.0932C48.4896 95.3292 44.2778 94.5193 40.696 95.6232C37.8281 96.5052 35.0623 98.9051 28.5646 99.2051C24.3948 99.397 20.471 100.818 17.7411 102.594C28.5886 113.357 43.5158 120 60.003 120C76.5562 120 91.5434 113.303 102.397 102.462C102.397 102.462 100.423 101.197 96.7632 101.197Z"
							fill="#DE9351"
						/>
						<path
							d="M73.0163 95.7252C76.8921 94.8433 81.1939 94.9873 85.6997 97.4891C89.1975 99.427 92.1014 101.197 96.7571 101.197C100.417 101.197 102.396 102.462 102.396 102.462C99.8941 104.958 97.1758 107.232 94.272 109.26C94.272 109.26 92.2454 104.221 84.2298 102.241C77.3721 100.549 73.0163 95.7252 73.0163 95.7252Z"
							fill="#DA853B"
						/>
						<path
							d="M43.2639 101.359C43.2639 101.359 42.1239 101.377 41.5719 101.911C41.0199 102.445 40.7679 103.297 41.4279 103.897C42.1719 104.575 43.4439 104.317 43.9359 103.657C44.4279 102.997 44.9619 101.557 43.2639 101.359V101.359Z"
							fill="#AC652F"
						/>
						<path
							d="M56.3852 110.28C56.3852 110.28 55.7132 110.34 55.3712 111.036C55.0292 111.732 55.3472 112.788 56.1692 113.142C57.0932 113.538 58.0172 112.974 58.2632 112.182C58.5092 111.39 58.0472 109.908 56.3852 110.286V110.28Z"
							fill="#AC652F"
						/>
						<path
							d="M78.4101 104.191C78.4101 104.191 77.3721 104.425 76.9941 105.277C76.6821 105.979 77.0361 106.801 77.8581 107.149C78.7821 107.539 79.7901 107.053 80.0361 106.267C80.2821 105.481 80.0721 103.819 78.4041 104.197L78.4101 104.191Z"
							fill="#AC652F"
						/>
					</g>
					<defs>
						<clipPath id="clip0_1_141">
							<rect width="120" height="120" fill="white" />
						</clipPath>
					</defs>
				</>
			)}
			{tier === "pro" && (
				<>
					<g clipPath="url(#clip0_1_140)">
						<path
							opacity="0.9"
							d="M21.324 45.997C18.5034 49.7775 18.2985 54.4479 20.9572 58.3632C21.2323 58.7731 21.7392 59.4203 21.7392 59.4203C21.7392 59.4203 21.1622 59.9811 20.8224 60.3209C17.4302 63.7724 16.6374 69.3488 18.8162 73.8412C19.0967 74.4182 19.3501 74.936 19.3825 74.9953C20.515 76.9745 22.1653 78.3875 23.0551 79.04C24.4088 80.0324 25.9943 80.6957 29.0576 81.4939C29.0576 81.4939 34.0083 82.7774 39.6225 80.7658C40.1079 80.5932 40.6418 80.3667 41.208 80.0809C41.8929 79.7357 42.4808 79.3636 42.9661 79.0185C44.3522 80.2535 47.0648 81.2242 47.0648 81.2242C52.528 82.8367 57.6405 82.0871 62.2785 78.7704C66.744 75.5723 68.6531 70.3411 67.3048 65.3688C66.6685 63.0336 65.3687 61.0813 63.6376 59.334C65.2609 57.4033 66.1615 55.2029 66.237 52.7329C66.3826 48.1866 63.3733 43.8452 58.7677 42.0763L56.9125 41.5208C56.33 39.3744 54.9764 37.7026 54.9764 37.7026C48.2675 28.8257 32.7787 30.3195 28.6369 40.658C28.6045 40.7928 28.5722 40.9276 28.5398 41.0624C28.5129 41.1757 28.4859 41.2889 28.4643 41.4022C26.4312 42.0817 24.0636 43.1873 22.4835 44.6488C22.0574 45.0479 21.4372 45.8245 21.3078 46.0024L21.324 45.997Z"
							fill="#4C8534"
						/>
						<path
							d="M54.0542 61.0759C53.2884 60.2831 52.0264 60.2616 51.2336 61.0274L44.6649 67.3642V52.4687C44.6649 51.3685 43.7697 50.4732 42.6695 50.4732C41.5693 50.4732 40.6741 51.3685 40.6741 52.4687V74.8335L30.6431 66.8464C29.7802 66.1615 28.529 66.3017 27.8441 67.1646C27.1592 68.0275 27.2994 69.2787 28.1623 69.9636L40.6795 79.9299V103.735C40.6795 104.835 41.5747 105.73 42.6749 105.73C43.7751 105.73 44.6703 104.835 44.6703 103.735V72.9028L54.0056 63.8965C54.7984 63.1306 54.82 61.8687 54.0542 61.0759V61.0759Z"
							fill="#AE6935"
						/>
						<path
							d="M54.2483 44.8915C54.2483 44.8915 57.6891 45.7813 59.3286 50.155C60.968 54.5288 58.0342 59.1128 58.0342 59.1128C58.0342 59.1128 62.0089 62.6938 60.2022 70.093C58.1259 78.5978 47.081 81.2242 47.081 81.2242C52.5441 82.8367 57.6567 82.0871 62.2947 78.7704C66.7601 75.5723 68.6693 70.3411 67.321 65.3687C66.6846 63.0336 65.3849 61.0813 63.6538 59.334C65.2771 57.4033 66.1777 55.2029 66.2532 52.7329C66.3988 48.1866 63.3895 43.8452 58.7839 42.0763L56.9287 41.5208C56.3462 39.3744 54.9926 37.7026 54.9926 37.7026C53.3154 35.4807 51.088 33.9113 48.6288 32.9621C48.6288 32.9621 54.34 38.8836 54.2483 44.8915V44.8915Z"
							fill="#4C8534"
						/>
						<path
							d="M54.3184 34.4344C51.4979 38.2149 51.293 42.8853 53.9517 46.8006C54.2268 47.2105 54.7337 47.8576 54.7337 47.8576C54.7337 47.8576 54.1567 48.4185 53.8169 48.7583C50.4247 52.2098 49.6319 57.7862 51.8107 62.2786C52.0911 62.8556 52.3446 63.3733 52.377 63.4327C53.5095 65.4119 55.1598 66.8249 56.0496 67.4774C57.4033 68.4697 58.9888 69.1331 62.052 69.9312C62.052 69.9312 67.0028 71.2148 72.6169 69.2032C73.1023 69.0306 73.6362 68.8041 74.2025 68.5183C74.8874 68.1731 75.4753 67.801 75.9606 67.4558C77.3466 68.6908 80.0593 69.6616 80.0593 69.6616C85.5224 71.2741 90.635 70.5245 95.273 67.2078C99.7384 64.0097 101.648 58.7785 100.299 53.8061C99.6629 51.4709 98.3632 49.5187 96.632 47.7713C98.2553 45.8406 99.156 43.6403 99.2315 41.1703C99.3771 36.624 96.3678 32.2826 91.7622 30.5137L89.907 29.9582C89.3245 27.8118 87.9709 26.14 87.9709 26.14C81.262 17.263 65.7732 18.7569 61.6314 29.0953C61.599 29.2301 61.5667 29.365 61.5343 29.4998C61.5073 29.6131 61.4804 29.7263 61.4588 29.8396C59.4256 30.5191 57.0581 31.6246 55.4779 33.0862C55.0519 33.4852 54.4317 34.2618 54.3023 34.4398L54.3184 34.4344Z"
							fill="white"
						/>
						<path
							opacity="0.8"
							d="M54.3184 34.4344C51.4979 38.2149 51.293 42.8853 53.9517 46.8006C54.2268 47.2105 54.7337 47.8576 54.7337 47.8576C54.7337 47.8576 54.1567 48.4185 53.8169 48.7583C50.4247 52.2098 49.6319 57.7862 51.8107 62.2786C52.0911 62.8556 52.3446 63.3733 52.377 63.4327C53.5095 65.4119 55.1598 66.8249 56.0496 67.4774C57.4033 68.4697 58.9888 69.1331 62.052 69.9312C62.052 69.9312 67.0028 71.2148 72.6169 69.2032C73.1023 69.0306 73.6362 68.8041 74.2025 68.5183C74.8874 68.1731 75.4753 67.801 75.9606 67.4558C77.3466 68.6908 80.0593 69.6616 80.0593 69.6616C85.5224 71.2741 90.635 70.5245 95.273 67.2078C99.7384 64.0097 101.648 58.7785 100.299 53.8061C99.6629 51.4709 98.3632 49.5187 96.632 47.7713C98.2553 45.8406 99.156 43.6403 99.2315 41.1703C99.3771 36.624 96.3678 32.2826 91.7622 30.5137L89.907 29.9582C89.3245 27.8118 87.9709 26.14 87.9709 26.14C81.262 17.263 65.7732 18.7569 61.6314 29.0953C61.599 29.2301 61.5667 29.365 61.5343 29.4998C61.5073 29.6131 61.4804 29.7263 61.4588 29.8396C59.4256 30.5191 57.0581 31.6246 55.4779 33.0862C55.0519 33.4852 54.4317 34.2618 54.3023 34.4398L54.3184 34.4344Z"
							fill="#58B647"
						/>
						<path
							d="M87.0433 49.5187C86.2775 48.7259 85.0155 48.7043 84.2227 49.4701L77.654 55.8069V40.9114C77.654 39.8112 76.7588 38.916 75.6586 38.916C74.5584 38.916 73.6632 39.8112 73.6632 40.9114V63.2763L63.6322 55.2892C62.7693 54.6043 61.5181 54.7445 60.8332 55.6074C60.1483 56.4703 60.2885 57.7215 61.1514 58.4064L73.6686 68.3727V102.931C73.6686 104.031 74.5638 104.927 75.664 104.927C76.7642 104.927 77.6594 104.031 77.6594 102.931V61.3402L86.9947 52.3338C87.7875 51.568 87.8091 50.3061 87.0433 49.5133V49.5187Z"
							fill="white"
						/>
						<path
							d="M87.0433 49.5187C86.2775 48.7259 85.0155 48.7043 84.2227 49.4701L77.654 55.8069V40.9114C77.654 39.8112 76.7588 38.916 75.6586 38.916C74.5584 38.916 73.6632 39.8112 73.6632 40.9114V63.2763L63.6322 55.2892C62.7693 54.6043 61.5181 54.7445 60.8332 55.6074C60.1483 56.4703 60.2885 57.7215 61.1514 58.4064L73.6686 68.3727V102.931C73.6686 104.031 74.5638 104.927 75.664 104.927C76.7642 104.927 77.6594 104.031 77.6594 102.931V61.3402L86.9947 52.3338C87.7875 51.568 87.8091 50.3061 87.0433 49.5133V49.5187Z"
							fill="#AE6935"
							fillOpacity="0.85"
						/>
						<path
							d="M87.2374 33.3288C87.2374 33.3288 90.6782 34.2187 92.3176 38.5924C93.9571 42.9662 91.0233 47.5502 91.0233 47.5502C91.0233 47.5502 94.998 51.1312 93.1913 58.5304C91.115 67.0352 80.0701 69.6616 80.0701 69.6616C85.5332 71.2741 90.6458 70.5245 95.2838 67.2078C99.7492 64.0097 101.658 58.7785 100.31 53.8061C99.6737 51.4709 98.374 49.5187 96.6428 47.7713C98.2661 45.8406 99.1668 43.6403 99.2423 41.1703C99.3879 36.624 96.3786 32.2826 91.7729 30.5137L89.9177 29.9582C89.3353 27.8118 87.9817 26.14 87.9817 26.14C86.3044 23.918 84.0771 22.3487 81.6179 21.3995C81.6179 21.3995 87.3291 27.321 87.2374 33.3288V33.3288Z"
							fill="#58B647"
						/>
						<path
							d="M60.0027 120C73.3774 120 85.7274 115.621 95.6991 108.227C95.5265 107.38 95.3431 106.733 95.0789 106.043C94.4695 104.447 93.4232 103.222 93.2722 103.044C92.6466 102.322 91.2768 100.903 89.098 100.315C87.383 99.8571 85.2636 100.046 83.6672 100.504C82.0062 100.984 80.7065 101.658 79.8058 102.219C78.7218 97.2091 75.5184 94.2106 71.1177 94.0812C66.868 93.9571 63.039 96.9125 61.4858 102.91C58.1151 102.279 55.575 103.594 53.7414 106.588C52.5981 101.168 48.8338 98.4981 44.3845 98.4657C40.9384 98.4387 37.3898 100.849 36.2572 103.632C35.8905 103.406 32.4012 101.664 28.7933 103.034C26.0752 104.064 24.727 106.544 24.2362 108.162C34.2241 115.589 46.601 119.989 60.0081 119.989L60.0027 120Z"
							fill="white"
						/>
						<path
							opacity="0.85"
							d="M60 120C73.3747 120 85.7283 115.631 95.7 108.237C95.5275 107.391 95.3441 106.733 95.0798 106.043C94.4704 104.447 93.4242 103.222 93.2732 103.044C92.6476 102.322 91.2778 100.903 89.099 100.315C87.384 99.8571 85.2645 100.046 83.6682 100.504C82.0072 100.984 80.7074 101.658 79.8068 102.219C78.7228 97.2091 75.5194 94.2106 71.1187 94.0812C66.869 93.9571 63.0399 96.9125 61.4867 102.91C58.1161 102.279 55.576 103.594 53.7424 106.588C52.599 101.168 48.8347 98.498 44.3855 98.4657C40.9393 98.4387 37.3907 100.849 36.2582 103.632C35.8915 103.406 32.4022 101.664 28.7943 103.034C26.0762 104.064 24.7219 106.566 24.2312 108.183C34.219 115.61 46.593 120 60 120Z"
							fill="#4C8534"
						/>
						<path
							d="M95.0788 106.048C94.4694 104.452 93.4232 103.228 93.2722 103.05C92.6466 102.327 91.2768 100.909 89.098 100.321C87.383 99.8625 85.2636 100.051 83.6672 100.51C82.8205 100.752 82.0709 101.049 81.4183 101.351C82.3244 101.95 85.8999 103.81 87.4262 107.041C88.5371 109.381 88.8877 111.323 88.9578 112.558C91.3037 111.263 93.558 109.823 95.699 108.237C95.5265 107.391 95.3431 106.733 95.0788 106.043V106.048Z"
							fill="#4C8534"
						/>
					</g>
					<defs>
						<clipPath id="clip0_1_140">
							<rect width="120" height="120" fill="white" />
						</clipPath>
					</defs>
				</>
			)}
		</svg>
	);
};

export default TiersIcon;
