import React from "react";
import { SVGGradient } from "../SVGGradient";

// Types
import type IconProps from "Types/IconProps";

const SchoolIcon: React.FC<IconProps> = ({
	width,
	height,
	className = "school-icon",
	id,
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "24px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 24 24"
			fill={fill === "gradient" ? "url(#svgGradient)" : fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.75 4.84074V10.7648L0.593751 12.4526C0.228176 12.6481 0 13.0285 0 13.4425V22.877C0 23.4972 0.50368 24 1.125 24H4.87244L4.875 24L9.375 24L10.5 24V19.8824C10.5 19.6757 10.6679 19.5081 10.875 19.5081H13.125C13.3321 19.5081 13.5 19.6757 13.5 19.8824V24L14.625 24L19.125 24L19.1276 24H22.875C23.4963 24 24 23.4972 24 22.877V13.4425C24 13.0285 23.7718 12.6481 23.4062 12.4526L20.25 10.7648V4.84074C20.25 4.42267 20.0173 4.0392 19.6462 3.84553L12.5212 0.127767C12.1947 -0.0425889 11.8053 -0.0425891 11.4788 0.127767L4.35384 3.84553C3.98266 4.0392 3.75 4.42267 3.75 4.84074ZM16.5 9.02687C16.5 11.5077 14.4853 13.5188 12 13.5188C9.51472 13.5188 7.5 11.5077 7.5 9.02687C7.5 6.54605 9.51472 4.53494 12 4.53494C14.4853 4.53494 16.5 6.54605 16.5 9.02687ZM12.5625 6.40658C12.5625 6.09647 12.3107 5.84508 12 5.84508C11.6893 5.84508 11.4375 6.09647 11.4375 6.40658V9.02687C11.4375 9.17579 11.4968 9.31861 11.6023 9.42391L13.1023 10.9212C13.3219 11.1405 13.6781 11.1405 13.8977 10.9212C14.1174 10.7019 14.1174 10.3464 13.8977 10.1272L12.5625 8.7943V6.40658Z"
			/>
			{fill === "gradient" && <SVGGradient />}
		</svg>
	);
};

export default SchoolIcon;
