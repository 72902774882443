import React, { PropsWithChildren, useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";

// CC
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import ModalBanner from "CCW-Components/CCW-ModalBanner";
import FIGAddress from "../CCW-FIGAddress";
import ButtonLoader from "../CCW-ButtonLoader";

// Hooks
import useEntities from "Hooks/UseAccountEntities";
import useAlert from "Hooks/UseAlert";
import useSession from "Hooks/UseSession";
import useEntity from "Hooks/UseEntity";

// Helpers
import updateEntityAddress from "Helpers/NetworkingHelpers/UpdateEntityAddress";
import isError from "Helpers/TypeHelpers/IsError";

// Styles
import { Container, SiteName, AddressBox, AlignRight } from "./styles";

// Types
import type Entity from "Types/Entity";

export const AddressUpdateModal: React.FC<PropsWithChildren<unknown>> = ({
	...props
}) => {
	// Hooks
	const [activeUser, , , { activeAccount: account, activeEntity: entity }] =
		useSession();
	const [entities, entitiesError, entitiesMutate] = useEntities(account?._id);
	const [, entityError, entityMutate] = useEntity(entity?._id);
	const location = useLocation();
	const navigate = useNavigate();
	const [, reportAlert] = useAlert();
	const accountType = account?.accountType;

	const sites: Entity[] =
		entity && accountType === "home"
			? [entity]
			: Object.values(entities || {}).flat();

	const sitesWithOldAddress = sites?.filter(
		(site) => !("street" in site.address),
	);
	const hash = "updateToNewAddress";

	useEffect(() => {
		if (
			sitesWithOldAddress?.length &&
			location?.hash !== hash &&
			(account?.permission === "Account Admin" ||
				account?.permission === "System Admin")
		) {
			// Open the modal if there are addresses that are not up to date and the user is an admin
			navigate({ hash });
		} else if (sitesWithOldAddress?.length === 0 && location?.hash === hash) {
			// Close the modal once all addresses are up to date
			navigate(location.pathname);
		}
	}, [
		account?.permission,
		location?.hash,
		location.pathname,
		navigate,
		sitesWithOldAddress?.length,
	]);

	// Loading state
	if (activeUser && !entities && !entitiesError && !entity && !entityError) {
		return <BlockLoader message="Loading addresses..." />;
	}

	if (sitesWithOldAddress?.length) {
		// Normal state
		const formKeyName = "addressIDs";
		return (
			<ModalBanner
				id={"updateToNewAddress"}
				matchType={"hash"}
				headline={"Update address"}
				subheading="Sorry for the interruption, we're improving our carbon calculations and we'll need you to update your addresses."
				{...props}>
				<Formik
					initialValues={{
						[formKeyName]: sitesWithOldAddress.map(() => ({
							_id: "",
							description: "",
						})),
					}}
					validationSchema={Yup.object({
						[formKeyName]: Yup.array().of(
							Yup.object({
								_id: Yup.string().required("Please enter address"),
							}),
						),
					})}
					onSubmit={async (values, { setSubmitting }) => {
						setSubmitting(true);
						try {
							await Promise.all(
								sitesWithOldAddress.map((site, index) =>
									updateEntityAddress(
										site._id,
										values[formKeyName]?.[index]?._id as unknown as string,
									),
								),
							);
							reportAlert(
								"Thanks, your addresses are now up to date",
								"success",
							);
							await entitiesMutate();
							await entityMutate();
							navigate(location.pathname);
						} catch (error) {
							reportAlert(
								(isError(error) && error.message) ||
									"An error occurred. Please contact us if problem persists",
								"error",
							);
							entitiesMutate();
							entityMutate();
						} finally {
							setSubmitting(false);
						}
					}}>
					{({ isSubmitting, errors, dirty }) => (
						<Form>
							<FieldArray name="sitesAddressesToUpdate">
								{() => (
									<Container>
										{sitesWithOldAddress?.map((site, index) => (
											<AddressBox key={index}>
												<SiteName>
													{!!("name" in site) && site.name}{" "}
													{"postcode" in site.address &&
														`(${site.address.postcode}, ${site.address.state})`}
												</SiteName>
												<FIGAddress
													key={index}
													name={`${formKeyName}[${index}]`}
												/>
											</AddressBox>
										))}
									</Container>
								)}
							</FieldArray>
							<AlignRight>
								<ButtonLoader
									type="submit"
									loading={isSubmitting}
									disabled={!dirty || !!errors[formKeyName]?.length}>
									Submit
								</ButtonLoader>
							</AlignRight>
						</Form>
					)}
				</Formik>
			</ModalBanner>
		);
	}

	return null;
};

export default AddressUpdateModal;
