import React from "react";

// Types
import type IconProps from "Types/IconProps";

const BureaucraticIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "bureaucratic-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "64px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 64 58"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M32.7506 22.1774L58.8011 37.3709C59.7935 37.9497 59.7934 39.3836 58.8011 39.9624L32.7506 55.1559C32.2836 55.4284 31.706 55.4283 31.239 55.1558L5.19726 39.9623C4.20513 39.3834 4.20513 37.9499 5.19727 37.371L31.239 22.1775C31.706 21.905 32.2836 21.905 32.7506 22.1774Z"
				fill="white"
				stroke={fill ? fill : "#828282"}
				strokeWidth="3"
			/>
			<path
				d="M32.7506 12.1774L58.8011 27.3709C59.7935 27.9497 59.7934 29.3836 58.8011 29.9624L32.7506 45.1559C32.2836 45.4284 31.706 45.4283 31.239 45.1558L5.19726 29.9623C4.20513 29.3834 4.20513 27.9499 5.19727 27.371L31.239 12.1775C31.706 11.905 32.2836 11.905 32.7506 12.1774Z"
				fill="white"
				stroke={fill ? fill : "#828282"}
				strokeWidth="3"
			/>
			<path
				d="M32.7506 2.17739L58.8011 17.3709C59.7935 17.9497 59.7934 19.3836 58.8011 19.9624L32.7506 35.1559C32.2836 35.4284 31.706 35.4283 31.239 35.1558L5.19726 19.9623C4.20513 19.3834 4.20513 17.9499 5.19727 17.371L31.239 2.1775C31.706 1.90502 32.2836 1.90498 32.7506 2.17739Z"
				fill="white"
				stroke={fill ? fill : "#828282"}
				strokeWidth="3"
			/>
			<path
				d="M30.5 26.5L46.5 17"
				stroke={fill ? fill : "#828282"}
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M23.5 22.5L39.5 13"
				stroke={fill ? fill : "#828282"}
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.5 18.5L32.5 9"
				stroke={fill ? fill : "#828282"}
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default BureaucraticIcon;
