import styled from "@emotion/styled";

// Styles
export const Container = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "stretch",
}));

export const MarginTop = styled.div(() => ({
	margin: "2rem 0",
	textAlign: "right",
	display: "flex",
	justifyContent: "flex-end",
}));

export const ExplanationNote = styled.p(({ theme }) => ({
	...theme.webFonts.F6,
	color: theme.colors.D3,
	margin: 0,
}));
