import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useField, useFormikContext } from "formik";

// CC
import FIGDefault from "CCW-Components/CCW-FIGDefault";
import FIGAddress from "CCW-Components/CCW-FIGAddress";
import FIGPhone from "CCW-Components/CCW-FIGPhone";
import Button from "CCW-Components/CCW-ButtonLoader";

// Helpers
import getLGAsForAddress from "Helpers/NetworkingHelpers/GetLGAsForAddress";

// Styles
import { Container, MarginTop } from "./styles";
import { Header } from "../../styles";

// Types
import type OrganisationDetailsStepProps from "./types";
import type Referral from "Types/Referrals";

// Data
import { FormValues } from "../SignUpWizard/FormLogic";

export const OrganisationDetailsStep: React.FC<
	OrganisationDetailsStepProps
> = ({ to }) => {
	// Hooks
	const { isSubmitting, errors, dirty, submitForm, validateForm } =
		useFormikContext();

	const [{ value: address }] = useField<FormValues["address"]>("address");
	const [{ value: accountType }] =
		useField<FormValues["accountType"]>("accountType");
	const [LGAOptionsField, , LGAOptionsFieldHelpers] = useField<
		Referral[] | undefined
	>("LGAOptions");
	const [, , LGAHelpers] = useField<string>("lga");

	const [loadingPartners, setLoadingPartners] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		validateForm();
		!accountType && navigate("/sign-up/1");
	}, [accountType, navigate, validateForm]);

	// Helpers
	const handleSubmit = async () => {
		setLoadingPartners(true);
		try {
			const [lgas] = await getLGAsForAddress(address._id);

			LGAOptionsFieldHelpers.setValue(lgas);
			LGAOptionsField.value &&
				LGAHelpers.setValue(LGAOptionsField.value[0]._id);
			setLoadingPartners(false);
			submitForm();
			if (lgas?.length) {
				navigate(to.withPartner);
			} else {
				throw "fail";
			}
		} catch {
			LGAOptionsFieldHelpers.setValue([]);
			setLoadingPartners(false);
			await submitForm();
			navigate(to.withoutPartner);
		}
	};

	return (
		<Container>
			<Header>{accountType} Details</Header>
			<div>
				<FIGDefault
					id="organisationName"
					name="organisationName"
					label={`Name of ${accountType}`}
					type="text"
					required={true}
				/>
				<FIGDefault
					id="entityName"
					name="entityName"
					label={`Name of main ${accountType} ${
						accountType === "business" ? "site" : "campus"
					}`}
					type="text"
					required={true}
				/>
				<FIGPhone
					id="phone"
					name="phone"
					label="Contact Number"
					required={true}
				/>
				{accountType === "school" && (
					<>
						<FIGDefault
							id="siteEmail"
							name="siteEmail"
							type="email"
							label="Campus Email Address"
							required={true}
						/>
					</>
				)}
				<>
					<FIGAddress name={"address"} />
					<span className="text-sm text-blueberry-400">
						NOTE: We use your address to calculate your emissions and to
						determine eligibility for any partnership discounts.
						{accountType !== "home" &&
							" As carbon calculations are quite complex, we kindly request your phone number to assist us in setting up your account."}
					</span>
				</>

				<MarginTop>
					<Button
						type="submit"
						loading={isSubmitting || loadingPartners}
						disabled={isSubmitting || !dirty || !!Object.entries(errors).length}
						onClick={handleSubmit}>
						Continue
					</Button>
				</MarginTop>
			</div>
		</Container>
	);
};

export default OrganisationDetailsStep;
