import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import type { JSONApi, DataHookResponse, ResourceObject } from "Types/JSONApi";
import type Account from "Types/Account";
import type Entity from "Types/Entity";

type Relationships = {
	account: {
		links: { related: string };
		data: { type: "accounts"; id: string };
	};
};

export type Related = {
	account: Account;
}[];

type Included = ResourceObject<Account, undefined, "accounts">[];

type Meta = { siteCount: number; siteLimit: number };

export type Response = JSONApi<
	Entity[],
	Relationships,
	Included,
	Meta,
	Related
>;

/**
 * Get all the entities of a particular account for a supplied account Id - The first entity in the array will always be the main one
 * @param accountId Id of the account whose entities we want to fetch
 * @returns a DataHookResponse tuple of Entity, Error, Mutate, Related (account), and the full response

 */
const useAccountEntities = (
	accountId: string | undefined,
): DataHookResponse<Entity[], Related, Response> => {
	const { data, error, mutate } = useSWR<
		JSONApi<Entity[], Relationships, Included, Meta, Related>,
		Error
	>(
		accountId
			? [
					`${
						import.meta.env.VITE_BASE_URL
					}executive/accounts/${accountId}/entities`,
					1,
			  ]
			: null,
		getReq,
		{
			shouldRetryOnError: false,
		},
	);

	const [entities, related, fullJSONApiResponse] = transformAPIResponse(
		data,
		[],
	);

	return [entities, error, mutate, related, fullJSONApiResponse];
};

export default useAccountEntities;
