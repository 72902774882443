import React from "react";
import { SVGGradient } from "../SVGGradient";

// Types
import IconProps from "Types/IconProps";

/** Customizable svg icon */
const HomeIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "home-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "64px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 32 32"
			fill={fill === "gradient" ? "url(#svgGradient)" : fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 11.6461C0 10.7436 0.456409 9.90242 1.21296 9.41045L2.66676 8.46506V2.47808H8.00027V4.99676L15.419 0.172449C15.7726 -0.0574832 16.2285 -0.057483 16.5821 0.172449L30.7881 9.41045C31.5447 9.90242 32.0011 10.7436 32.0011 11.6461V32H0V11.6461ZM12.5338 23.9485C12.2392 23.9485 12.0004 24.1873 12.0004 24.4819V32H20.0007V24.4819C20.0007 24.1873 19.7619 23.9485 19.4673 23.9485H12.5338Z"
			/>
			{fill === "gradient" && <SVGGradient />}
		</svg>
	);
};

export default HomeIcon;
