// Helpers
import patchReq from "../PatchReq";

// Types
import { PartnerSignUp } from "Types/SignUp";

/**
 * @async
 * Finish the partner's sign up updating their account
 * @param data.user User information needed to update the partner
 *  @param data.payment Payment information needed to finish the partner's sign up
 *  @param data.token Validated partner token
 * @throws Networking error
 */
const updateFinishPartnerSignUp = async (data: PartnerSignUp) => {
	const response = await patchReq(
		`${import.meta.env.VITE_BASE_URL}executive/auth/partner/sign-up`,
		data,
	);
	return response;
};

export default updateFinishPartnerSignUp;
