import React from "react";

// Custom Hooks
import usePlatform from "Hooks/UsePlatform";

// Helpers
import sentenceCase from "Helpers/StringHelpers/SentenceCase";

// Styles
import {
	Container,
	LoginContainer,
	Card,
	TitleContainer,
	PlatformInfo,
	PlatformTitle,
	Title,
	Welcome,
	Header,
	Heading,
} from "./styles";

// Assets
import SchoolIcon from "Assets/SVG/SchoolIcon";
import HomeIcon from "Assets/SVG/HomeIcon";

// Types
import FormCardProps from "./types";
import Platform from "Types/Platform";

const FormCard: React.FC<FormCardProps> = ({
	title,
	heading,
	text,
	className,
	...props
}) => {
	// Hooks
	const platform = usePlatform();

	// Helpers;
	const platformTitle = (platform: Platform) => {
		switch (platform) {
			case "partner":
				return "ClimateClever Partners";
			case "admin":
				return "Admin Panel";
			default:
				return sentenceCase(platform);
		}
	};

	// Main state
	return (
		<Container className={className}>
			<LoginContainer>
				<TitleContainer>
					<PlatformInfo>
						{platform === "school" && <SchoolIcon width="24px" height="24px" />}
						{platform === "home" && <HomeIcon width="24px" height="24px" />}
						<PlatformTitle>{platformTitle(platform)}</PlatformTitle>
					</PlatformInfo>
					<Title>{title}</Title>
					<Welcome>{text}</Welcome>
				</TitleContainer>
				{heading ? (
					<Card>
						<Header {...props}>
							<Heading>{heading}</Heading>
						</Header>
						{props.children}
					</Card>
				) : (
					props.children
				)}
			</LoginContainer>
		</Container>
	);
};

export default FormCard;
