import { SVGGradient } from "Assets/SVG/SVGGradient";

// Types
import IconProps from "Types/IconProps";

const HistoricIcon: React.FC<IconProps> = ({
	width,
	height,
	className = "historic-icon",
	fill,
	id,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "29px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 29 28"
			fill={fill === "gradient" ? "url(#svgGradient)" : fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.625 5.90625C14.2291 5.90625 14.7188 6.39594 14.7188 7V13.7812H21.5C22.1041 13.7812 22.5938 14.2709 22.5938 14.875C22.5938 15.4791 22.1041 15.9688 21.5 15.9688H13.625C13.0209 15.9688 12.5312 15.4791 12.5312 14.875V7C12.5312 6.39594 13.0209 5.90625 13.625 5.90625Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.67639 7.18226C7.2956 5.08749 9.66134 3.55915 12.4679 3.03901C18.5261 1.91624 24.3475 5.91724 25.4703 11.9755C26.5931 18.0338 22.5921 23.8551 16.5338 24.9779C10.9328 26.0159 5.53277 22.6738 3.86362 17.3759C3.6821 16.7998 3.0679 16.4799 2.49175 16.6614C1.91561 16.8429 1.59571 17.4571 1.77722 18.0333C3.77441 24.3724 10.2315 28.3707 16.9324 27.1288C24.1786 25.7859 28.9641 18.823 27.6212 11.5769C26.2782 4.33072 19.3154 -0.454789 12.0692 0.888139C8.5609 1.53834 5.63016 3.5061 3.69228 6.18368L1.53916 5.10005C1.29696 4.97816 1.00565 5.01059 0.796193 5.18277C0.586733 5.35496 0.498546 5.63448 0.571272 5.89569L1.93699 10.801C1.98876 10.9869 2.11656 11.1424 2.28896 11.2291C2.46136 11.3159 2.66236 11.3259 2.84253 11.2567L7.59583 9.43104C7.84894 9.33383 8.0209 9.09646 8.03439 8.82565C8.04788 8.55484 7.90037 8.30156 7.65817 8.17966L5.67639 7.18226Z"
				fill="white"
			/>
			{fill === "gradient" && <SVGGradient />}
		</svg>
	);
};

export default HistoricIcon;
