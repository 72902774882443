import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

// CC
import CCButton from "CCW-Components/CCW-CCButton";

// Hooks
import useBreakpoints from "Hooks/UseBreakpoints";

// Helpers
import { breakpoints } from "Helpers/WebUIHelpers/Breakpoints";

// Assets
import { SparklesIcon } from "Assets/SVG";

// Styles
import {
	Bullets,
	Container,
	Title,
	Flex,
	HeaderImageBox,
	ReportLink,
} from "./styles";

// Types
import type PaywallProps from "./types";

// Data
import { getContent } from "./Data";

export const Paywall: React.FC<PaywallProps> = ({ feature, ...props }) => {
	const navigate = useNavigate();
	const { title, points, headerImage: HeaderImage } = getContent(feature);
	const theme = useTheme();

	const currentBreakpointWidth = useBreakpoints();

	const isMobile = currentBreakpointWidth < breakpoints[0];

	return (
		<Container {...props}>
			<HeaderImageBox>
				<HeaderImage width={isMobile ? "40rem" : "52rem"} />
			</HeaderImageBox>
			<Title>{title}</Title>
			<Bullets>
				{points.map(({ text, icon: Icon, iconProp }, index) => (
					<li key={index}>
						<Icon
							height="2.8rem"
							width="2.8rem"
							fill={"white"}
							{...(iconProp === "innerFill" && {
								innerFill: theme.colors.Blueberry400,
							})}
							{...(iconProp === "outline" && {
								outline: theme.colors.grey50,
							})}
						/>
						{text}
					</li>
				))}
			</Bullets>
			<CCButton
				variant="primary"
				colorVariant="blueberry"
				border
				onClick={() => navigate({ pathname: "/settings/upgrade-plan" })}
				icon={SparklesIcon}>
				<Flex>
					<SparklesIcon width={"1.6rem"} height={"1.6rem"} />
					Upgrade Plan
				</Flex>
			</CCButton>
			{feature === "report" && (
				<ReportLink
					href={`${
						import.meta.env.VITE_IMAGE_BASE_URL
					}carbon-reports/example.pdf`}
					target="_blank">
					View example <strong>Carbon Report</strong>
				</ReportLink>
			)}
		</Container>
	);
};

export default Paywall;
