import React from "react";

// Types
import type IconProps from "Types/IconProps";

const CarbonIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "carbon-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "64px" } : {})}
			{...{ className, id, height, ...props }}
			width={width}
			height={height}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.5331 28.5107C5.59366 27.0477 -8.6592 20.7908 7.48974 0C16.2263 5.92464 19.5903 11.1385 20.2119 15.4497C16.0378 16.9457 13.1598 19.8535 13.5202 25.1153C11.2455 19.7047 9.45856 12.2212 8.69153 7.55458C8.30637 13.0064 9.11141 22.7001 10.5331 28.5107ZM18.4893 31.4978C20.1887 28.1867 22.379 25.2012 25.1496 22.6786C21.7492 24.5598 18.7257 26.9469 16.1254 29.8183C14.9203 26.4774 12.4225 15.6084 31.0908 16.2068C31.6346 33.2782 22.5939 32.6483 18.4893 31.4978Z"
				fill={fill != null ? fill : "#5E9842"}
			/>
		</svg>
	);
};

export default CarbonIcon;
