import { useField } from "formik";
import React, { useState } from "react";

// CC
import { DropDownInputConverter as DropDownInput } from "../CCW-FIGDropDownInputNew";
import FormGroup from "../CCW-FormGroup";
import InputLabel from "../CCW-InputLabel";

// Hooks
import useDebouncer from "Hooks/UseDebouncer";
import useAlert from "Hooks/UseAlert";

// Helpers
import getAddressSuggestions from "Helpers/NetworkingHelpers/GetAddressAutocompleteOptions";
import isError from "Helpers/TypeHelpers/IsError";

// Styles
import { Container } from "./styles";

// Types
import FIGAddressProps from "./types";
import { AddressRecommendation } from "Types/Address";

const FIGAddress: React.FC<FIGAddressProps> = ({
	name,
	label = "Please enter your address",
	required,
	disabled,
	...props
}) => {
	// Hooks
	const [field, meta, helpers] = useField<AddressRecommendation | undefined>(
		name,
	);
	const [, reportAlert] = useAlert();
	const [addressRecommendations, setAddressRecommendations] = useState<
		{ _id: string; description: string }[]
	>([
		{
			_id: field.value?._id ?? "",
			description: field.value?.description ?? "",
		},
	]); // Initialise the option as the current address
	const debounce = useDebouncer(300);

	//Helpers
	const handleAddressGuess = async (addressInput: string): Promise<void> => {
		if (addressInput.length >= 5) {
			debounce(async () => {
				try {
					const [addressSuggestions] = await getAddressSuggestions(
						addressInput,
					);
					addressSuggestions && setAddressRecommendations(addressSuggestions);
				} catch (error) {
					reportAlert(
						isError(error)
							? error.message
							: "Action failed. Please contact support",
						"error",
					);
				}
			});
		}
	};

	const getSingleErrorMessage = (): string | undefined => {
		const errors = meta.touched && meta.error ? meta.error : undefined;
		if (errors && typeof errors === "object") {
			return Object.values(errors)[0] as string;
		} else if (errors) {
			return errors;
		}
	};

	return (
		<Container {...props}>
			<FormGroup>
				<InputLabel htmlFor={name} text={label} required={required} />
				<DropDownInput
					onSelect={(_, selectedAddressId) => {
						helpers.setValue(
							addressRecommendations.find(
								(addressRecommendation) =>
									addressRecommendation._id === selectedAddressId,
							),
						);
					}}
					handleSearch={(value) => handleAddressGuess(value)}
					error={getSingleErrorMessage()}
					options={addressRecommendations.map((addressRecommendation) => ({
						label: addressRecommendation.description,
						value: addressRecommendation._id,
					}))}
					name={name}
					placeholder={"e.g. 123 Wellington st"}
					value={
						field.value && "_id" in field.value ? field.value._id : undefined
					}
					disabled={disabled}
				/>
			</FormGroup>
		</Container>
	);
};

export default FIGAddress;
