import React from "react";
import { useNavigate } from "react-router-dom";

// CC
import Button from "../CCW-CCButton";

// Types
import { ButtonLinkProps } from "./types";

const ButtonLink: React.FC<ButtonLinkProps> = ({
	to,
	onClick,
	confirmationMessage,
	...rest
}) => {
	// Hooks
	const navigate = useNavigate();

	return (
		<Button
			{...rest}
			onClick={(event) => {
				if (!!confirmationMessage && !window.confirm(confirmationMessage)) {
					return;
				}
				onClick && onClick(event);
				navigate(to);
			}}
		/>
	);
};

export default ButtonLink;
