// Helpers
import patchReq from "../PatchReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import { JSONApi, DataHelperResponse, Update } from "Types/JSONApi";
import Subscription, {
	SubscriptionPeriod,
	SubscriptionStatusUpdateOptions,
} from "Types/Subscription";

/**
 * The shape of the whole JSON:API response from the backend.
 * You can leave any parts that the backend doesn't supply as `undefined`.
 */
type Response = JSONApi<
	Subscription,
	undefined,
	undefined,
	undefined,
	undefined
>;

/**
 * @async
 * Either upgrades / downgrades or activates / cancels a subscription
 * Only do one or the other, not both
 * @param nextAutorenewOrSubscription either the next tier or the next status
 * @param status the next subscription status
 * @returns a DataHelperResponse tuple of Subscription, and the full response
 * @throws Networking error
 */
const updateSubscription = async (
	accountID: string,
	subscriptionID: string,
	nextAutorenewOrSubscriptionDetails:
		| {
				autoRenew: boolean;
		  }
		| {
				tier: SubscriptionStatusUpdateOptions;
				billingPeriod: SubscriptionPeriod;
		  },
): Promise<DataHelperResponse<Subscription, undefined, Response>> => {
	const requestBody: Update<typeof nextAutorenewOrSubscriptionDetails> = {
		data: {
			id: subscriptionID,
			type: "subscriptions",
			attributes: nextAutorenewOrSubscriptionDetails,
			relationships: undefined,
			meta: undefined,
		},
	};
	const response = await patchReq<Response>(
		`${
			import.meta.env.VITE_BASE_URL
		}executive/accounts/${accountID}/subscriptions/${subscriptionID}`,
		requestBody,
		1,
	);

	const [subscription, related, fullResponse] = transformAPIResponse(
		response,
		{},
	);
	return [subscription, related, fullResponse];
};

export default updateSubscription;
