import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

// CC
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import DefaultPage from "CCW-Components/CCW-DefaultPage";

// Hooks
import useValidateReferral from "Hooks/ValidateReferral";

// Local Components
import Layout from "CCW-Components/CCW-Layout";
import SignUpWizard from "./Components/SignUpWizard";
import PartnerSignUpWizard from "./Components/PartnerSignUpWizard";

// Types
import type SignUpPageProps from "./types";
import type Referral from "Types/Referrals";
import { AccountType } from "Types/Account";

export const SignUpPage: React.FC<SignUpPageProps> = ({
	onSuccess,
	wizardType,
}) => {
	// State
	const [progress, setProgress] = useState(0);
	const location = useLocation();
	const [persistedReferrer, setPersistedReferrer] = useState<
		Referral | undefined
	>();
	const [persistedRequestedAccountType, setPersistedRequestedAccountType] =
		useState<AccountType | undefined>();

	const referralCode =
		new URLSearchParams(location.search).get("referral-code") ||
		new URLSearchParams(location.search).get("referralCode");
	const partnerCode = new URLSearchParams(location.search).get("partner"); // Deprecated
	const requestedAccountType: AccountType | undefined = new URLSearchParams(
		location.search,
	).get("account-type") as AccountType | undefined;

	// Hooks
	const [referral, referralError] = useValidateReferral(
		referralCode || partnerCode || "",
	);

	useEffect(() => {
		if (!persistedReferrer && referral) {
			setPersistedReferrer(referral);
		}
	}, [persistedReferrer, referral]);

	useEffect(() => {
		if (!persistedRequestedAccountType && requestedAccountType) {
			setPersistedRequestedAccountType(requestedAccountType);
		}
	}, [persistedRequestedAccountType, requestedAccountType]);

	// Loading State
	if (referralCode && !referralError && !persistedReferrer) {
		return <BlockLoader message="Loading..." />;
	}

	return (
		<DefaultPage defaultPadding={false} onNavigation={true}>
			{import.meta.env.VITE_HOLIDAY === "xmas" && (
				<Helmet>
					<script src="https://app.embed.im/snow.js" defer></script>
				</Helmet>
			)}
			<Layout
				{...{ progress }}
				referrerImages={
					persistedReferrer
						? {
								logoURL: persistedReferrer?.logo || undefined,
								bannerURL: persistedReferrer.banner || undefined,
						  }
						: undefined
				}
				referrerName={persistedReferrer?.name}>
				{wizardType === "regular" ? (
					<SignUpWizard
						referral={persistedReferrer}
						accountType={persistedRequestedAccountType}
						onProgressChange={(nextProgress) => setProgress(nextProgress)}
						onSuccess={onSuccess}
						isReferralError={referralError && !persistedReferrer}
					/>
				) : (
					<PartnerSignUpWizard
						onProgressChange={(nextProgress) => setProgress(nextProgress)}
						onSuccess={onSuccess}
					/>
				)}
			</Layout>
		</DefaultPage>
	);
};

export default SignUpPage;
