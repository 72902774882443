import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// CC
import { DropDownInputConverter as DropDownInput } from "CCW-Components/CCW-FIGDropDownInputNew";

// Helpers
import { updateUser } from "Helpers/TrackingHelpers/Events";

// Styles
import { Container, Button, MarginTop } from "./styles";
import { Header } from "../../styles";

// Types
import type FeedbackStepProps from "./types";

export const FeedbackStep: React.FC<FeedbackStepProps> = ({ to }) => {
	// Hooks
	const [selectedOption, setSelectedOption] = useState<string>("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const navigate = useNavigate();

	// Helpers
	const handleSubmit = async () => {
		setIsSubmitting(true);
		try {
			if (selectedOption) {
				updateUser({ convertedFrom: selectedOption });
			}
			navigate(to);
		} catch {
			navigate(to);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Container>
			<Header>We&apos;d love to know how you heard about us</Header>
			<div>
				<DropDownInput
					name="feedback"
					onSelect={(_, value) => setSelectedOption(value)}
					value={selectedOption}
					options={[
						{ label: "ClimateClever's social media", value: "CC-Social" },
						{ label: "Other social media", value: "Other-Social" },
						{ label: "Found the ClimateClever website", value: "CC-Website" },
						{ label: "Outreach from the ClimateClever team", value: "CC-Team" },
						{ label: "Word of mouth", value: "Other-Word-Of-Mouth" },
						{ label: "From a blog", value: "Other-Blog" },
						{ label: "Other", value: "Other-Unknown" },
					]}
				/>

				<MarginTop>
					<Button
						type="submit"
						loading={isSubmitting}
						disabled={isSubmitting}
						onClick={handleSubmit}>
						Continue
					</Button>
				</MarginTop>
			</div>
		</Container>
	);
};

export default FeedbackStep;
