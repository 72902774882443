import styled from "@emotion/styled";

// CC
import ButtonLoader from "CCW-Components/CCW-ButtonLoader";

export const Submit = styled(ButtonLoader)(() => ({
	marginTop: "3rem",
}));

export const FormContainer = styled.div(() => ({
	padding: "2rem",
}));

export const CheckItems = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	gap: "2rem",
}));
