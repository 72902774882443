import styled from "@emotion/styled";

export const Container = styled.div(() => ({
	display: "flex",
	alignItems: "center",
}));

export const Link = styled.a(({ theme }) => ({
	...theme.webFonts.F6SemiBold,
	color: theme.colors.secondary,
	marginLeft: "0.5rem",
	"&: hover, focus": {
		opacity: 0.5,
	},
	"&: visited": { color: theme.colors.DarkBlue },
}));

export const Text = styled.p(({ theme }) => ({
	...theme.webFonts.F6,
	margin: "0",
}));
