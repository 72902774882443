import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Container = styled.div(() => ({
	width: "100%",
	height: "100vh",
	display: "flex",
}));

export const LoginContainer = styled.div(() => ({
	width: "100%",
	height: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	[MediaQueries[1]]: {
		justifyContent: "center",
	},
}));

export const Card = styled.div(({ theme }) => ({
	marginTop: "4rem",
	boxShadow: theme.shadow,
	borderRadius: "1.2rem",
	minWidth: "100%",
	[MediaQueries[1]]: {
		minWidth: "44.5rem",
	},
	"#card-form": {
		margin: "3rem 2rem 3rem 2rem",
		[MediaQueries[1]]: {
			margin: "3rem 4rem 4rem 4rem",
		},
	},
}));

export const TitleContainer = styled.div(() => ({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
}));

export const PlatformInfo = styled.div(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	gap: "1.6rem",
	"& > svg > path": {
		fill: theme.colors.primary,
	},
}));

export const PlatformTitle = styled.h3(({ theme }) => ({
	...theme.webFonts.h3,
	color: theme.colors.primary,
	margin: "0",
	padding: "0",
}));

export const Title = styled.h1(({ theme }) => ({
	...theme.webFonts.h1,
	color: theme.colors.D1,
	textAlign: "center",
	margin: "1.6rem 0 0 0",
}));

export const Welcome = styled.p(({ theme }) => ({
	...theme.webFonts.F3,
	color: theme.colors.D3,
	textAlign: "center",
	maxWidth: "44.5rem",
	margin: "1.6rem 0 0 0",
	"& > .start": {
		color: theme.colors.primary,
	},
	"& > .end": {
		color: theme.colors.Green,
	},
}));

export const Header = styled.div(({ theme }) => ({
	width: "100%",
	height: "10.4rem",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	background: theme.colors.Black05,
	borderRadius: "1.2rem 1.2rem 0 0",
}));

export const Heading = styled.div(({ theme }) => ({
	...theme.webFonts.F5SemiBold,
	color: theme.colors.D1,
	marginLeft: "2rem",
}));
