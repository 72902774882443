import React from "react";

// Types
import type IconProps from "Types/IconProps";

export const PlayIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "play-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "32px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0 30.382V1.56073C0 0.831486 0.755396 0.347482 1.4179 0.652236L31.44 14.4624C32.2023 14.8131 32.2198 15.8901 31.4693 16.2653L1.44721 31.2764C0.782313 31.6088 0 31.1253 0 30.382Z"
				fill={fill || "#828282"}
			/>
		</svg>
	);
};

export default PlayIcon;
