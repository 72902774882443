import * as Yup from "yup";

export type FormValues = {
	password: string;
	confirmPassword: string;
	tc: boolean;
	token?: string;
};

/**
 * Initial values for the Formik form
 */
export const initValues: FormValues = {
	password: "",
	confirmPassword: "",
	tc: false,
	token: undefined,
};

/**
 * Array of validation objects for the Formik form
 */
export const validation = [
	Yup.object({
		token: Yup.string().required(),
	}),
	Yup.object({
		password: Yup.string().min(8, "Password must be at least 8 characters"),
		confirmPassword: Yup.string().when("password", {
			is: (value: string) => !!value,
			then: (schema) =>
				schema
					.oneOf([Yup.ref("password")], "Passwords do not match")
					.required("Please confirm your new password"),
		}),
		tc: Yup.bool().oneOf([true], "You must accept the terms and data policy"),
	}),
];
