import React, { useState } from "react";
import { Navigate } from "react-router-dom";

// CC
import CardFormTemplate from "CCW-Components/CCW-CardFormTemplate";

// Local CC
import CheckToken from "../CheckToken";
import VerifyEmailCard from "../VerifyEmailCard";

const DefaultVerifyEmailPage = () => {
	// State
	const [redirect, setRedirect] = useState(false);
	const [verified, setVerified] = useState<undefined | boolean>();

	// Actions
	const handleResult = (result: string) => {
		switch (result) {
			case "redirect":
				return setRedirect(true);
			case "verified":
				return setVerified(true);
			case "unverified":
				return setVerified(false);
			default:
				return;
		}
	};

	if (redirect) {
		return <Navigate to="/" />;
	}

	return (
		<CardFormTemplate>
			{verified == null ? (
				<CheckToken handleResult={handleResult} />
			) : (
				<VerifyEmailCard verified={verified} />
			)}
		</CardFormTemplate>
	);
};

export default DefaultVerifyEmailPage;
