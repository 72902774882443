// Helpers
import postReq from "../PostReq";
import { track } from "../../TrackingHelpers/Events";

// Types
import SignUpDetails from "./Types";
import {
	Create,
	DataHelperResponse,
	JSONApi,
	ResourceObject,
} from "Types/JSONApi";
import User from "Types/User";
import Account from "Types/Account";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

type Related = {
	accounts: Account[];
};

type Relationships = {
	accounts: {
		links: { related: string };
		data: { type: "accounts"; id: string }[];
	};
};

type Includes = ResourceObject<
	Account,
	{
		subscription: {
			links: { related: string };
			// TODO REMOVE THIS ONCE INCLUDES IS OK NOT HAVING A DATA KEY
			// https://github.com/climateclever/cc_web_apps/issues/5945
			data: { type: "subscriptions"; id: string };
		};
	},
	"accounts"
>[];

type SignUpResponse = JSONApi<
	User,
	Relationships,
	Includes,
	undefined,
	Related
>;

/**
 * @async
 * Sign up a user, auto assigns cookies and return auth token
 * @param platform The platform that is being logged into
 * @param data Data required for the sign up request, should contain user, entity and payment keys
 * @throws Networking error
 */
const signUp = async (
	data: SignUpDetails,
): Promise<DataHelperResponse<User, Related, SignUpResponse>> => {
	const requestBody: Create<Partial<SignUpDetails>> = {
		data: {
			type: "users",
			attributes: data,
			relationships: undefined,
			meta: undefined,
		},
	};
	const response = await postReq<
		JSONApi<User, Relationships, Includes, undefined, Related>
	>(`${import.meta.env.VITE_BASE_URL}executive/auth/sign-up`, requestBody, 1);
	track("SignUp", { accountType: data.accountType });
	const [primaryData, related, fullResponse] = transformAPIResponse(
		response,
		{},
	);
	return [primaryData, related, fullResponse];
};

export default signUp;
