import React, { useEffect, useMemo } from "react";
import { Formik, Form } from "formik";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

// Hooks
import useAlert from "Hooks/UseAlert";

// Helpers
import isError from "Helpers/TypeHelpers/IsError";
import { track } from "Helpers/TrackingHelpers/Events";
import updateFinishPartnerSignUp from "Helpers/NetworkingHelpers/UpdateFinishPartnerSignUp";

// Local Components
import PartnerTokenValidationStep from "../PartnerTokenValidationStep";
import PasswordStep from "../PasswordStep";
import SubmittingStep from "../SubmittingStep";

// Form Login
import { initValues, validation, FormValues } from "./FormLogic";

// Types
import type { PartnerSignUpWizardProps } from "./types";
import { PartnerSignUp } from "Types/SignUp";

const PartnerSignUpWizard: React.FC<PartnerSignUpWizardProps> = ({
	onProgressChange,
	onSuccess,
	onPartnerTokenSuccess,
}) => {
	// Hooks
	const [, reportAlert] = useAlert();
	const location = useLocation();
	const navigate = useNavigate();

	// Helpers
	const getCurrentQuestionIndex = (path: string) => {
		return Number(path.split("/").slice(-1)[0]) - 1;
	};

	// Helpers
	const serializeValues = (formValues: Partial<FormValues>): PartnerSignUp =>
		({
			user: {
				password: formValues.password,
				confirmPassword: formValues.confirmPassword,
				tc: formValues.tc,
			},
			token: formValues.token,
		} as PartnerSignUp);

	const progress = (question: number) => {
		return (100 / 3) * question;
	};

	const steps: {
		name: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		component: React.FC<any>;
		nextStep?: number;
		additionalProps?: Record<string, unknown>;
	}[] = useMemo(
		() => [
			{
				name: "Token Validation",
				component: PartnerTokenValidationStep,
				nextStep: 2,
				additionalProps: { onPartnerTokenSuccess },
			},
			{
				name: "Password",
				component: PasswordStep,
				nextStep: 3,
			},
			{
				name: "Submitting",
				component: SubmittingStep,
			},
		],
		[onPartnerTokenSuccess],
	);

	// Code to run when step changes
	useEffect(() => {
		const currentQuestionIndex = getCurrentQuestionIndex(location.pathname);
		if (
			typeof currentQuestionIndex !== "number" ||
			isNaN(currentQuestionIndex) ||
			currentQuestionIndex < 0 ||
			currentQuestionIndex > steps.length - 1
		) {
			navigate("/sign-up/1" + (location.search || ""));
			return;
		}

		const currentStepName = steps[currentQuestionIndex].name;
		onProgressChange && onProgressChange(progress(currentQuestionIndex + 1));
		track(`SignUp: ${currentStepName} + partner`);
	}, [location.pathname, location.search, navigate, onProgressChange, steps]);

	return (
		<Formik
			initialValues={initValues}
			validationSchema={validation[getCurrentQuestionIndex(location.pathname)]}
			onSubmit={async (values, { setSubmitting }) => {
				try {
					if (getCurrentQuestionIndex(location.pathname) !== steps.length - 1)
						return;
					const serializedValues = serializeValues(values);
					await updateFinishPartnerSignUp(serializedValues);
					onSuccess && onSuccess(serializedValues);
					navigate("/welcome");
				} catch (err) {
					navigate("/sign-up/2");
					setSubmitting(false);
					reportAlert(
						isError(err)
							? err.message
							: "Action failed. Please contact support",
						"error",
					);
				}
			}}>
			<Form>
				<Routes>
					{steps.map((step, index) => (
						<Route
							key={step.name}
							path={`/${index + 1}`}
							element={
								<div className="mx-auto max-w-4xl">
									<step.component
										to={`/sign-up/${step.nextStep}`}
										{...step.additionalProps}
									/>
								</div>
							}
						/>
					))}
				</Routes>
			</Form>
		</Formik>
	);
};

export default PartnerSignUpWizard;
