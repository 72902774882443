// Helpers
import postReq from "Helpers/NetworkingHelpers/PostReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";
import User from "Types/User";
import Account from "Types/Account";

// Types
import {
	JSONApi,
	DataHelperResponse,
	ResourceObject,
	Create,
} from "Types/JSONApi";

type Relationships = {
	accounts: {
		links: { related: string };
		data: { type: "accounts"; id: string }[];
	};
};

type Related = {
	accounts: Account[];
};

type Included = ResourceObject<Account, undefined, "accounts">[];

type Response = JSONApi<User, Relationships, Included, undefined, Related>;

/**
 * @async
 * Verify user email address
 * @param verificationToken Token used to verify
 * @returns a DataHelperResponse tuple of User, Related, and the full response
 * @throws Networking error
 */
const verifyEmail = async (
	verificationToken: string,
): Promise<DataHelperResponse<User, Related, Response>> => {
	const requestBody: Create<Record<string, unknown>> = {
		data: {
			type: "users",
			attributes: {
				token: verificationToken,
			},
			relationships: undefined,
			meta: undefined,
		},
	};
	const response = await postReq<
		JSONApi<User, Relationships, Included, undefined, Related>
	>(
		`${import.meta.env.VITE_BASE_URL}executive/auth/verify-email`,
		requestBody,
		2,
	);

	const [verifiedResponse, related, fullResponse] = transformAPIResponse(
		response,
		{},
	);
	return [verifiedResponse, related, fullResponse];
};

export default verifyEmail;
