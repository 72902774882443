import React from "react";
import { useField } from "formik";

// Components
import InputLabel from "../CCW-InputLabel";
import TextInputPhone from "../CCW-TextInputPhone";
import FormGroup from "../CCW-FormGroup";

// Types
import { FIGPhoneProps } from "./types";

const FIGPhone: React.FC<FIGPhoneProps> = ({
	label,
	required,
	name,
	className,
	...props
}) => {
	const [field, meta, helpers] = useField({ name });

	return (
		<FormGroup className={className}>
			<InputLabel htmlFor={props.id} text={label} required={required} />
			<TextInputPhone
				{...field}
				{...props}
				helpers={helpers}
				error={meta.touched && meta.error ? meta.error : undefined}
			/>
		</FormGroup>
	);
};

export default FIGPhone;
