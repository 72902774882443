import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Column = styled.div<{ selected: boolean; isCurrentTier: boolean }>(
	({ theme, selected, isCurrentTier }) => ({
		...theme.webFonts.F6,
		display: "flex",
		position: "relative",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		height: "100%",
		padding: "3.2rem",
		backgroundColor: selected ? theme.colors.Grape400 : theme.colors.White,
		color: selected ? theme.colors.White : theme.colors.Grape400,
		boxShadow: theme.shadow,
		...(isCurrentTier
			? {
					outline: `0.1rem dashed ${theme.colors.Grape400}`,
					borderRadius: "0.6rem",
					margin: "0 1.2rem",
					color: theme.colors.Grape400,
					backgroundColor: theme.colors.White,
					overflow: "visible",
			  }
			: {}),
		borderRadius: "1.2rem",
		textAlign: "center",
		transition: "all 0.3s ease-in-out",
		transform: `scale(${selected ? 1.05 : 0.98})`,
		[MediaQueries[1]]: {
			padding: "3.4rem",
		},
		"& ul": {
			padding: 0,
			margin: "2.4rem 0 3.4rem",
		},
		"& li": {
			listStyle: "none",
			paddingBottom: "1.4rem",
			color:
				!isCurrentTier && selected ? theme.colors.grey50 : theme.colors.grey500,
			[MediaQueries[1]]: {
				paddingBottom: "1.8rem",
			},
		},
		"& h3": {
			marginBottom: "0.4rem",
			"& strong": {
				...theme.webFonts.h2,
			},
		},
		"& span": {
			...theme.webFonts.italic,
			color:
				!isCurrentTier && selected
					? theme.colors.Grape100
					: theme.colors.grey500,
		},
	}),
);

export const IconContainer = styled.div(({ theme }) => ({
	position: "absolute",
	top: 0,
	left: "50%",
	transform: "translate(-50%, -50%)",
	"& svg": {
		boxShadow: theme.shadow,
		borderRadius: "50%",
		background: theme.colors.White,
	},
}));

export const Title = styled.h2(({ theme }) => ({
	...theme.webFonts.h5,
	margin: "0",
	paddingTop: "4.5rem",
}));

export const BannerStyles = styled.div<{ invertColors?: boolean }>(
	({ theme, invertColors = false }) => ({
		...theme.webFonts.F6Bold,
		padding: "1.2rem 3rem",
		backgroundColor: invertColors ? theme.colors.grey50 : theme.colors.Grape400,
		color: invertColors ? theme.colors.Grape400 : theme.colors.grey50,
	}),
);

export const CurrentPlanTag = styled(BannerStyles)(({ theme }) => ({
	opacity: 0.7,
	position: "absolute",
	bottom: "3rem",
	left: 0,
	right: 0,
}));

export const Banner = styled(BannerStyles)({ margin: "0 -3.3rem 1.2rem" }); // Ugly hack to get the banner past the padding and to the edges
