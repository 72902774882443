import {
	AbatementsIcon,
	AustraliaIcon,
	CarbonFootprintIcon,
	CarbonIcon,
	CertificateIcon,
	CommunityIcon,
	OffsetIcon,
	OnboardingIcon,
	PaywallSharehubIcon,
	ReportIcon,
} from "Assets/SVG";
import CSVIcon from "Assets/SVG/CSVIcon";
import HistoricIcon from "Assets/SVG/HistoricIcon";
import PaywallEmissionIcon from "Assets/SVG/PaywallEmissionsIcon";
import PaywallOffsetsIcon from "Assets/SVG/PaywallOffsetsIcon";
import PaywallReportIcon from "Assets/SVG/PaywallReportIcon";
import PaywallSitesIcon from "Assets/SVG/PaywallSitesIcon";
import AnalyticsIcon from "Assets/SVG/AnalyticsIcon";
import exhaustivenessCheck from "Helpers/TypeHelpers/ExhaustivenessCheck";
import IconProps from "Types/IconProps";
import { PaywalledFeature } from "../types";

export const getContent = (
	feature: PaywalledFeature,
): {
	headerImage: React.FC<IconProps>;
	title: string;
	points: {
		icon: React.FC<IconProps & { innerFill?: string; outline?: string }>;
		text: string;
		iconProp?: string;
	}[];
} => {
	switch (feature) {
		case "sharehub":
			return {
				headerImage: PaywallSharehubIcon,
				title: "Upgrade your plan to gain access to Sharehub",
				points: [
					{
						icon: CommunityIcon,
						text: "Easily account for emissions from staff who work from home.",
					},
					{
						icon: AbatementsIcon,
						text: "Understand your full business impact by capturing supply chain emissions.",
						iconProp: "innerFill",
					},
					{
						icon: CarbonIcon,
						text: "Report on individual projects using our innovative emission tagging system.",
					},
				],
			};
		case "report":
			return {
				headerImage: PaywallReportIcon,
				title: "Upgrade your plan to gain access to Carbon Reports",
				points: [
					{
						icon: ReportIcon,
						text: "Generate an in-depth, transparent report into your business carbon footprint at the click of a button.",
					},
					{
						icon: OnboardingIcon,
						text: "Demonstrate your climate action to customers, investors and key stakeholders.",
					},
					{
						icon: CarbonFootprintIcon,
						text: "Create an auditable trail of how your footprint has been calculated.",
					},
				],
			};
		case "historicReport":
			return {
				headerImage: PaywallReportIcon,
				title: "Upgrade your plan to access Historical Carbon Reports!",
				points: [
					{
						icon: HistoricIcon,
						text: "Showcase your reduction efforts over multiple years with the ability to generate reports for previous years.",
					},
				],
			};
		case "thirdPartyOffsets":
			return {
				headerImage: PaywallOffsetsIcon,
				title: "Upgrade your plan to access Third Party Offsets!",
				points: [
					{
						icon: OffsetIcon,
						text: "Want to purchase offsets elsewhere?",
					},
					{
						icon: CertificateIcon,
						text: "You'll have the ability to upload externally purchased offset certificates to the Platform to use for reporting purposes.",
					},
				],
			};
		case "sites":
			return {
				headerImage: PaywallSitesIcon,
				title: "Upgrade your plan to access Additional Sites!",
				points: [
					{
						icon: AustraliaIcon,
						text: "Do you operate across multiple locations?",
						iconProp: "outline",
					},
					{
						icon: CarbonFootprintIcon,
						text: "Account for your whole carbon footprint by adding additional sites",
					},
				],
			};
		case "accommodation":
		case "catering":
		case "electricity":
		case "equipment":
		case "flight":
		case "gas":
		case "lpg":
		case "paper":
		case "stationary-fuels":
		case "transport":
		case "waste":
		case "water":
			return {
				headerImage: PaywallEmissionIcon,
				title: "Upgrade your plan to access more Emission Sources!",
				points: [
					{
						icon: AbatementsIcon,
						text: "Get access to a broader range of emission sources.",
					},
					{
						icon: CSVIcon,
						text: "Supercharge data entry by uploading your bills via CSV files or using our automated data collection system.",
					},
					{
						icon: AnalyticsIcon,
						text: "If you've previously entered data for this emission stream, you'll be able to access it when you upgrade.",
					},
				],
			};
		default:
			return exhaustivenessCheck(feature);
	}
};
