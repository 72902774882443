import styled from "@emotion/styled";

// Styles
export const Container = styled.div({
	display: "flex",
	columnGap: "3rem",
	flexWrap: "wrap",
	"& > div": {
		flexGrow: 1,
	},
});
