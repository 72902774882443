import React from "react";

// Types
import type IconProps from "Types/IconProps";

const CarbonIcon: React.FC<IconProps & { innerFill?: string }> = ({
	width,
	height,
	id,
	className = "abatements-icon",
	fill,
	innerFill = "white",
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width || "16px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.22783 4.51483C3.29644 3.29824 3.82807 2.15405 4.71361 1.31704C5.59916 0.480028 6.77147 0.0136719 7.98999 0.0136719C9.2085 0.0136719 10.3808 0.480028 11.2664 1.31704C12.1519 2.15405 12.6835 3.29824 12.7521 4.51483C13.6118 4.67078 14.3822 5.1426 14.9117 5.83757C15.4412 6.53255 15.6917 7.40043 15.614 8.27069C15.5362 9.14096 15.1358 9.95068 14.4915 10.5408C13.8471 11.1308 13.0053 11.4586 12.1316 11.4597H3.85269C2.97859 11.4597 2.13611 11.1327 1.49093 10.5429C0.84574 9.95321 0.444534 9.14343 0.366203 8.27284C0.287872 7.40226 0.538086 6.53386 1.06764 5.83843C1.59719 5.143 2.36776 4.67085 3.22783 4.51483Z"
				fill={fill != null ? fill : "#63849C"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.02351 8.02805C7.94783 6.93183 8.54675 6.32643 9.4181 6.01508C9.28837 5.11562 8.58783 4.02805 6.76513 2.79346C3.39864 7.11778 6.36945 8.43237 7.4008 8.73724C7.08952 7.3026 6.96022 5.83447 7.01594 4.36751C7.22353 5.61942 7.56121 6.84625 8.02351 8.02805Z"
				fill={innerFill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.6992 6.17085C7.8073 6.04761 8.32838 8.31356 8.57919 9.00978C9.11935 8.41299 9.75506 7.91026 10.4603 7.52221C9.89091 8.04645 9.4207 8.66901 9.07217 9.36004C9.9219 9.60004 11.8073 9.72977 11.6992 6.17085Z"
				fill={innerFill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.54783 13.3038L5.94568 15.2043C5.90315 15.2505 5.85151 15.2874 5.79401 15.3126C5.73651 15.3379 5.67441 15.3509 5.61162 15.3509C5.54884 15.3509 5.48673 15.3379 5.42924 15.3126C5.37174 15.2874 5.32009 15.2505 5.27756 15.2043L3.67541 13.3038C3.59523 13.2135 3.55277 13.0959 3.5568 12.9752C3.56082 12.8545 3.61101 12.7399 3.69702 12.6552C3.74058 12.6131 3.79214 12.5802 3.84864 12.5585C3.90514 12.5368 3.96543 12.5266 4.02593 12.5286C4.08643 12.5307 4.14591 12.5448 4.20084 12.5703C4.25577 12.5957 4.30502 12.6319 4.34567 12.6768L5.16081 13.7146V10.0065C5.16081 9.88551 5.20887 9.76947 5.29443 9.68391C5.37999 9.59835 5.49603 9.55029 5.61703 9.55029C5.73802 9.55029 5.85406 9.59835 5.93962 9.68391C6.02518 9.76947 6.07325 9.88551 6.07325 10.0065V13.706L6.88621 12.6681C6.92687 12.6233 6.97613 12.5871 7.03106 12.5616C7.08598 12.5362 7.14546 12.522 7.20597 12.52C7.26647 12.518 7.32675 12.5281 7.38325 12.5499C7.43975 12.5716 7.49131 12.6045 7.53486 12.6465C7.62221 12.7328 7.67244 12.8498 7.67486 12.9725C7.67728 13.0953 7.63171 13.2141 7.54783 13.3038Z"
				fill={fill != null ? fill : "#63849C"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.3046 13.9522L10.7024 15.8528C10.6599 15.899 10.6083 15.9358 10.5508 15.9611C10.4933 15.9863 10.4312 15.9993 10.3684 15.9993C10.3056 15.9993 10.2435 15.9863 10.186 15.9611C10.1285 15.9358 10.0768 15.899 10.0343 15.8528L8.43217 13.9522C8.35199 13.8619 8.30953 13.7443 8.31356 13.6236C8.31758 13.5029 8.36777 13.3884 8.45378 13.3036C8.49734 13.2615 8.5489 13.2287 8.6054 13.207C8.6619 13.1852 8.72219 13.1751 8.78269 13.1771C8.84319 13.1791 8.90267 13.1932 8.9576 13.2187C9.01253 13.2441 9.06178 13.2804 9.10243 13.3252L9.91757 14.363V10.6549C9.91757 10.5339 9.96563 10.4179 10.0512 10.3323C10.1367 10.2468 10.2528 10.1987 10.3738 10.1987C10.4948 10.1987 10.6108 10.2468 10.6964 10.3323C10.7819 10.4179 10.83 10.5339 10.83 10.6549V14.3544L11.643 13.3166C11.6836 13.2717 11.7329 13.2355 11.7878 13.21C11.8427 13.1846 11.9022 13.1704 11.9627 13.1684C12.0232 13.1664 12.0835 13.1766 12.14 13.1983C12.1965 13.22 12.2481 13.2529 12.2916 13.2949C12.379 13.3812 12.4292 13.4982 12.4316 13.621C12.434 13.7437 12.3885 13.8626 12.3046 13.9522Z"
				fill={fill != null ? fill : "#63849C"}
			/>
		</svg>
	);
};

export default CarbonIcon;
