import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { useLocation } from "react-router-dom";

// CC
import FIGDefault from "CCW-Components/CCW-FIGDefault";

// Assets
import WorldIcon from "Assets/SVG/WorldIcon";

// Styles
import {
	Container,
	Card,
	Button,
	LoginText,
	Link,
	InternationalSection,
} from "./styles";
import { Row } from "CCW-Components/CCW-UserDetailsForm/styles";

// Types
import UserDetailsStepProps from "./types";

export const UserDetailsStep: React.FC<UserDetailsStepProps> = ({ to }) => {
	// Hooks
	const { isSubmitting, dirty, errors, validateForm } = useFormikContext();
	const location = useLocation();

	useEffect(() => {
		validateForm();
	}, [validateForm]);

	// Normal state
	return (
		<>
			<Container>
				<LoginText>
					Already a member?{" "}
					<Link to={{ ...location, pathname: "/login" }}>Log in</Link>
				</LoginText>
				<Card>
					<Row>
						<FIGDefault
							id="fName"
							name="fName"
							type="text"
							label="First Name"
							required={true}
						/>
					</Row>
					<Row>
						<FIGDefault
							id="lName"
							name="lName"
							type="text"
							label="Last Name"
							required={true}
						/>
					</Row>
					<Row>
						<FIGDefault
							id="email"
							name="email"
							type="email"
							label="Email Address"
							required={true}
						/>
					</Row>
					<Button
						disabled={
							isSubmitting ||
							!dirty ||
							["lName", "fName", "email"].some((fieldName) =>
								Object.keys(errors).includes(fieldName),
							)
						}
						variant="primary"
						type="submit"
						to={to}
						block>
						Continue
					</Button>
				</Card>
			</Container>
			<InternationalSection>
				<WorldIcon width="2.8rem" height="2.8rem" />
				<p>
					<span>Are you outside Australia?</span> Register your interest{" "}
					<a
						href="https://forms.monday.com/forms/49b27ddca403ac0da767f2d7e64afc8d?r=use1"
						target="_blank"
						rel="noreferrer">
						here
					</a>{" "}
					and we&apos;ll let you know when ClimateClever is available in your
					country.
				</p>
			</InternationalSection>
		</>
	);
};

export default UserDetailsStep;
