import { useField } from "formik";

// CC
import CCButton from "CCW-Components/CCW-CCButton";

// Hooks
import useSession from "Hooks/UseSession";
import usePlatform from "Hooks/UsePlatform";

// Helpers
import { getDisplayPriceFromPlan } from "Pages/Settings/Helpers";

// Local components
import SimplePricingColumn from "../SimplePricingColumn";

// Local Helpers
import { getFeatures } from "./Helpers";

// Styles
import {
	Container,
	Head,
	Table,
	Spacer,
	TogglePeriod,
	SnapAndAnimate,
} from "./styles";

// Types
import type SimplePricingTableProps from "./types";
import { Tier } from "Types/Tiers";

export const SimplePricingTable: React.FC<SimplePricingTableProps> = ({
	showGetStarted,
	onSelect,
	plans,
	entity,
	accountType,
}) => {
	// Hooks
	const [selectedPlan, , helpers] = useField("plan");
	const [, , , { activeAccount: account }] = useSession();
	const platform = usePlatform();

	const selectPlan = (tier: Tier) => {
		helpers.setValue(tier);
		onSelect && onSelect(tier);
	};

	// Normal state
	return (
		<Container>
			{showGetStarted && (
				<Head>
					<h3>Get started for free!</h3>
					<CCButton
						colorVariant="grape"
						variant="secondary"
						onClick={() => selectPlan("free")}>
						Get started
					</CCButton>
				</Head>
			)}
			<TogglePeriod currentBillingPeriod={account?.billingPeriod} />
			<Table>
				<Spacer />
				{plans
					.filter(
						(plan) =>
							!account?.accountType ||
							account?.accountType === "home" ||
							plan.tier !== "free",
					)
					.map((plan, index) => (
						<SnapAndAnimate key={plan.name} index={index}>
							<SimplePricingColumn
								currentBillingPeriod={account?.billingPeriod}
								key={plan.name}
								currentTier={account?.tier}
								tierName={plan.tier}
								selected={selectedPlan.value === plan.tier}
								displayPrice={getDisplayPriceFromPlan(plan, "monthly").price}
								features={
									getFeatures(accountType || platform)[plan.tier] as string[]
								}
								period={plan.period}
								{...{ selectPlan, entity }}
								accountType={accountType || platform}
							/>
						</SnapAndAnimate>
					))}
				<Spacer />
			</Table>
		</Container>
	);
};

export default SimplePricingTable;
