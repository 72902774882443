import React from "react";

// Local
import DefaultVerifyEmailPage from "./Components/DefaultVerifiyEmailPage";

const VerifyEmailPage = () => {
	return <DefaultVerifyEmailPage />;
};

export default VerifyEmailPage;
