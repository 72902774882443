import React, { PropsWithChildren, ReactElement } from "react";

// Hooks
import useFeatureAccess from "Hooks/UseFeatureAccess";

// Types
import PaywallProps from "CCW-Components/CCW-Paywall/types";
import PaywallPage from "Pages/PaywallPage";

const PaywalledRoute: React.FC<PropsWithChildren<PaywallProps>> = ({
	feature,
	children,
}) => {
	const allowedFeatures = useFeatureAccess();

	return allowedFeatures.includes("report") ? (
		(children as ReactElement)
	) : (
		<PaywallPage feature={feature} />
	);
};

export default PaywalledRoute;
