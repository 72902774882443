import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useField, useFormikContext } from "formik";

// CC
import FIGAddress from "CCW-Components/CCW-FIGAddress";
import ButtonLoader from "CCW-Components/CCW-ButtonLoader";

// Helpers
import getLGAsForAddress from "Helpers/NetworkingHelpers/GetLGAsForAddress";

// Styles
import { Container, MarginTop, ExplanationNote } from "./styles";
import { Header } from "../../styles";

// Types
import type AddressStepProps from "./types";
import { AddressRecommendation } from "Types/Address";
import Referral from "Types/Referrals";

export const AddressStep: React.FC<AddressStepProps> = ({ to }) => {
	// Hooks
	const { isSubmitting, errors, submitForm, validateForm } = useFormikContext();
	const [addressField] = useField<AddressRecommendation>("address");
	const [, , LGAOptionsFieldHelpers] = useField<Referral[] | undefined>(
		"LGAOptions",
	);
	const [, , LGA_fieldHelper] = useField<string>("lga");
	const [loadingPartners, setLoadingPartners] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		validateForm();
	}, [validateForm]);

	// Helpers
	const handleSubmit = async () => {
		setLoadingPartners(true);
		try {
			const [lgas] = await getLGAsForAddress(addressField.value._id);
			LGAOptionsFieldHelpers.setValue(lgas);
			setLoadingPartners(false);
			submitForm();
			if (lgas?.length) {
				navigate(to.withPartner);
			} else {
				throw "fail";
			}
		} catch {
			LGAOptionsFieldHelpers.setValue([]);
			setLoadingPartners(false);
			await submitForm();
			navigate(to.withoutPartner);
		}
	};

	return (
		<Container>
			<Header>Your Address</Header>
			<ExplanationNote>
				We use your address to calculate your emissions and to determine
				eligibility for any partnership discounts.
			</ExplanationNote>
			<FIGAddress name={"address"} />
			<MarginTop>
				<ButtonLoader
					type="submit"
					loading={isSubmitting || loadingPartners}
					disabled={
						isSubmitting ||
						!addressField.value ||
						["address"].some((fieldName) =>
							Object.keys(errors).includes(fieldName),
						)
					}
					onClick={handleSubmit}>
					Continue
				</ButtonLoader>
			</MarginTop>
		</Container>
	);
};

export default AddressStep;
