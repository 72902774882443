import styled from "@emotion/styled";

// CC
import Input from "../CCW-TextInput";

export const PhoneContainer = styled.div(() => ({
	position: "relative",
}));

export const AreaCode = styled.div(() => ({
	position: "absolute",
	top: "0px",
	left: "15px",
	height: "40px",
	display: "flex",
	alignItems: "center",
}));

export const Text = styled.span(({ theme }) => ({
	...theme.webFonts.F6,
	color: theme.colors.Blueberry200,
	userSelect: "none",
}));

export const Separator = styled.span(({ theme }) => ({
	...theme.webFonts.F5,
	color: theme.colors.Blueberry100,
	marginLeft: "10px",
	userSelect: "none",
}));

export const PaddedInput = styled(Input)(() => ({
	textIndent: "5rem",
}));
