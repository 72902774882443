// Helpers
import postReq from "../PostReq";

// Types
import ValidatedDataPartner from "Types/ValidatedDataPartner";

/**
 * @async
 * Check partner's invite token
 * @param inviteToken Token from the invite partner email
 * @throws Networking error
 */
const createValidatePartnerToken = async (token: string) => {
	const response = await postReq<ValidatedDataPartner>(
		`${import.meta.env.VITE_BASE_URL}executive/auth/partner/sign-up/validate`,
		{ token },
	);
	return response;
};

export default createValidatePartnerToken;
