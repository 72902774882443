import { SVGGradient } from "Assets/SVG/SVGGradient";

// Types
import type IconProps from "Types/IconProps";

const SparklesIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "sparkles-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "18px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 18 18"
			fill={fill === "gradient" ? "url(#svgGradient)" : fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			<path d="M17.5935 6.35502C17.0368 6.19998 16.4794 6.04426 15.9247 5.88252C13.9613 5.30911 12.6915 4.04265 12.1188 2.08448C11.9537 1.52042 11.798 0.953688 11.6349 0.389629C11.5781 0.193144 11.5126 0.00133663 11.25 0C10.988 0.00133663 10.9219 0.193144 10.8651 0.389629C10.702 0.954356 10.5463 1.52042 10.3812 2.08448C9.80848 4.04265 8.53872 5.30911 6.57526 5.88252C6.02058 6.04426 5.46322 6.19998 4.90652 6.35502C4.69 6.41517 4.49285 6.50139 4.5002 6.7647C4.50688 7.00797 4.69668 7.08884 4.90051 7.14564C5.46856 7.30337 6.03728 7.45775 6.60199 7.6255C8.53538 8.20025 9.79445 9.45201 10.3732 11.3788C10.5389 11.9308 10.6906 12.4868 10.8497 13.0415C10.9105 13.2541 10.9493 13.496 11.25 13.5C11.5507 13.496 11.5895 13.2541 11.6503 13.0415C11.8094 12.4875 11.9611 11.9308 12.1268 11.3788C12.7056 9.45201 13.9646 8.20025 15.898 7.6255C16.4627 7.45775 17.0314 7.30337 17.5995 7.14564C17.804 7.08884 17.9931 7.00797 17.9998 6.7647C18.0072 6.50205 17.8107 6.41517 17.5935 6.35502Z" />
			<path d="M6.54674 14.4275C6.26839 14.35 5.98971 14.2721 5.71237 14.1913C4.73064 13.9046 4.09576 13.2713 3.80939 12.2922C3.72686 12.0102 3.649 11.7268 3.56747 11.4448C3.53907 11.3466 3.50632 11.2507 3.375 11.25C3.24401 11.2507 3.21093 11.3466 3.18253 11.4448C3.101 11.7272 3.02314 12.0102 2.94061 12.2922C2.65424 13.2713 2.01936 13.9046 1.03763 14.1913C0.760288 14.2721 0.481608 14.35 0.203262 14.4275C0.0949978 14.4576 -0.00357599 14.5007 9.96421e-05 14.6324C0.00344113 14.754 0.0983393 14.7944 0.200255 14.8228C0.484281 14.9017 0.768641 14.9789 1.051 15.0627C2.01769 15.3501 2.64722 15.976 2.9366 16.9394C3.01947 17.2154 3.09532 17.4934 3.17485 17.7708C3.20525 17.877 3.22463 17.998 3.375 18C3.52537 17.998 3.54475 17.877 3.57516 17.7708C3.65468 17.4938 3.73053 17.2154 3.8134 16.9394C4.10278 15.976 4.73231 15.3501 5.699 15.0627C5.98136 14.9789 6.26572 14.9017 6.54975 14.8228C6.65199 14.7944 6.74656 14.754 6.7499 14.6324C6.75358 14.501 6.65534 14.4576 6.54674 14.4275Z" />
			{fill === "gradient" && <SVGGradient />}
		</svg>
	);
};

export default SparklesIcon;
