import React from "react";

// Types
import type IconProps from "Types/IconProps";

const CalendarIcon: React.FC<IconProps & { type?: "year" | "month" }> = ({
	width,
	height,
	id,
	className = "calendar-icon",
	fill,
	type = "year",
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "16px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 16 16"
			fill={fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			{type === "year" ? (
				<>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M3 0C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V3C16 1.34315 14.6569 0 13 0H3ZM3 4C2.44772 4 2 4.44772 2 5V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V5C14 4.44772 13.5523 4 13 4H3Z"
					/>
					<path d="M4 11.5H7.45296V10.4374H6.4616V6.10201H5.50384C5.12578 6.34853 4.73932 6.49304 4.15963 6.60355V7.41963H5.15939V10.4374H4V11.5Z" />
					<path d="M8.21099 11.5H12V10.3949H10.8574C10.6138 10.3949 10.2441 10.4289 9.96687 10.4544C10.8658 9.57032 11.7396 8.69474 11.7396 7.72566C11.7396 6.68856 11.0086 6 9.95007 6C9.19394 6 8.69826 6.29753 8.17738 6.89258L8.84949 7.57264C9.10993 7.29212 9.40398 7.03709 9.77364 7.03709C10.2189 7.03709 10.4962 7.30062 10.4962 7.81066C10.4962 8.55873 9.5216 9.51932 8.21099 10.6924V11.5Z" />
				</>
			) : (
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V3ZM2 5C2 4.44772 2.44772 4 3 4H13C13.5523 4 14 4.44772 14 5V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V5ZM9.75296 11.498H6.3V10.4354H7.45939V7.41763H6.45963V6.60155C7.03932 6.49104 7.42578 6.34653 7.80384 6.10001H8.7616V10.4354H9.75296V11.498Z"
				/>
			)}
		</svg>
	);
};

export default CalendarIcon;
