// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import type {
	JSONApi,
	ResourceObject,
	DataHelperResponse,
} from "Types/JSONApi";
import type Account from "Types/Account";
import type { Entity } from "Types/Entity";
import type { User, UserPermission } from "Types/User";

type Relationships = {
	accounts: {
		links: { related: string };
		data: { type: "accounts"; id: string }[];
	};
	activeAccount: {
		links: { related: string };
		data: { type: "accounts"; id: string };
	};
	activeEntity: {
		links: { related: string };
		data: { type: "entities"; id: string };
	};
};

export type Related = {
	accounts: Account[];
	activeAccount: Account;
	activeEntity: Entity;
};

type Included = (
	| ResourceObject<Entity, undefined, "entities">
	| ResourceObject<Account, undefined, "accounts">
)[];

type Meta = {
	permissions: {
		account: string;
		permission: UserPermission;
	}[];
};

export type Response = JSONApi<User, Relationships, Included, Meta, Related>;

/**
 * Switch site to the supplied entity id
 * @param entityID Id of the entity we want to switch to
 * @returns a DataHelperResponse tuple of User, Error, Mutate, Related, and the full response
 */
const switchSite = async (
	entityID: string,
): Promise<DataHelperResponse<User, Related, Response>> => {
	const response = await getReq<
		JSONApi<User, Relationships, Included, Meta, Related>
	>([
		`${import.meta.env.VITE_BASE_URL}executive/auth/switch-site/${entityID}`,
		2,
	]);

	const [user, related, fullJSONApiResponse] = transformAPIResponse(
		response,
		{},
	);

	return [user, related, fullJSONApiResponse];
};

export default switchSite;
