import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// CC
import CCButton from "CCW-Components/CCW-CCButton";
import ButtonLink from "CCW-Components/CCW-ButtonLink";
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import AppError from "CCW-Components/CCW-AppError";

// Hooks
import useAlert from "Hooks/UseAlert";
import useAccountEntities from "Hooks/UseAccountEntities";
import useSession from "Hooks/UseSession";

// Helpers
import isError from "Helpers/TypeHelpers/IsError";
import switchSite from "Helpers/NetworkingHelpers/SwitchSite";

// Assets
import { PlusIcon } from "Assets/SVG";

// Styles
import {
	Backdrop,
	Container,
	Header,
	Title,
	CloseButton,
	SiteList,
	SiteItem,
	ButtonContainer,
	FinishedMessage,
	Row,
} from "./styles";

// Types
import type { SiteModalProps } from "./types";
import type { BusinessEntity, SchoolEntity } from "Types/Entity";

export const SitesModal: React.FC<SiteModalProps> = ({ id, name }) => {
	// State
	const [selected, setSelected] = useState<string>(id);
	const [selectedSiteName, setSelectedSiteName] = useState<string>();

	// Hooks
	const [, reportAlert] = useAlert();
	const location = useLocation();
	const navigate = useNavigate();
	const containerRef = useRef<HTMLDivElement>(null);
	const [, sessionError, sessionMutate, { activeAccount: account }] =
		useSession();
	const [allEntities, entitiesError, , , fullEntitiesResponse] =
		useAccountEntities(account?._id);
	const entities = allEntities as SchoolEntity[] | BusinessEntity[];

	useEffect(() => {
		setSelected(id);
		setSelectedSiteName(name);
	}, [id, name]);

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (
				containerRef.current &&
				!containerRef.current.contains(event.target as HTMLDivElement)
			) {
				navigate("/");
			}
		};

		// Helpers
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				event.preventDefault();
				navigate("/");
			}
		};
		document.addEventListener("mousedown", handleOutsideClick);
		document.addEventListener("keydown", handleKeyDown);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [navigate, location]);

	// Actions
	const selectSite = (id: string, name: string) => {
		setSelected(id);
		setSelectedSiteName(name);
	};

	if (entitiesError && sessionError && !("name" in entities[0])) {
		return <AppError error={entitiesError || sessionError} />;
	}

	if (!entities || !fullEntitiesResponse?.meta) {
		return <BlockLoader message="Loading..." />;
	}

	const sitesRemaining =
		fullEntitiesResponse.meta?.siteLimit - fullEntitiesResponse.meta?.siteCount;

	const switchToSite = async (id: string) => {
		try {
			await switchSite(id);
			sessionMutate();
			navigate("#switched");
			reportAlert("You have successfully changed to the new site", "success");
		} catch (err) {
			reportAlert(
				isError(err) ? err.message : "Action failed. Please contact support",
				"error",
			);
		}
	};

	return (
		<Backdrop>
			<Container ref={containerRef}>
				<span>
					<CloseButton to="/" />
				</span>
				<Header>
					<Title>
						{account?.accountType === "business"
							? "Business Sites"
							: "School Campuses"}
					</Title>
				</Header>
				{location.hash === "#switched" ? (
					<FinishedMessage>
						<p>{`You have successfully changed to ${selectedSiteName}`}</p>
						<ButtonLink type="button" to="/" block>
							Go Home
						</ButtonLink>
					</FinishedMessage>
				) : (
					<>
						<SiteList>
							{entities.map(({ _id, name }) => (
								<SiteItem
									selected={selected === _id}
									key={name}
									aria-label="sitesmodal-siteitem">
									<button onClick={() => selectSite(_id, name as string)}>
										{name}
									</button>
								</SiteItem>
							))}
						</SiteList>
						<Row>
							<ButtonLink
								to="/settings/entity#add-site"
								icon={PlusIcon}
								disabled={!sitesRemaining}
								variant="linkWithIcon">
								Add {account?.accountType === "school" ? "Campus" : "Site"}
							</ButtonLink>
							<ButtonContainer>
								<CCButton type="submit" onClick={() => switchToSite(selected)}>
									Switch {account?.accountType === "school" ? "campus" : "site"}
								</CCButton>
								<ButtonLink type="button" to="/" variant="washed">
									Cancel
								</ButtonLink>
							</ButtonContainer>
						</Row>
					</>
				)}
			</Container>
		</Backdrop>
	);
};

export default SitesModal;
