import Platform from "Types/Platform";
import { Tier } from "Types/Tiers";

const homesFree = [
	"3 emission sources available",
	"Access to actions",
	"Purchase offsets",
];

const homesBasic = [
	"All emission sources available",
	"Access to actions",
	"Purchase offsets",
	"In app support (live messaging)",
	"Upload external offsets",
];

const free = [
	"1 site included",
	"1 emission source available",
	"Access to actions",
	"Purchase offsets",
	"CSV data uploads",
];

const basic = [
	"2 sites included",
	"5 emission sources available",
	"Access to actions",
	"Purchase offsets",
	"CSV data uploads",
	"In app support (live messaging)",
];

const smallBusiness = [
	"3 sites included",
	"Access to all emission sources",
	"In-App support",
	"Capture de-identified home data",
	"Simple carbon footprint report",
	"Upload data via CSV",
];

const standard = [
	"10 sites included",
	"All emission sources available",
	"Upload external offsets",
	"Capture work from home data",
	"Standard carbon footprint report",
	"30 mins private consultation/support",
];

const businessStandard = [
	"6 sites included",
	"2 bespoke emission sources",
	"Standard carbon footprint report",
	"Organisational boundary identification",
	"Upload external offsets",
	"Assistance with platform setup",
	"Data management and documentation setup",
	"Presentation of report",
];

const pro = [
	"15 sites included",
	"All emission sources available",
	"5 bespoke emission sources",
	"Create historic carbon footprint reports",
	"Detailed carbon footprint report",
	"Help marketing sustainability achievements",
	"Data checking and verification",
	"ClimateClever Certification or assistance with Climate Active certification",
	"Assistance with actions",
	"Target setting",
];

export const getFeatures = (
	platform: Platform,
): { [name in Tier]?: string[] } => ({
	free,
	basic,
	standard,
	pro,
	...(platform === "home" && {
		free: homesFree,
		basic: homesBasic,
	}),
	...(platform === "business" && {
		"small-business": smallBusiness,
		standard: businessStandard,
	}),
});
