import React, { useState } from "react";
import * as Yup from "yup";
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	useElements,
	useStripe,
} from "@stripe/react-stripe-js";
import { useTheme } from "@emotion/react";

// CC
import FormGroup from "../CCW-FormGroup";
import InputLabel from "../CCW-InputLabel";
import TextInput from "../CCW-TextInput";

// Helpers
import createPaymentMethod from "Helpers/NetworkingHelpers/CreatePaymentMethod";
import validateCard from "Helpers/Validation/CardToken";
import isError from "Helpers/TypeHelpers/IsError";

// Hooks
import useAlert from "Hooks/UseAlert";
import useSession from "Hooks/UseSession";

// Styles
import {
	Container,
	FakeInput,
	stripeStyle,
	InnerDiv,
	Grid,
	Error as ErrorUI,
	ButtonLoader,
	EmailSection,
} from "./styles";

// Types
import type CreditCardInputProps from "./types";
import updateAccount from "Helpers/NetworkingHelpers/UpdateAccount";

type InputFields = "card" | "expiry" | "cvc";

export const CreditCardInput: React.FC<CreditCardInputProps> = ({
	className,
	isDefault,
	buttonText = "Add credit card to file",
	onSuccess,
}) => {
	// Hooks
	const [user, , , { activeAccount: account }] = useSession();
	const [activeFields, setActiveFields] = useState<InputFields[]>([]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
		{},
	);
	const [emailInput, setEmailInput] = useState<string>();

	const theme = useTheme();
	const [, reportAlert] = useAlert();

	const elements = useElements();
	const stripe = useStripe();

	const onSubmit = async () => {
		try {
			setIsSubmitting(true);
			const validatedEmail: string | undefined = !account?.billingEmail
				? await Yup.string()
						.email("Please enter a valid email address")
						.validate(emailInput)
				: undefined;

			const token = await validateCard(elements, stripe);
			if (token && account?._id && user?._id) {
				if (validatedEmail) {
					updateAccount(account._id, { billingEmail: validatedEmail });
				}
				const [newPaymentMethod] = await createPaymentMethod(account._id, {
					token,
					isDefault: !!isDefault,
				});

				if (newPaymentMethod?.lastFour) {
					onSuccess && onSuccess(newPaymentMethod, validatedEmail);
				} else {
					throw new Error("Credit Card add failed. Please contact support");
				}
			} else if (token && onSuccess) {
				onSuccess(token, validatedEmail);
			} else {
				throw new Error("Credit Card add failed. Please contact support");
			}
			setIsSubmitting(false);
		} catch (error) {
			isError(error) && reportAlert(error.message, "error");
			setIsSubmitting(false);
		}
	};

	return (
		<Container className={className}>
			{!account?.billingEmail && (
				<EmailSection>
					<InputLabel text={"Email Address"} required={true} htmlFor="email" />
					<TextInput
						name="email"
						type="email"
						error={""}
						onChange={({ target: { value } }) => setEmailInput(value)}
					/>
				</EmailSection>
			)}
			<FormGroup>
				<Grid>
					<div>
						<InputLabel text="Card Number" />
						<FakeInput active={activeFields.includes("card")}>
							<InnerDiv>
								<CardNumberElement
									options={stripeStyle({ theme })}
									onFocus={() => setActiveFields(["card"])}
									onBlur={() => {
										setActiveFields(
											activeFields.filter((field) => field !== "card"),
										);
									}}
									onChange={(event) =>
										setErrors({
											...errors,
											card: event?.error?.message,
										})
									}
								/>
								{errors?.card && <ErrorUI>{errors.card}</ErrorUI>}
							</InnerDiv>
						</FakeInput>
					</div>
					<div>
						<InputLabel text="Expiry" />
						<FakeInput active={activeFields.includes("expiry")}>
							<InnerDiv>
								<CardExpiryElement
									options={stripeStyle({ theme })}
									onFocus={() => setActiveFields(["expiry"])}
									onBlur={() => {
										setActiveFields(
											activeFields.filter((field) => field !== "expiry"),
										);
									}}
									onChange={(event) =>
										setErrors({
											...errors,
											expiry: event?.error?.message,
										})
									}
								/>
								{errors?.expiry && <ErrorUI>{errors.expiry}</ErrorUI>}
							</InnerDiv>
						</FakeInput>
					</div>
					<div>
						<InputLabel text="CVC" />
						<FakeInput active={activeFields.includes("cvc")}>
							<InnerDiv>
								<CardCvcElement
									options={stripeStyle({ theme })}
									onFocus={() => setActiveFields(["cvc"])}
									onBlur={() => {
										setActiveFields(
											activeFields.filter((field) => field !== "cvc"),
										);
									}}
									onChange={(event) =>
										setErrors({ ...errors, cvc: event?.error?.message })
									}
								/>
								{errors?.card && <ErrorUI>{errors.csv}</ErrorUI>}
							</InnerDiv>
						</FakeInput>
					</div>
				</Grid>
				<div className="mt-10 flex justify-end">
					<ButtonLoader onClick={() => onSubmit()} loading={isSubmitting}>
						{buttonText}
					</ButtonLoader>
				</div>
			</FormGroup>
		</Container>
	);
};

export default CreditCardInput;
