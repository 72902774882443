import React from "react";

// Types
import type IconProps from "Types/IconProps";

const ActionIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "community-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "64px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<circle cx="52" cy="6" r="6" fill={fill != null ? fill : "#A5897D"} />
			<path
				d="M40 23.4262C40 18.9263 42.696 14.6955 47 14C47 14 49.3173 15.6021 52 15.6021C54.6827 15.6021 57 14 57 14C61.3033 14.6955 63.9996 18.9263 64 23.4262C64 25.898 59.0098 28 52 28C44.9902 28 40.0004 25.898 40 23.4262Z"
				fill={fill != null ? fill : "#AC50DA"}
			/>
			<circle cx="52" cy="42" r="6" fill={fill != null ? fill : "#E7D0C6"} />
			<path
				d="M40 59.4262C40 54.9263 42.696 50.6955 47 50C47 50 49.3173 51.6021 52 51.6021C54.6827 51.6021 57 50 57 50C61.3033 50.6955 63.9996 54.9263 64 59.4262C64 61.898 59.0098 64 52 64C44.9902 64 40.0004 61.898 40 59.4262Z"
				fill={fill != null ? fill : "#BB3680"}
			/>
			<circle cx="12" cy="42" r="6" fill={fill != null ? fill : "#A5897D"} />
			<path
				d="M0 59.4262C0 54.9263 2.696 50.6955 7 50C7 50 9.31726 51.6021 12 51.6021C14.6827 51.6021 17 50 17 50C21.3033 50.6955 23.9996 54.9263 24 59.4262C24 61.898 19.0098 64 12 64C4.99022 64 0.000355556 61.898 0 59.4262Z"
				fill={fill != null ? fill : "#5E9842"}
			/>
			<circle cx="12" cy="6" r="6" fill={fill != null ? fill : "#E7D0C6"} />
			<path
				d="M0 23.4262C0 18.9263 2.696 14.6955 7 14C7 14 9.31726 15.6021 12 15.6021C14.6827 15.6021 17 14 17 14C21.3033 14.6955 23.9996 18.9263 24 23.4262C24 25.898 19.0098 28 12 28C4.99022 28 0.000355556 25.898 0 23.4262Z"
				fill={fill != null ? fill : "#007CBE"}
			/>
		</svg>
	);
};

export default ActionIcon;
