/**
 *
 * @param x
 *
 * This function is used as an exhaustiveness checker.
 *
 * Say for example you have an enum type that you are using with a switch statement.
 * Calling this function at the end will cause a compiler error if you have not made a case for each enum in your type.
 */

const exhaustivenessCheck = (x: never): never => {
	throw new Error(`Unhandled discriminated union member: ${JSON.stringify(x)}`);
};

export default exhaustivenessCheck;
