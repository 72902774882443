import React from "react";

// Assets
import CrossIcon from "Assets/SVG/CrossIcon";

// Styles
import { CloseButton, CloseContainer } from "./styles";

// Types
import { CloseButtonLinkProps } from "./types";

/**
 * @deprecated Use CloseButtonLinkNew instead, which allows the parent to position the button
 */
const CloseButtonLink: React.FC<CloseButtonLinkProps> = ({
	to,
	className,
	leftPosition,
}) => {
	return (
		<CloseContainer className={className} leftPosition={leftPosition}>
			<CloseButton to={to} type="button">
				<CrossIcon width="1.6rem" height="1.6rem" />
			</CloseButton>
		</CloseContainer>
	);
};

export default CloseButtonLink;
