import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Types
import CreditCardStepProps from "./types";
import StripeWrapper from "CCW-Components/CCW-StripeWrapper";
import CreditCardInput from "CCW-Components/CCW-CreditCardInput";
import { useField } from "formik";
import { Header } from "Pages/SignUp/styles";

export const CreditCardStep: React.FC<CreditCardStepProps> = ({ to }) => {
	// Hooks
	const [, , { setValue: setTokenValue }] = useField("stripeToken");
	const [, , { setValue: setBillingEmailValue }] = useField("billingEmail");
	const [{ value: selectedPlan }] = useField("plan");
	const navigate = useNavigate();

	useEffect(() => {
		if (selectedPlan === "free") {
			navigate(to);
		}
	}, [navigate, selectedPlan, to]);

	// Normal state
	return (
		<>
			<Header>Payment Details</Header>
			<StripeWrapper>
				<CreditCardInput
					buttonText="Continue"
					onSuccess={async (stripeToken, email) => {
						// Unnecessary TS narrowing here, just to let TS know it's not a PaymentMethod
						if (typeof stripeToken === "string") {
							setTokenValue(stripeToken);
							setBillingEmailValue(email);
							navigate(to);
						}
					}}
				/>
			</StripeWrapper>
		</>
	);
};

export default CreditCardStep;
