import React from "react";
import useLoadImage from "Hooks/UseLoadImage";

// Styles
import { Button, PartnerImage, PartnerName } from "./styles";

// Types
import type PartnerButtonProps from "./types";

export const PartnerButton: React.FC<PartnerButtonProps> = ({
	partner,
	selected,
	onClick,
	...props
}) => {
	const [partnerBanner] = useLoadImage(partner?._id && partner.banner);
	return (
		<Button
			type="button"
			name={partner._id}
			onClick={onClick}
			selected={selected}
			{...props}>
			{!partnerBanner ? (
				<PartnerName>{partner.name}</PartnerName>
			) : (
				<PartnerImage src={partnerBanner?.src} alt={partner.name} />
			)}
		</Button>
	);
};

export default PartnerButton;
