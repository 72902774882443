import useMeasure from "Hooks/UseMeasure";
import React from "react";

// Styles
import {
	Container,
	Label,
	MainLabel,
	SubLabel,
	Button,
	ToggleState,
	Switch,
	CheckBox,
} from "./styles";

// types
import { ToggleInputProps } from "./types";

/** A toggle/switch for a yes/no on/off state */
export const ToggleInput: React.FC<ToggleInputProps> = ({
	label,
	subLabel,
	id,
	onChange,
	style,
	className,
	uncheckedContent: uncheckedContent = "Off",
	checkedContent: checkedContent = "On",
	checked = true,
	disabled,
	isColumn = false,
	styleType = "fat",
	colorType = "primary",
	invertColors = false,
}) => {
	// Actions
	const handleToggle = () => {
		onChange && onChange(!checked);
	};

	const [checkedContentRef, { width: checkedWidth }] =
		useMeasure<HTMLSpanElement>();
	const [uncheckedContentRef, { width: unCheckedWidth }] =
		useMeasure<HTMLSpanElement>();

	if (styleType === "fat") {
		return (
			<Container
				style={style}
				className={className}
				hasLabel={!!label}
				isColumn={isColumn}>
				<Label
					htmlFor={id}
					id={id}
					showLabel={!!label}
					onClick={handleToggle}
					hasBoldStyles={true}>
					<MainLabel className="label">{label}</MainLabel>
					{subLabel && <SubLabel>{subLabel}</SubLabel>}
				</Label>
				<Button
					disabled={disabled}
					type="button"
					role="switch"
					aria-checked={checked}
					aria-labelledby={id}
					onClick={handleToggle}
					selectedTextWidth={checked ? checkedWidth : unCheckedWidth}
					invertColors={invertColors}>
					<ToggleState ref={uncheckedContentRef} className="left-button">
						{uncheckedContent}
					</ToggleState>
					<ToggleState ref={checkedContentRef} className="right-button">
						{checkedContent}
					</ToggleState>
				</Button>
			</Container>
		);
	} else {
		return (
			<Container
				style={style}
				className={className}
				hasLabel={!!label}
				isColumn={isColumn}>
				<Label showLabel={!!label} htmlFor={id}>
					<MainLabel className="label">{label}</MainLabel>
					{subLabel && <SubLabel>{subLabel}</SubLabel>}
				</Label>
				<CheckBox
					type="checkbox"
					id={id}
					className="checkbox"
					onChange={handleToggle}
					checked={checked}
					colorType={colorType}
					invertColors={invertColors}
				/>
				<Switch htmlFor={id} className="switch" />
			</Container>
		);
	}
};

export default ToggleInput;
