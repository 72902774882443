import type { StripeElements, Stripe } from "@stripe/stripe-js";

/**
 * @async
 * Validate a card details and tokenize it
 * @param elements The object from the useElements hook
 * @param stripe The object from the useStripe hook
 * @returns The token id
 * @throws
 */
const validateCard = async (
	elements: StripeElements | null,
	stripe: Stripe | null,
) => {
	if (!stripe || !elements)
		throw new Error(
			"Could not load stripe payments, check your internet connection and try again",
		);

	const cardNumber = elements.getElement("cardNumber");
	const cardExpiry = elements.getElement("cardExpiry");
	const cardCvc = elements.getElement("cardCvc");

	if (!(cardNumber && cardExpiry && cardCvc))
		throw new Error(
			"Could not validate card information, try again or contact support",
		);

	/**
	 * Note that according to the docs, you only need to supply the cardNumber to createToken and
	 * Stripe will grab the other elements associated with it automatically
	 * https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement#stripe_create_token-tokenType
	 */
	const { token, error } = await stripe.createToken(cardNumber);
	if (error) throw error;
	if (token == null)
		throw new Error(
			"Could not validate card, please check details and try again",
		);
	return token.id;
};

export default validateCard;
