import { useEffect } from "react";

// CC or Bit
import ButtonLink from "CCW-Components/CCW-ButtonLink";
import BlockLoader from "CCW-Components/CCW-BlockLoader";

// Hooks
import useSession from "Hooks/UseSession";
import usePlatform from "Hooks/UsePlatform";

// Helpers
import { setUser } from "Helpers/TrackingHelpers/Events";

// Styles
import {
	Container,
	LeftBgShape,
	RightBgShape,
	EmailIcon,
	ContentContainer,
	Title,
	Info,
	InfoBold,
} from "./styles";

const DefaultWelcomePage = () => {
	// Hooks
	const [activeUser] = useSession();
	const platform = usePlatform();

	useEffect(() => {
		activeUser && setUser(activeUser._id, { platform });
	}, [activeUser, platform]);

	if (!activeUser) {
		return <BlockLoader message="Checking details..." />;
	}

	return (
		<Container>
			<LeftBgShape width="900" height="900" side="left" />
			<RightBgShape width="900" height="900" side="right" />
			<ContentContainer>
				<EmailIcon height="120px" width="160px" />
				<Title>You&apos;re all signed up!</Title>
				<Info>
					{`Thanks, ${activeUser.fName}. You're almost there, we just need you to `}
					<InfoBold> confirm your email </InfoBold>
					{"address and you're ready to go!"}
				</Info>
				<ButtonLink type="button" to="/">
					{"Take me to the Dashboard"}
				</ButtonLink>
			</ContentContainer>
		</Container>
	);
};

export default DefaultWelcomePage;
