import React, { useEffect } from "react";

// CC
import BlockLoader from "CCW-Components/CCW-BlockLoader";

// Types
import type PasswordStepProps from "./types";
import { useFormikContext } from "formik";

export const PasswordStep: React.FC<PasswordStepProps> = () => {
	// Hooks
	const { submitForm } = useFormikContext();

	useEffect(() => {
		submitForm();
	}, [submitForm]);

	// Normal state
	return <BlockLoader message="Submitting your sign up application..." />;
};

export default PasswordStep;
