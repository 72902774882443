import * as Yup from "yup";

// Types
import type { AcceptInviteFormValues } from "../types";

export const InitialValues: AcceptInviteFormValues = {
	password: "",
	confirmPassword: "",
	tc: false,
	newsletter: false,
	marketingEmails: false,
};

export const ValidationSchema = Yup.object({
	password: Yup.string()
		.min(8)
		.required("Password must be at least 8 characters long"),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("password")], "Passwords do not match")
		.required("Required"),
	tc: Yup.bool()
		.oneOf([true], "You must accept the terms and conditions.")
		.required(),
	newsletter: Yup.bool().required(),
	marketingEmails: Yup.bool().required(),
});
