import React, { useEffect } from "react";

// CC
import FIGPassword from "CCW-Components/CCW-FIGPassword";
import FIGPasswordStrength from "CCW-Components/CCW-FIGPasswordStrength";
import CheckBoxFormik from "CCW-Components/CCW-CheckBoxFormik";

// Styles
import { Container, Button, MarginTop } from "./styles";
import { Header } from "../../styles";

// Types
import type PasswordStepProps from "./types";
import { useFormikContext } from "formik";
import { FormValues } from "../SignUpWizard/FormLogic";
import Terms from "CCW-Components/CCW-Terms";

export const PasswordStep: React.FC<PasswordStepProps> = ({ to }) => {
	// Hooks
	const { isSubmitting, errors, dirty, validateForm, values } =
		useFormikContext<FormValues>();

	useEffect(() => {
		validateForm();
	}, [validateForm]);

	// Normal state
	return (
		<Container>
			<Header>Set your password</Header>
			<div>
				<FIGPasswordStrength
					id="password"
					name="password"
					label="New Password"
					type="password"
					required={true}
				/>
				<FIGPassword
					id="confirmPassword"
					name="confirmPassword"
					label="Confirm New Password"
					type="password"
					required={true}
				/>
				<div className="flex flex-col gap-4">
					<CheckBoxFormik
						id="tc"
						name="tc"
						label={
							<span>
								I agree to the{" "}
								<a
									href="https://climatecleverweb.cdn.prismic.io/climatecleverweb/31347415-15ea-46a9-8669-486914ade15b_ClimateClever_Terms_%26_Conditions_2023.pdf"
									target="_blank"
									rel="noopener noreferrer">
									terms and data policy
								</a>
							</span>
						}
					/>
					<Terms
						id="newsletter"
						name="newsletter"
						customTerms="receive the monthly newsletter"
					/>
					<Terms
						id="marketingEmails"
						name="marketingEmails"
						customTerms="receive our marketing emails"
					/>
				</div>
				<MarginTop>
					<Button
						type="submit"
						disabled={
							isSubmitting ||
							!dirty ||
							["password", "confirmPassword", "tc"].some((fieldName) =>
								Object.keys(errors).includes(fieldName),
							)
						}
						to={
							values.accountType === "home"
								? to.withHomeAccountType
								: to.withoutHomeAccountType
						}>
						Continue
					</Button>
				</MarginTop>
			</div>
		</Container>
	);
};

export default PasswordStep;
