// CC
import CCButton from "CCW-Components/CCW-CCButton";

// Hooks

// Helpers
import { track } from "Helpers/TrackingHelpers/Events";

// Styles
import { Column, IconContainer, Title, CurrentPlanTag, Banner } from "./styles";

// Assets
import TiersIcon from "Assets/SVG/TiersIcon";

// Types
import type SimplePricingColumnProps from "./types";

export const SimplePricingColumn: React.FC<SimplePricingColumnProps> = ({
	tierName,
	displayPrice,
	features,
	selectPlan,
	selected,
	entity,
	currentTier,
	period,
	currentBillingPeriod,
	accountType,
}) => {
	// Normal state
	const isCurrentTier = currentTier === tierName;
	const tierDisplayName =
		accountType === "home" && tierName === "basic"
			? "plus"
			: tierName === "small-business"
			? "small business"
			: tierName;
	const isPopular =
		(accountType === "business" && tierName === "pro") ||
		(accountType !== "business" && tierName === "standard");

	return (
		<Column isCurrentTier={isCurrentTier} {...{ selected }}>
			<IconContainer>
				<TiersIcon width="12rem" height="12rem" tier={tierName} />
			</IconContainer>
			<div>
				<Title>
					{isPopular && <Banner invertColors={selected}>MOST POPULAR</Banner>}
					{tierDisplayName.toUpperCase()}
				</Title>
				<h3>
					<strong>{displayPrice}</strong> /month{" "}
				</h3>
				{tierName !== "free" && <span>+ GST (Billed {period})</span>}
				<ul>
					{tierName !== "basic" &&
						tierName !== "small-business" &&
						tierName !== "free" && (
							<li>
								All features in{" "}
								{tierName === "standard"
									? accountType === "business"
										? "SMALL BUSINESS"
										: "BASIC"
									: tierName === "pro"
									? "STANDARD"
									: "PRO"}{" "}
								plan
							</li>
						)}
					{features?.map((feature, index) => (
						<li key={`${tierName} - feature ${index + 1}`}>{feature}</li>
					))}
				</ul>
			</div>
			{isCurrentTier ? (
				<CurrentPlanTag>
					CURRENT PLAN{" "}
					{tierName !== "free" && (
						<i>{currentBillingPeriod && `(${currentBillingPeriod})`}</i>
					)}
				</CurrentPlanTag>
			) : (
				<CCButton
					colorVariant="grape"
					variant={selected ? "secondary" : "primary"}
					onClick={() => {
						selectPlan(tierName);
						track("Subscription: Selected Basic Plan", {
							platform: accountType,
							entity,
						});
					}}>
					{selected ? "Selected" : "Select Plan"}
				</CCButton>
			)}
		</Column>
	);
};

export default SimplePricingColumn;
