import styled from "@emotion/styled";

// Styles
export const Container = styled.div(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "stretch",
	gap: "4rem",
}));

export const MarginTop = styled.div(() => ({
	marginTop: "1.8rem",
	textAlign: "right",
	display: "flex",
	justifyContent: "flex-end",
	marginBottom: "2rem",
}));

export const ExplanationNote = styled.p(({ theme }) => ({
	...theme.webFonts.F5,
	color: theme.colors.D3,
	margin: 0,
}));
