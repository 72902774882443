import styled from "@emotion/styled";
import { minScreenWidth } from "Helpers/WebUIHelpers/Breakpoints";

// Styles
export const Container = styled.div(({ theme }) => ({
	background: theme.colors.Blueberry400,
	display: "flex",
	flexDirection: "column",
	gap: "1.8rem",
	borderRadius: "1.2rem",
	color: theme.colors.grey50,
	alignItems: "center",
	padding: "3.2rem 2.4rem 2.8rem",
	width: "100%",
	maxWidth: "60rem",
	margin: "0 auto",
	"& > svg": {
		height: "19.2rem",
	},
	[minScreenWidth.extraSmall]: {
		padding: "4rem 5.6rem 3.6rem",
		"& > svg": {
			height: "22.6rem",
		},
	},
	animation: "paywallSlide 0.5s ease-in-out",
	"@keyframes paywallSlide": {
		"0%": {
			transform: "translateY(3rem)",
			opacity: 0,
		},
		"100%": {
			transform: "translateY(0)",
			opacity: 1,
		},
	},
}));

export const HeaderImageBox = styled.div({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	overflow: "hidden",
});

export const Title = styled.h3(({ theme }) => ({
	...theme.webFonts.h3,
	margin: 0,
	textAlign: "center",
}));

export const Bullets = styled.ul(({ theme }) => ({
	...theme.webFonts.F6,
	listStyle: "none",
	display: "flex",
	flexDirection: "column",
	gap: "1.4rem",
	padding: "0",
	"& li": {
		display: "grid",
		gridTemplateColumns: "auto 1fr",
		alignItems: "center",
		justifyItems: "start",
		gap: "1.6rem",
	},
}));

export const Flex = styled.div({
	display: "flex",
	gap: "1rem",
	alignItems: "center",
});

export const ReportLink = styled.a(({ theme }) => ({
	...theme.webFonts.F6,
	...theme.webFonts.italic,
	color: theme.colors.grey50,
	alignSelf: "end",
	marginTop: "0.4rem",
	textDecoration: "none",
}));
