// Helpers
import patchReq from "Helpers/NetworkingHelpers/PatchReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import { DataHelperResponse, Update } from "Types/JSONApi";
import { AccountResponse, AccountRelated } from "Hooks/UseAccount";
import Account, {
	UpdateBusinessAccount,
	UpdateSchoolAccount,
} from "Types/Account";

/**
 * @async
 * Patches an account with updated data
 * @param accountID The id of the account you want to patch
 * @returns a DataHelperResponse tuple of the updated Account, Related, and the full response
 * @throws Networking error
 */
const updateAccount = async (
	accountID: string,
	updatedAccountDetails: Partial<UpdateBusinessAccount | UpdateSchoolAccount>,
): Promise<DataHelperResponse<Account, AccountRelated, AccountResponse>> => {
	const requestBody: Update<
		Partial<UpdateBusinessAccount | UpdateSchoolAccount>
	> = {
		data: {
			id: accountID,
			type: "accounts",
			attributes: updatedAccountDetails,
			relationships: undefined,
			meta: undefined,
		},
	};
	const response = await patchReq<AccountResponse>(
		`${import.meta.env.VITE_BASE_URL}executive/accounts/${accountID}`,
		requestBody,
		4,
	);

	return transformAPIResponse(response, {});
};

export default updateAccount;
