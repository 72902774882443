import React from "react";

// CC
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import DefaultPage from "CCW-Components/CCW-DefaultPage";

// Hooks
import useSession from "Hooks/UseSession";

// Local CC
import SitesModal from "../SitesModal";

const DefaultSitesPage: React.FC = () => {
	// Hooks
	const [, , , { activeEntity: entity }] = useSession();

	// Loading State
	if (!entity) {
		return <BlockLoader message="Loading sites..." />;
	}

	return (
		<DefaultPage>
			<SitesModal
				id={entity._id}
				name={"name" in entity ? entity.name : undefined}
			/>
		</DefaultPage>
	);
};

export default DefaultSitesPage;
