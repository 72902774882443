// Styles
import CalendarIcon from "Assets/SVG/CalendarIcon";
import ToggleInput from "CCW-Components/CCW-ToggleInput";
import { useLocation, useNavigate } from "react-router-dom";
import { SubscriptionPeriod } from "Types/Subscription";
import { Flex } from "./styles";

// Types
import type TogglePeriodProps from "./types";

export const TogglePeriod: React.FC<TogglePeriodProps> = ({
	currentBillingPeriod,
	...props
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const selectedSubscriptionPeriod: SubscriptionPeriod =
		(searchParams.get("period") as SubscriptionPeriod) ||
		currentBillingPeriod ||
		"yearly";
	return (
		<ToggleInput
			id={"period"}
			checked={selectedSubscriptionPeriod === "monthly"}
			styleType="fat"
			colorType="secondary"
			uncheckedContent={
				<Flex>
					<CalendarIcon height="1.6rem" width="1.6rem" />
					<span>YEARLY (Save 20%)</span>
				</Flex>
			}
			checkedContent={
				<Flex>
					<CalendarIcon height="1.6rem" width="1.6rem" type="month" />
					<span>MONTHLY</span>
				</Flex>
			}
			onChange={(checked) => {
				searchParams.set("period", checked ? "monthly" : "yearly");
				navigate({
					search: searchParams.toString(),
				});
			}}
			{...props}
		/>
	);
};

export default TogglePeriod;
