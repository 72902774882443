import styled from "@emotion/styled";

// CC
import PLink from "../CCW-PLink";

// Helpers
import mediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Header = styled.div(() => ({
	display: "flex",
	marginBottom: "3.5rem",
	[mediaQueries[1]]: {
		marginBottom: "4rem",
	},
}));

export const Title = styled.h1(({ theme }) => ({
	...theme.webFonts.h1,
	color: theme.colors.D1,
	textAlign: "left",
	margin: "0",
	[mediaQueries[1]]: {
		textAlign: "center",
		width: "100%",
	},
}));

export const Back = styled(PLink)(({ theme }) => ({
	display: "none",
	[mediaQueries[1]]: {
		display: "flex",
		alignItems: "center",
		marginLeft: "-100%",
		"& > svg": {
			marginRight: "1rem",
		},
		"& > svg > path": {
			fill: theme.colors.secondary,
		},
	},
}));

export const DetailsBlankSpace = styled.div(() => ({
	display: "none",
	[mediaQueries[1]]: {
		display: "block",
	},
}));

export const MobileBack = styled(PLink)(({ theme }) => ({
	"& > svg > path": {
		fill: theme.colors.primary,
	},
}));

export const MobileSubmit = styled.button(({ theme }) => ({
	...theme.webFonts.F5Bold,
	padding: "1.2rem 0 1.1rem 10rem",
	background: theme.colors.White,
	color: theme.colors.primary,
	border: "none",
	"&:active": {
		transform: "translateY(2px) scale(0.98)",
	},
}));

export const MobileHeader = styled.div(({ theme }) => ({
	background: theme.colors.White,
	display: "flex",
	margin: "1rem 0 0 0",
	alignItems: "center",
	width: "100%",
	justifyContent: "space-between",
	[mediaQueries[1]]: {
		display: "none",
	},
}));

export const Row = styled.div(() => ({
	display: "flex",
	"& > div": {
		width: "100%",
	},
	gap: "3rem",
}));

export const ButtonRow = styled.div(() => ({
	display: "flex",
	justifyContent: "end",
	gap: "1.5rem",
}));
