import React from "react";

// Local CC
import DefaultSitesPage from "./Components/DefaultSitesPage";

/** Sites page */
const SitesPage: React.FC = () => {
	return <DefaultSitesPage />;
};

export default SitesPage;
