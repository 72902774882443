import React, { useState } from "react";
import { Formik, Form } from "formik";

// CC
import FIGDefault from "CCW-Components/CCW-FIGDefault";
import ButtonLink from "CCW-Components/CCW-ButtonLink";

// Hooks
import useAlert from "Hooks/UseAlert";
import resendVerifyEmail from "Helpers/NetworkingHelpers/ResendVerifyEmail";
import useSession from "Hooks/UseSession";

// Helpers
import isError from "Helpers/TypeHelpers/IsError";

// Assets
import EmailIcon from "Assets/SVG/EmailIcon";

// Styles
import { Card, Button, Link, Message } from "./styles";

// Types
import type VerifiedEmailCardProps from "./types";

// Data
import { initValues, validation } from "./FormLogic";

const VerifiedEmailCard: React.FC<VerifiedEmailCardProps> = ({ verified }) => {
	// State
	const [resend, setResend] = useState(false);

	// Hooks
	const [, reportAlert] = useAlert();
	const [activeUser, , , { activeEntity: entity }] = useSession();

	return (
		<>
			{!resend ? (
				<Card
					title={verified ? "Email verified successfully" : "Issue detected"}
					subtitle={
						verified
							? !entity
								? "Please, click the button below to login to your account"
								: ""
							: "There was an issue verifying your email. Please, click the button below to resend another link or contact support if the issue persists."
					}>
					{verified ? (
						!entity ? (
							<ButtonLink to="/login" type="submit" block>
								Login
							</ButtonLink>
						) : (
							<ButtonLink to="/" type="button" block>
								Go Home
							</ButtonLink>
						)
					) : (
						<Formik
							initialValues={initValues}
							validationSchema={validation}
							onSubmit={async (values, { setSubmitting }) => {
								setSubmitting(true);
								try {
									if (!activeUser?._id) {
										throw new Error("No active user session, please try again");
									}
									await resendVerifyEmail(activeUser._id);
									setResend(true);
								} catch (err) {
									setSubmitting(false);
									reportAlert(
										isError(err)
											? err.message
											: "Action failed. Please contact support",
										"error",
									);
								}
							}}>
							{({ isSubmitting }) => (
								<Form>
									<FIGDefault
										id="login-email"
										name="email"
										type="email"
										label="Email Address"
									/>
									<Button
										variant="primary"
										type="submit"
										disabled={isSubmitting}
										loading={isSubmitting}
										block>
										Resend verification email
									</Button>
								</Form>
							)}
						</Formik>
					)}
				</Card>
			) : (
				<Card icon={EmailIcon} title="Check your inbox">
					<Message>
						We have resent a verification email. Please follow the instructions
						and close this window.
						<p>
							If you don&apos;t receive an email in the next few minutes, please
							<Link onClick={() => setResend(false)}> click here</Link>.
						</p>
					</Message>
				</Card>
			)}
		</>
	);
};

export default VerifiedEmailCard;
