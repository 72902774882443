import React, { useEffect } from "react";
import { useField, useFormikContext } from "formik";

// CC
import CCPartnerPicker from "CCW-Components/CCW-PartnerPicker";

// Styles
import {
	Heading,
	Highlighted,
	PrimaryText,
	RightAlign,
	Button,
} from "./styles";

// Types
import type PartnerPickerProps from "./types";
import type Referral from "Types/Referrals";
import Platform from "Types/Platform";
import { FormValues } from "../SignUpWizard/FormLogic";

export const PartnerPicker: React.FC<PartnerPickerProps> = ({
	to,
	...props
}) => {
	// Formik
	const [LGAOptionsField] = useField<Referral[]>("LGAOptions");
	const [LGAField, x, LGAHelpers] = useField<string>("lga");
	const { isSubmitting, validateForm } = useFormikContext();

	useEffect(() => {
		validateForm();
	}, [validateForm]);

	// Hooks
	const [{ value: accountType }] =
		useField<FormValues["accountType"]>("accountType");

	// Helpers
	const getHeadingForPlatform = (platform: Platform) => {
		switch (platform) {
			case "home":
				return <>Your address falls in one of our council areas!</>;
			case "school":
			case "business":
				return (
					<>
						Your address falls in one of our council areas! You will receive a{" "}
						<Highlighted>discount</Highlighted> if you decide to upgrade later!
					</>
				);
			default:
				return <>Your address falls in one of our council areas!</>;
		}
	};

	// Actions
	const handlePartnerSelect = (partnerID: string | null) => {
		LGAHelpers.setValue(partnerID ?? "");
	};

	// Normal state
	return (
		<div {...props}>
			<Heading>{getHeadingForPlatform(accountType)}</Heading>
			<PrimaryText>Which council area do you live in?</PrimaryText>
			<CCPartnerPicker
				partners={LGAOptionsField.value}
				onSelect={handlePartnerSelect}
				selectedPartnerID={LGAField.value}
			/>
			<RightAlign>
				<Button type="submit" disabled={isSubmitting} to={to}>
					Continue
				</Button>
			</RightAlign>
		</div>
	);
};

export default PartnerPicker;
