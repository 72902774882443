// Helpers
import postReq from "../PostReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import PaymentMethod from "Types/PaymentMethod";
import { Create, DataHelperResponse, JSONApi } from "Types/JSONApi";

type Response = JSONApi<
	PaymentMethod,
	undefined,
	undefined,
	undefined,
	undefined
>;

/**
 * @async
 * Create a new Payment Method (credit card)
 * @param accountID The ID of the account you want to create a payment method for
 * @param paymentMethodDetails The details of the payment method
 * @throws Networking error
 */
const createPaymentMethod = async (
	accountID: string,
	paymentMethodDetails: {
		token: string;
		isDefault: boolean;
	},
): Promise<DataHelperResponse<PaymentMethod, undefined, Response>> => {
	const requestBody: Create<typeof paymentMethodDetails> = {
		data: {
			type: "payment-methods",
			attributes: paymentMethodDetails,
			relationships: undefined,
			meta: undefined,
		},
	};
	const response = await postReq<
		JSONApi<PaymentMethod, undefined, undefined, undefined, undefined>
	>(
		`${
			import.meta.env.VITE_BASE_URL
		}executive/accounts/${accountID}/payment-methods/cards`,
		requestBody,
		1,
	);

	const [newPaymentMethod, , fullResponse] = transformAPIResponse(response, {});
	return [newPaymentMethod, undefined, fullResponse];
};

export default createPaymentMethod;
