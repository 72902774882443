import React from "react";

// Styles
import {
	PhoneContainer,
	AreaCode,
	Text,
	Separator,
	PaddedInput,
} from "./styles";

// Types
import { TextInputPhoneProps } from "./types";

/** Base input field */
const TextInputPhone: React.FC<TextInputPhoneProps> = ({
	error,
	className,
	id,
	name,
	value,
	helpers,
	...props
}) => {
	const areaCode = "+61";
	return (
		<PhoneContainer className={className}>
			<PaddedInput
				id={id || name}
				name={name}
				error={error}
				type={"tel"}
				value={value?.replace(areaCode, "")}
				{...props}
				onChange={({ target: { value } }) =>
					helpers.setValue(value ? areaCode + value.replaceAll(" ", "") : "")
				}
			/>
			<AreaCode>
				<Text>{areaCode}</Text>
				<Separator>|</Separator>
			</AreaCode>
		</PhoneContainer>
	);
};

export default TextInputPhone;
