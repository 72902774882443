// Types
import type IconProps from "Types/IconProps";

const PaywallReportIcon: React.FC<IconProps> = ({
	width,
	height,
	className = "paywall-report-icon",
	fill,
	id,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "520px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 520 242"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M377.394 166.48L388.561 177.464L391.412 174.144L384.768 162.091L377.394 166.48Z"
				fill="#0093D2"
			/>
			<path
				d="M473.669 89.7114L481.318 76.0284L477.378 74.1371L467.516 83.7214L473.669 89.7114Z"
				fill="#0093D2"
			/>
			<path
				d="M90.1683 15.9021L82.7273 29.6995L86.7003 31.5339L96.3971 21.7879L90.1683 15.9021Z"
				fill="#AC50DA"
			/>
			<path
				d="M53.9258 127.322L46.4849 141.119L50.4579 142.954L60.1547 133.208L53.9258 127.322Z"
				fill="#FBB12A"
			/>
			<path
				d="M10.6524 191.594L20.6441 203.662L23.8222 200.649L18.4197 187.991L10.6524 191.594Z"
				fill="#AC50DA"
			/>
			<path
				d="M168.956 186.587L161.515 200.385L165.488 202.219L175.185 192.473L168.956 186.587Z"
				fill="#AC50DA"
			/>
			<path
				d="M440.774 26.2134L447.09 40.565L450.966 38.5325L449.233 24.877L440.774 26.2134Z"
				fill="#AC50DA"
			/>
			<path
				d="M352.889 41.1887L348.242 56.1677L352.496 57.1919L360.139 45.7578L352.889 41.1887Z"
				fill="#FBB12A"
			/>
			<path
				d="M399.374 87.8113L394.727 102.79L398.981 103.814L406.624 92.3804L399.374 87.8113Z"
				fill="#AC50DA"
			/>
			<path
				d="M441.919 169.993L437.273 184.972L441.526 185.996L449.169 174.562L441.919 169.993Z"
				fill="#E97E42"
			/>
			<path
				d="M489.484 197.901L477.241 207.664L480.183 210.91L492.916 205.77L489.484 197.901Z"
				fill="#FBB12A"
			/>
			<path
				d="M48.8485 187.784C48.8507 188.487 49.0606 189.174 49.4517 189.757C49.8427 190.34 50.3974 190.794 51.0456 191.062C51.6939 191.329 52.4066 191.398 53.0938 191.26C53.781 191.121 54.4119 190.782 54.9067 190.284C55.4016 189.786 55.7382 189.152 55.8742 188.463C56.0101 187.773 55.9393 187.059 55.6705 186.41C55.4018 185.76 54.9472 185.206 54.3642 184.815C53.7812 184.425 53.096 184.217 52.395 184.217C51.9283 184.217 51.4662 184.309 51.0352 184.489C50.6042 184.668 50.2128 184.931 49.8833 185.262C49.5538 185.594 49.2928 185.987 49.1153 186.42C48.9377 186.853 48.8471 187.316 48.8485 187.784Z"
				fill="#58B647"
			/>
			<path
				d="M514.485 111.342C514.485 111.776 514.613 112.2 514.854 112.561C515.094 112.922 515.436 113.203 515.836 113.369C516.236 113.535 516.676 113.579 517.1 113.494C517.525 113.409 517.915 113.2 518.221 112.893C518.527 112.586 518.735 112.195 518.82 111.77C518.904 111.344 518.861 110.903 518.695 110.502C518.53 110.101 518.249 109.758 517.889 109.517C517.529 109.276 517.106 109.147 516.673 109.147C516.386 109.146 516.1 109.201 515.834 109.311C515.568 109.421 515.326 109.582 515.123 109.786C514.919 109.991 514.758 110.233 514.648 110.5C514.539 110.767 514.483 111.053 514.485 111.342Z"
				fill="#AC50DA"
			/>
			<path
				d="M486.909 41.0014C486.907 41.4359 487.033 41.8613 487.272 42.2237C487.512 42.586 487.853 42.869 488.252 43.0369C488.652 43.2047 489.092 43.2498 489.518 43.1664C489.943 43.083 490.334 42.8748 490.641 42.5684C490.948 42.2619 491.158 41.8709 491.243 41.4449C491.329 41.0189 491.286 40.5771 491.121 40.1755C490.955 39.7738 490.675 39.4304 490.315 39.1888C489.955 38.9471 489.531 38.8181 489.098 38.8181C488.519 38.8181 487.964 39.0478 487.554 39.4569C487.144 39.866 486.912 40.4213 486.909 41.0014Z"
				fill="#E94242"
			/>
			<path
				d="M5.51517 67.0783C5.51294 67.5128 5.63944 67.9382 5.87856 68.3006C6.11769 68.6629 6.4587 68.9459 6.8584 69.1138C7.2581 69.2816 7.69848 69.3267 8.12375 69.2433C8.54902 69.1599 8.94001 68.9517 9.2472 68.6453C9.5544 68.3388 9.76397 67.9478 9.84933 67.5218C9.93468 67.0958 9.89198 66.654 9.72668 66.2524C9.56137 65.8507 9.2809 65.5073 8.92076 65.2657C8.56062 65.024 8.13703 64.895 7.7037 64.895C7.12521 64.895 6.57024 65.1247 6.16014 65.5338C5.75003 65.9429 5.51813 66.4982 5.51517 67.0783Z"
				fill="#E97E42"
			/>
			<path
				d="M123.697 119.232C123.695 119.667 123.821 120.092 124.06 120.454C124.3 120.817 124.641 121.1 125.04 121.268C125.44 121.435 125.88 121.48 126.306 121.397C126.731 121.314 127.122 121.106 127.429 120.799C127.736 120.493 127.946 120.102 128.031 119.676C128.117 119.25 128.074 118.808 127.909 118.406C127.743 118.005 127.463 117.661 127.103 117.419C126.743 117.178 126.319 117.049 125.886 117.049C125.307 117.049 124.752 117.278 124.342 117.688C123.932 118.097 123.7 118.652 123.697 119.232Z"
				fill="#E94242"
			/>
			<path
				d="M399.455 63.6653C399.446 64.3724 399.648 65.0661 400.034 65.6578C400.42 66.2495 400.973 66.7122 401.623 66.987C402.273 67.2617 402.99 67.3359 403.682 67.2002C404.374 67.0644 405.01 66.7247 405.509 66.2247C406.008 65.7247 406.346 65.0869 406.482 64.3929C406.617 63.6989 406.543 62.9801 406.269 62.3285C405.995 61.6769 405.534 61.122 404.944 60.7346C404.353 60.3473 403.662 60.1452 402.956 60.1541C402.031 60.1629 401.145 60.5357 400.49 61.1923C399.835 61.8488 399.464 62.7368 399.455 63.6653Z"
				fill="#58B647"
			/>
			<path
				d="M484.546 137.155C484.537 137.862 484.738 138.556 485.125 139.147C485.511 139.739 486.064 140.202 486.714 140.476C487.364 140.751 488.081 140.825 488.773 140.69C489.465 140.554 490.101 140.214 490.6 139.714C491.098 139.214 491.437 138.576 491.573 137.882C491.708 137.188 491.634 136.47 491.36 135.818C491.086 135.166 490.624 134.611 490.034 134.224C489.444 133.837 488.753 133.635 488.047 133.644C487.121 133.652 486.236 134.025 485.581 134.682C484.926 135.338 484.554 136.226 484.546 137.155Z"
				fill="#58B647"
			/>
			<path
				d="M329.334 17.8333C329.325 18.5404 329.526 19.2341 329.913 19.8258C330.299 20.4175 330.852 20.8802 331.502 21.1549C332.152 21.4297 332.869 21.5039 333.561 21.3681C334.253 21.2323 334.889 20.8927 335.388 20.3927C335.886 19.8926 336.225 19.2549 336.36 18.5608C336.496 17.8668 336.422 17.1481 336.148 16.4965C335.874 15.8448 335.412 15.2899 334.822 14.9026C334.232 14.5153 333.54 14.3132 332.835 14.3221C331.909 14.3309 331.024 14.7036 330.369 15.3602C329.714 16.0168 329.342 16.9048 329.334 17.8333Z"
				fill="#0093D2"
			/>
			<path
				d="M377.394 213.805C377.385 214.512 377.587 215.206 377.973 215.798C378.359 216.389 378.913 216.852 379.563 217.127C380.213 217.402 380.929 217.476 381.621 217.34C382.314 217.204 382.95 216.865 383.448 216.365C383.947 215.865 384.286 215.227 384.421 214.533C384.556 213.839 384.482 213.12 384.208 212.468C383.934 211.817 383.473 211.262 382.883 210.875C382.293 210.487 381.601 210.285 380.896 210.294C379.97 210.303 379.084 210.676 378.429 211.332C377.775 211.989 377.403 212.877 377.394 213.805Z"
				fill="#E94242"
			/>
			<path
				d="M127.637 70.7771C127.628 71.4843 127.829 72.178 128.216 72.7696C128.602 73.3613 129.155 73.8241 129.805 74.0988C130.455 74.3735 131.172 74.4477 131.864 74.312C132.556 74.1762 133.192 73.8366 133.691 73.3365C134.189 72.8365 134.528 72.1987 134.663 71.5047C134.799 70.8107 134.725 70.0919 134.451 69.4403C134.177 68.7887 133.715 68.2338 133.125 67.8465C132.535 67.4591 131.843 67.257 131.138 67.2659C130.212 67.2747 129.327 67.6475 128.672 68.3041C128.017 68.9607 127.645 69.8486 127.637 70.7771Z"
				fill="#0093D2"
			/>
			<path
				d="M15.7578 109.498C15.749 110.205 15.9506 110.898 16.3368 111.49C16.7231 112.082 17.2765 112.545 17.9263 112.819C18.5762 113.094 19.2929 113.168 19.985 113.032C20.6772 112.897 21.3132 112.557 21.8119 112.057C22.3105 111.557 22.6492 110.919 22.7846 110.225C22.92 109.531 22.846 108.812 22.572 108.161C22.2981 107.509 21.8366 106.954 21.2465 106.567C20.6565 106.18 19.9647 105.977 19.2595 105.986C18.3335 105.995 17.4479 106.368 16.7931 107.025C16.1383 107.681 15.7666 108.569 15.7578 109.498Z"
				fill="#0093D2"
			/>
			<path
				d="M113.284 146.287L123.946 157.766L120.568 160.534L108.727 153.557L113.284 146.287Z"
				fill="#E97E42"
			/>
			<path
				d="M95.1245 209.973L96.3664 225.611L92.0043 225.675L87.0502 212.829L95.1245 209.973Z"
				fill="#0093D2"
			/>
			<path
				d="M422.92 113.098L433.582 124.577L430.204 127.345L418.363 120.368L422.92 113.098Z"
				fill="#E94242"
			/>
			<path
				d="M85.5966 80.0692L90.8971 94.8292L86.7055 96.0418L78.5561 84.9555L85.5966 80.0692Z"
				fill="#58B647"
			/>
			<path
				d="M44.3002 46.8157L45.6971 59.3334L49.1504 58.7168L51.0962 47.8339L44.3002 46.8157Z"
				fill="#E94242"
			/>
			<path
				d="M168.042 20.8528L174.065 31.9057L177.028 30.0225L174.716 19.2118L168.042 20.8528Z"
				fill="#FBB12A"
			/>
			<g filter="url(#filter0_dd_2282_1963)">
				<g clipPath="url(#clip0_2282_1963)">
					<rect
						x="147.333"
						y="8"
						width="225.333"
						height="226"
						rx="112.667"
						fill="#A5ACDA"
					/>
					<g clipPath="url(#clip1_2282_1963)">
						<rect
							width="138.667"
							height="195.182"
							transform="translate(191.455 61.7344)"
							fill="white"
						/>
						<rect
							x="196.975"
							y="61.7344"
							width="193.601"
							height="5.52044"
							transform="rotate(90 196.975 61.7344)"
							fill="#818CCA"
						/>
						<g clipPath="url(#clip2_2282_1963)">
							<path
								d="M298.183 180.608L275.328 178.01C274.696 183.565 276.11 189.161 279.306 193.749C282.502 198.337 287.261 201.605 292.693 202.939L298.183 180.608Z"
								fill="#A5ACDA"
							/>
							<path
								d="M292.693 202.939C296.439 203.86 300.357 203.825 304.085 202.836C307.814 201.848 311.235 199.937 314.032 197.281C316.828 194.625 318.912 191.308 320.09 187.636C321.269 183.963 321.504 180.054 320.775 176.267L298.183 180.608L292.693 202.939Z"
								fill="#818CCA"
							/>
							<path
								d="M298.183 180.608L320.775 176.276C319.741 170.876 316.805 166.024 312.499 162.602C308.194 159.18 302.804 157.414 297.307 157.623C291.81 157.833 286.57 160.005 282.538 163.745C278.506 167.485 275.948 172.546 275.328 178.01L298.183 180.608Z"
								fill="#5D6BBB"
							/>
						</g>
						<rect
							x="205.841"
							y="166.602"
							width="60.9293"
							height="2.54739"
							rx="1.27369"
							fill="#C9CEE9"
						/>
						<rect
							x="205.841"
							y="174.414"
							width="60.9293"
							height="2.54739"
							rx="1.27369"
							fill="#C9CEE9"
						/>
						<rect
							x="205.841"
							y="182.226"
							width="60.9293"
							height="2.54739"
							rx="1.27369"
							fill="#C9CEE9"
						/>
						<rect
							x="218.534"
							y="216.276"
							width="7.61616"
							height="2.54739"
							rx="1.2737"
							fill="#C9CEE9"
						/>
						<rect
							x="284.541"
							y="216.276"
							width="10.1549"
							height="2.54739"
							rx="1.2737"
							fill="#C9CEE9"
						/>
						<rect
							x="218.534"
							y="228.164"
							width="7.61616"
							height="2.54738"
							rx="1.27369"
							fill="#C9CEE9"
						/>
						<rect
							x="296.388"
							y="228.164"
							width="10.1549"
							height="2.54738"
							rx="1.27369"
							fill="#C9CEE9"
						/>
						<g clipPath="url(#clip3_2282_1963)">
							<path
								d="M279.207 113.82L298.183 126.805L279.782 113.02C279.584 113.283 279.392 113.549 279.207 113.82Z"
								fill="#F0F2F9"
							/>
							<path
								d="M275.966 120.838L298.183 126.805L279.207 113.813H279.211C277.74 115.952 276.642 118.327 275.966 120.838Z"
								fill="#C9CEE9"
							/>
							<path
								d="M275.966 120.838C274.413 126.636 275.184 132.812 278.116 138.05L298.183 126.805L275.966 120.838Z"
								fill="#A5ACDA"
							/>
							<path
								d="M278.116 138.05C280.115 141.614 283.028 144.581 286.555 146.646C290.082 148.71 294.096 149.798 298.183 149.797V126.804L278.116 138.05Z"
								fill="#818CCA"
							/>
							<path
								d="M298.183 149.798C301.571 149.796 304.916 149.047 307.98 147.603C311.045 146.16 313.753 144.059 315.911 141.448C318.069 138.838 319.624 135.784 320.465 132.504C321.306 129.224 321.412 125.799 320.776 122.473L298.183 126.805V149.798Z"
								fill="#5D6BBB"
							/>
							<path
								d="M298.183 126.805L320.775 122.473C319.935 118.098 317.841 114.062 314.749 110.855C311.656 107.648 307.699 105.408 303.357 104.406C299.015 103.405 294.475 103.686 290.29 105.216C286.105 106.745 282.454 109.456 279.782 113.02L298.183 126.805Z"
								fill="#4451A2"
							/>
						</g>
						<rect
							x="205.841"
							y="108.436"
							width="60.9293"
							height="2.54739"
							rx="1.27369"
							fill="#C9CEE9"
						/>
						<rect
							x="205.841"
							y="116.248"
							width="60.9293"
							height="2.54739"
							rx="1.27369"
							fill="#C9CEE9"
						/>
						<rect
							x="205.841"
							y="124.06"
							width="60.9293"
							height="2.54739"
							rx="1.27369"
							fill="#C9CEE9"
						/>
						<rect
							x="205.841"
							y="131.872"
							width="60.9293"
							height="2.54739"
							rx="1.27369"
							fill="#C9CEE9"
						/>
						<rect
							x="205.841"
							y="139.684"
							width="60.9293"
							height="2.54739"
							rx="1.27369"
							fill="#C9CEE9"
						/>
						<path
							d="M230.328 224.755H293.813V233.952H230.328V224.755Z"
							fill="#A5ACDA"
						/>
						<path
							d="M230.328 212.799H282.312V221.996H230.328V212.799Z"
							fill="#5D6BBB"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M230.098 247.748L230.098 210.959L230.328 210.959L230.328 247.748L230.098 247.748Z"
							fill="#545454"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M228.258 217.397L230.098 217.397L230.098 217.627L228.258 217.627L228.258 217.397Z"
							fill="#545454"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M228.258 229.354L230.098 229.354L230.098 229.583L228.258 229.583L228.258 229.354Z"
							fill="#545454"
						/>
						<rect
							x="204.148"
							y="82.9626"
							width="59.2368"
							height="4.24565"
							rx="2.12282"
							fill="#A5ACDA"
						/>
						<rect
							x="204.148"
							y="94.0012"
							width="24.541"
							height="3.39652"
							rx="1.69826"
							fill="#818CCA"
						/>
						<rect
							x="204.148"
							y="204.388"
							width="32.1571"
							height="3.39651"
							rx="1.69826"
							fill="#818CCA"
						/>
						<rect
							x="205.841"
							y="100.794"
							width="60.9293"
							height="2.54739"
							rx="1.27369"
							fill="#A5ACDA"
						/>
						<rect
							x="205.841"
							y="159.384"
							width="45.697"
							height="2.54739"
							rx="1.27369"
							fill="#A5ACDA"
						/>
						<path
							d="M206.437 78.0992C205.969 78.0992 205.537 77.9908 205.143 77.774C204.749 77.5572 204.432 77.2376 204.193 76.8154C203.958 76.3874 203.841 75.8625 203.841 75.2405C203.841 74.6243 203.961 74.0993 204.201 73.6657C204.447 73.2263 204.769 72.8925 205.169 72.6643C205.575 72.4303 206.011 72.3133 206.48 72.3133C206.839 72.3133 207.162 72.3875 207.448 72.5359C207.733 72.6785 207.97 72.8468 208.159 73.0409L207.491 73.8454C207.348 73.7142 207.196 73.6086 207.037 73.5287C206.882 73.4431 206.705 73.4003 206.505 73.4003C206.254 73.4003 206.023 73.4717 205.812 73.6143C205.606 73.757 205.44 73.9624 205.315 74.2306C205.195 74.4987 205.135 74.8211 205.135 75.1977C205.135 75.7683 205.258 76.2134 205.503 76.5329C205.749 76.8525 206.077 77.0122 206.488 77.0122C206.717 77.0122 206.919 76.9609 207.096 76.8582C207.279 76.7555 207.439 76.6328 207.576 76.4901L208.244 77.2776C207.776 77.8254 207.174 78.0992 206.437 78.0992Z"
							fill="#4451A2"
						/>
						<path
							d="M210.061 78.0992C209.672 78.0992 209.364 77.9766 209.135 77.7312C208.907 77.4801 208.793 77.1749 208.793 76.8154C208.793 76.3703 208.981 76.0223 209.358 75.7712C209.735 75.5201 210.343 75.3518 211.183 75.2662C211.171 75.0779 211.114 74.9295 211.011 74.8211C210.914 74.707 210.749 74.65 210.515 74.65C210.338 74.65 210.158 74.6842 209.975 74.7527C209.792 74.8211 209.598 74.9153 209.392 75.0351L208.938 74.2049C209.207 74.0394 209.492 73.9053 209.795 73.8026C210.103 73.6999 210.423 73.6485 210.754 73.6485C211.297 73.6485 211.714 73.8055 212.005 74.1193C212.296 74.4331 212.442 74.9153 212.442 75.5658V77.9965H211.414L211.328 77.56H211.294C211.117 77.7198 210.926 77.851 210.72 77.9537C210.52 78.0507 210.3 78.0992 210.061 78.0992ZM210.489 77.1235C210.632 77.1235 210.754 77.0921 210.857 77.0294C210.966 76.9609 211.074 76.8724 211.183 76.764V76.0194C210.737 76.0765 210.429 76.1678 210.258 76.2933C210.086 76.4131 210.001 76.5558 210.001 76.7212C210.001 76.8582 210.044 76.9609 210.129 77.0294C210.221 77.0921 210.34 77.1235 210.489 77.1235Z"
							fill="#4451A2"
						/>
						<path
							d="M213.507 77.9965V73.7513H214.535L214.621 74.4959H214.655C214.809 74.2106 214.995 73.9995 215.212 73.8625C215.429 73.7199 215.646 73.6485 215.863 73.6485C215.983 73.6485 216.083 73.6571 216.163 73.6742C216.243 73.6856 216.311 73.7056 216.368 73.7341L216.163 74.8211C216.088 74.7983 216.014 74.7812 215.94 74.7698C215.871 74.7584 215.792 74.7527 215.7 74.7527C215.54 74.7527 215.372 74.8126 215.195 74.9324C215.023 75.0465 214.881 75.2462 214.766 75.5315V77.9965H213.507Z"
							fill="#4451A2"
						/>
						<path
							d="M219.148 78.0992C218.959 78.0992 218.768 78.0536 218.574 77.9623C218.385 77.8653 218.208 77.7255 218.042 77.5429H218.008L217.905 77.9965H216.92V71.9966H218.18V73.4688L218.145 74.1193C218.311 73.9709 218.491 73.8568 218.685 73.7769C218.879 73.6913 219.073 73.6485 219.267 73.6485C219.61 73.6485 219.907 73.737 220.158 73.9139C220.41 74.0908 220.601 74.3418 220.732 74.6671C220.869 74.9866 220.938 75.3632 220.938 75.7969C220.938 76.2819 220.852 76.6984 220.681 77.0465C220.51 77.3888 220.287 77.6513 220.013 77.8339C219.744 78.0108 219.456 78.0992 219.148 78.0992ZM218.856 77.0722C219.073 77.0722 219.259 76.9723 219.413 76.7726C219.567 76.5729 219.644 76.2562 219.644 75.8225C219.644 75.0579 219.399 74.6756 218.908 74.6756C218.656 74.6756 218.414 74.804 218.18 75.0608V76.7983C218.294 76.901 218.408 76.9723 218.522 77.0122C218.636 77.0522 218.748 77.0722 218.856 77.0722Z"
							fill="#4451A2"
						/>
						<path
							d="M223.655 78.0992C223.296 78.0992 222.956 78.0137 222.636 77.8425C222.322 77.6656 222.068 77.4117 221.874 77.0807C221.679 76.7441 221.582 76.3418 221.582 75.8739C221.582 75.4003 221.679 74.998 221.874 74.6671C222.068 74.3361 222.322 74.0851 222.636 73.9139C222.956 73.737 223.296 73.6485 223.655 73.6485C224.015 73.6485 224.352 73.737 224.666 73.9139C224.98 74.0851 225.234 74.3361 225.429 74.6671C225.623 74.998 225.72 75.4003 225.72 75.8739C225.72 76.3418 225.623 76.7441 225.429 77.0807C225.234 77.4117 224.98 77.6656 224.666 77.8425C224.352 78.0137 224.015 78.0992 223.655 78.0992ZM223.655 77.0807C223.912 77.0807 224.107 76.9723 224.238 76.7555C224.369 76.5329 224.435 76.2391 224.435 75.8739C224.435 75.503 224.369 75.2091 224.238 74.9923C224.107 74.7755 223.912 74.6671 223.655 74.6671C223.393 74.6671 223.196 74.7755 223.064 74.9923C222.939 75.2091 222.876 75.503 222.876 75.8739C222.876 76.2391 222.939 76.5329 223.064 76.7555C223.196 76.9723 223.393 77.0807 223.655 77.0807Z"
							fill="#4451A2"
						/>
						<path
							d="M226.582 77.9965V73.7513H227.61L227.696 74.2905H227.73C227.913 74.1193 228.113 73.9709 228.33 73.8454C228.553 73.7142 228.807 73.6485 229.092 73.6485C229.555 73.6485 229.889 73.8026 230.095 74.1107C230.306 74.4132 230.411 74.8297 230.411 75.3604V77.9965H229.152V75.523C229.152 75.2148 229.109 75.0037 229.024 74.8896C228.944 74.7755 228.812 74.7184 228.63 74.7184C228.47 74.7184 228.333 74.7555 228.219 74.8297C228.104 74.8982 227.979 74.998 227.842 75.1293V77.9965H226.582Z"
							fill="#4451A2"
						/>
						<path
							d="M233.302 77.9965V72.416H236.823V73.4774H234.561V74.7698H236.497V75.8311H234.561V77.9965H233.302Z"
							fill="#4451A2"
						/>
						<path
							d="M239.366 78.0992C239.006 78.0992 238.666 78.0137 238.346 77.8425C238.032 77.6656 237.778 77.4117 237.584 77.0807C237.39 76.7441 237.293 76.3418 237.293 75.8739C237.293 75.4003 237.39 74.998 237.584 74.6671C237.778 74.3361 238.032 74.0851 238.346 73.9139C238.666 73.737 239.006 73.6485 239.366 73.6485C239.725 73.6485 240.062 73.737 240.376 73.9139C240.691 74.0851 240.945 74.3361 241.139 74.6671C241.333 74.998 241.43 75.4003 241.43 75.8739C241.43 76.3418 241.333 76.7441 241.139 77.0807C240.945 77.4117 240.691 77.6656 240.376 77.8425C240.062 78.0137 239.725 78.0992 239.366 78.0992ZM239.366 77.0807C239.623 77.0807 239.817 76.9723 239.948 76.7555C240.08 76.5329 240.145 76.2391 240.145 75.8739C240.145 75.503 240.08 75.2091 239.948 74.9923C239.817 74.7755 239.623 74.6671 239.366 74.6671C239.103 74.6671 238.906 74.7755 238.775 74.9923C238.649 75.2091 238.586 75.503 238.586 75.8739C238.586 76.2391 238.649 76.5329 238.775 76.7555C238.906 76.9723 239.103 77.0807 239.366 77.0807Z"
							fill="#4451A2"
						/>
						<path
							d="M244.117 78.0992C243.757 78.0992 243.418 78.0137 243.098 77.8425C242.784 77.6656 242.53 77.4117 242.335 77.0807C242.141 76.7441 242.044 76.3418 242.044 75.8739C242.044 75.4003 242.141 74.998 242.335 74.6671C242.53 74.3361 242.784 74.0851 243.098 73.9139C243.418 73.737 243.757 73.6485 244.117 73.6485C244.477 73.6485 244.814 73.737 245.128 73.9139C245.442 74.0851 245.696 74.3361 245.89 74.6671C246.085 74.998 246.182 75.4003 246.182 75.8739C246.182 76.3418 246.085 76.7441 245.89 77.0807C245.696 77.4117 245.442 77.6656 245.128 77.8425C244.814 78.0137 244.477 78.0992 244.117 78.0992ZM244.117 77.0807C244.374 77.0807 244.568 76.9723 244.7 76.7555C244.831 76.5329 244.897 76.2391 244.897 75.8739C244.897 75.503 244.831 75.2091 244.7 74.9923C244.568 74.7755 244.374 74.6671 244.117 74.6671C243.855 74.6671 243.658 74.7755 243.526 74.9923C243.401 75.2091 243.338 75.503 243.338 75.8739C243.338 76.2391 243.401 76.5329 243.526 76.7555C243.658 76.9723 243.855 77.0807 244.117 77.0807Z"
							fill="#4451A2"
						/>
						<path
							d="M248.454 78.0992C247.929 78.0992 247.552 77.948 247.324 77.6456C247.101 77.3432 246.99 76.9466 246.99 76.4559V74.7355H246.407V73.8026L247.058 73.7513L247.204 72.6215H248.249V73.7513H249.268V74.7355H248.249V76.4388C248.249 76.6784 248.297 76.8525 248.395 76.9609C248.497 77.0636 248.632 77.115 248.797 77.115C248.866 77.115 248.934 77.1064 249.003 77.0893C249.077 77.0722 249.143 77.0522 249.2 77.0294L249.397 77.9452C249.288 77.9794 249.154 78.0137 248.994 78.0479C248.84 78.0821 248.66 78.0992 248.454 78.0992Z"
							fill="#4451A2"
						/>
						<path
							d="M250.098 79.5714V73.7513H251.126L251.211 74.1706H251.245C251.411 74.0223 251.597 73.8996 251.802 73.8026C252.014 73.6999 252.231 73.6485 252.453 73.6485C252.967 73.6485 253.373 73.8454 253.67 74.2391C253.967 74.6271 254.115 75.1492 254.115 75.8054C254.115 76.2904 254.029 76.7041 253.858 77.0465C253.687 77.3888 253.464 77.6513 253.19 77.8339C252.922 78.0108 252.633 78.0992 252.325 78.0992C252.142 78.0992 251.965 78.0622 251.794 77.988C251.622 77.9081 251.462 77.7968 251.314 77.6542L251.357 78.3303V79.5714H250.098ZM252.034 77.0722C252.251 77.0722 252.436 76.9723 252.59 76.7726C252.745 76.5729 252.822 76.2562 252.822 75.8225C252.822 75.0579 252.576 74.6756 252.085 74.6756C251.839 74.6756 251.597 74.804 251.357 75.0608V76.7983C251.471 76.901 251.585 76.9723 251.699 77.0122C251.814 77.0522 251.925 77.0722 252.034 77.0722Z"
							fill="#4451A2"
						/>
						<path
							d="M255.008 77.9965V73.7513H256.036L256.122 74.4959H256.156C256.31 74.2106 256.496 73.9995 256.713 73.8625C256.93 73.7199 257.147 73.6485 257.364 73.6485C257.484 73.6485 257.584 73.6571 257.664 73.6742C257.744 73.6856 257.812 73.7056 257.869 73.7341L257.664 74.8211C257.589 74.7983 257.515 74.7812 257.441 74.7698C257.372 74.7584 257.292 74.7527 257.201 74.7527C257.041 74.7527 256.873 74.8126 256.696 74.9324C256.524 75.0465 256.382 75.2462 256.267 75.5315V77.9965H255.008Z"
							fill="#4451A2"
						/>
						<path
							d="M258.421 77.9965V73.7513H259.68V77.9965H258.421ZM259.047 73.1264C258.835 73.1264 258.664 73.0665 258.533 72.9467C258.401 72.8269 258.336 72.6671 258.336 72.4674C258.336 72.2677 258.401 72.1079 258.533 71.9881C258.664 71.8683 258.835 71.8083 259.047 71.8083C259.258 71.8083 259.429 71.8683 259.56 71.9881C259.692 72.1079 259.758 72.2677 259.758 72.4674C259.758 72.6671 259.692 72.8269 259.56 72.9467C259.429 73.0665 259.258 73.1264 259.047 73.1264Z"
							fill="#4451A2"
						/>
						<path
							d="M260.789 77.9965V73.7513H261.817L261.902 74.2905H261.936C262.119 74.1193 262.319 73.9709 262.536 73.8454C262.759 73.7142 263.013 73.6485 263.298 73.6485C263.761 73.6485 264.095 73.8026 264.301 74.1107C264.512 74.4132 264.618 74.8297 264.618 75.3604V77.9965H263.358V75.523C263.358 75.2148 263.316 75.0037 263.23 74.8896C263.15 74.7755 263.019 74.7184 262.836 74.7184C262.676 74.7184 262.539 74.7555 262.425 74.8297C262.311 74.8982 262.185 74.998 262.048 75.1293V77.9965H260.789Z"
							fill="#4451A2"
						/>
						<path
							d="M267.327 78.0992C266.801 78.0992 266.425 77.948 266.196 77.6456C265.973 77.3432 265.862 76.9466 265.862 76.4559V74.7355H265.28V73.8026L265.931 73.7513L266.076 72.6215H267.121V73.7513H268.141V74.7355H267.121V76.4388C267.121 76.6784 267.17 76.8525 267.267 76.9609C267.37 77.0636 267.504 77.115 267.67 77.115C267.738 77.115 267.807 77.1064 267.875 77.0893C267.949 77.0722 268.015 77.0522 268.072 77.0294L268.269 77.9452C268.161 77.9794 268.026 78.0137 267.867 78.0479C267.712 78.0821 267.532 78.0992 267.327 78.0992Z"
							fill="#4451A2"
						/>
					</g>
					<path
						d="M327.032 56.5596H206.757V56.4351C206.757 55.8704 206.3 55.4126 205.736 55.4126H196.73C196.166 55.4126 195.709 55.8704 195.709 56.4351V56.5596H194.113C189.796 56.5596 186.297 60.0654 186.297 64.3897V77.4333H186.172C185.609 77.4333 185.152 77.8912 185.152 78.4559V87.4781C185.152 88.0427 185.609 88.5006 186.172 88.5006H186.297V90.6525H186.172C185.609 90.6525 185.152 91.1103 185.152 91.675V100.697C185.152 101.262 185.609 101.72 186.172 101.72H186.297V244.344C186.297 248.668 189.796 252.175 194.114 252.175H327.032C331.349 252.175 334.85 248.669 334.85 244.344V64.3897C334.85 60.0654 331.35 56.5586 327.032 56.5586V56.5596ZM329.454 244.52C329.454 245.76 328.451 246.765 327.213 246.765H193.977C192.739 246.765 191.736 245.76 191.736 244.52V64.4652C191.736 63.225 192.739 62.2202 193.977 62.2202H327.213C328.451 62.2202 329.454 63.225 329.454 64.4652V244.519V244.52Z"
						fill="#4451A2"
					/>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_dd_2282_1963"
					x="139.333"
					y="0"
					width="241.333"
					height="242"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="4" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_2282_1963"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="1" />
					<feGaussianBlur stdDeviation="1.5" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
					/>
					<feBlend
						mode="normal"
						in2="effect1_dropShadow_2282_1963"
						result="effect2_dropShadow_2282_1963"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect2_dropShadow_2282_1963"
						result="shape"
					/>
				</filter>
				<clipPath id="clip0_2282_1963">
					<rect
						x="147.333"
						y="8"
						width="225.333"
						height="226"
						rx="112.667"
						fill="white"
					/>
				</clipPath>
				<clipPath id="clip1_2282_1963">
					<rect
						width="138.667"
						height="195.182"
						fill="white"
						transform="translate(191.455 61.7344)"
					/>
				</clipPath>
				<clipPath id="clip2_2282_1963">
					<rect
						width="46.0036"
						height="45.9861"
						fill="white"
						transform="translate(275.181 157.615)"
					/>
				</clipPath>
				<clipPath id="clip3_2282_1963">
					<rect
						width="47.1538"
						height="45.9861"
						fill="white"
						transform="translate(274.031 103.812)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default PaywallReportIcon;
