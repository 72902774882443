import styled from "@emotion/styled";
import Toggle from "Pages/Settings/Components/TogglePeriod";

// Helpers
import MediaQueries, { minScreenWidth } from "Helpers/WebUIHelpers/Breakpoints";

// Styles
export const Container = styled.div(({ theme }) => ({
	...theme.webFonts.F6,
	textAlign: "center",
}));

export const Head = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	marginBottom: "4rem",
	"& h3": {
		...theme.webFonts.h3,
		marginBottom: "1.6rem",
	},
	[MediaQueries[2]]: {
		marginBottom: "12rem",
	},
}));

export const Table = styled.div(() => ({
	display: "flex",
	alignItems: "stretch",
	justifyContent: "start",
	gap: "2rem",
	overflowX: "auto",
	overflowY: "hidden",
	scrollSnapType: "x mandatory",
	// Top padding is to account for the absolutely positioned icons
	padding: "9rem 0 4rem",
	[MediaQueries[3]]: {
		justifyContent: "center",
	},
	"&::-webkit-scrollbar": {
		display: "none",
	},
}));

export const Spacer = styled.div({ flex: "0 0 28rem" });

export const TogglePeriod = styled(Toggle)({
	marginTop: "3rem",
	[minScreenWidth.medium]: {
		marginTop: "-1.5rem",
	},
});

export const SnapAndAnimate = styled.div<{ index: number }>(({ index }) => ({
	flex: "0 0 28rem",
	scrollSnapAlign: "center",
	scrollSnapStop: "always",
	opacity: "0",
	animation: `simplePricingSlideIn 0.5s ${0.25 * index}s ease-in-out forwards`,
	"@keyframes simplePricingSlideIn": {
		"0%": {
			transform: "translateY(3rem)",
			opacity: 0,
		},
		"100%": {
			transform: "translateY(0)",
			opacity: 1,
		},
	},
}));
