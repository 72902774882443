import React from "react";

// Types
import type IconProps from "Types/IconProps";

const CarbonFootprintIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "carbon-footprint-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "64px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<circle cx="13" cy="6" r="6" fill={fill || "#58B647"} />
			<circle cx="7" cy="22" r="4" fill={fill || "#58B647"} />
			<circle cx="29" cy="4" r="4" fill={fill || "#58B647"} />
			<circle cx="49.5" cy="12.5" r="2.5" fill={fill || "#58B647"} />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.056 31.912C12.6213 21.376 21.3173 13 32 13C42.6827 13 51.3787 21.376 51.944 31.912C58.7973 33.1467 64 39.128 64 46.3333C64 54.432 57.432 61 49.3333 61H14.6667C6.568 61 0 54.432 0 46.3333C0 39.128 5.20267 33.1467 12.056 31.912ZM27.6173 27C15.5056 42.5931 26.1952 47.2858 29.8998 48.383C28.8336 44.0251 28.2298 36.7548 28.5187 32.6659C29.0939 36.1659 30.4342 41.7785 32.1401 45.8364C31.8699 41.8901 34.0284 39.7093 37.1589 38.5873C36.6927 35.3538 34.1697 31.4435 27.6173 27ZM40.8622 44.009C38.7843 45.9009 37.1415 48.14 35.867 50.6233C38.9455 51.4863 45.726 51.9586 45.3181 39.1551C31.3169 38.7063 33.1903 46.858 34.0941 49.3637C36.0443 47.2101 38.3119 45.4199 40.8622 44.009Z"
				fill={fill || "#58B647"}
			/>
		</svg>
	);
};

export default CarbonFootprintIcon;
