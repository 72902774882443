// Types
import type Account from "Types/Account";
import type Entity from "Types/Entity";
import type { BusinessEntity, HomeEntity, SchoolEntity } from "Types/Entity";

const currentYear = new Date().getFullYear().toString();
const lastYear = (new Date().getFullYear() - 1).toString();

const checkSettingsCompletion = (account: Account, entity: Entity): boolean => {
	if (
		account.accountType === "school" &&
		(!(entity as SchoolEntity).established ||
			!(entity as SchoolEntity).level ||
			!(entity as SchoolEntity).sType ||
			!(entity as SchoolEntity).staff?.[currentYear] ||
			!(entity as SchoolEntity).staff?.[lastYear] ||
			!(entity as SchoolEntity).students?.[currentYear] ||
			!(entity as SchoolEntity).students?.[lastYear])
	)
		return false;
	else if (
		account.accountType === "home" &&
		(!(entity as HomeEntity).hType ||
			!(entity as HomeEntity).occupants?.[currentYear] ||
			!(entity as HomeEntity).occupants?.[lastYear])
	) {
		return false;
	} else if (
		account.accountType === "business" &&
		(!account.industry ||
			!(entity as BusinessEntity).staff?.[currentYear] ||
			!(entity as BusinessEntity).staff?.[lastYear])
	) {
		return false;
	} else return true;
};

export default checkSettingsCompletion;
