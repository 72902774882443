import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Helpers
import { commsForUser, refreshCommsSession } from "Helpers/CommsHelpers";

// Types
import CommsProps from "./types";
import useIntercomHash from "Hooks/UseIntercomHash";

const Comms: React.FC<CommsProps> = ({ user, account }) => {
	// Status
	const [status, setStatus] = useState<"off" | "on">("off");

	// Hooks
	const { pathname } = useLocation();
	const [userHash] = useIntercomHash();

	// Helpers
	// Set up intercom session
	useEffect(() => {
		if (status !== "off") return;
		if (user && account && userHash) {
			commsForUser("boot", user, account, userHash);
			setStatus("on");
		}
	}, [account, status, user, userHash]);

	// Update user info
	useEffect(() => {
		if (status !== "on") return;
		if (user && account && userHash) {
			commsForUser("update", user, account, userHash);
		}
	}, [account, status, user, userHash]);

	useEffect(() => {
		if (status === "off") return;
		refreshCommsSession();
	}, [pathname, status]);

	return null;
};

export default Comms;
