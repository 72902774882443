import { BillingPeriod } from "Types/Account";
import { SubscriptionPlan } from "Types/Subscription";

const currencyFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
});

const formatCost = (cost: number): string => {
	return currencyFormatter.format(Number(cost.toFixed(1))).replace(".00", "");
};

export const getDisplayPriceFromPlan = (
	plan: SubscriptionPlan,
	duration?: BillingPeriod,
	rounded = true,
	removeFormatting = false,
): { price: string; totalPrice: string } => {
	const partnerDiscount = plan.discount && plan.discount / 100;

	const getCostPerDuration = (cost: number): number => {
		const costInDollars =
			cost /
			(plan.period === "yearly" && duration === "monthly" ? 12 : 1) /
			100;
		return rounded ? Math.ceil(costInDollars) : costInDollars;
	};

	const getCostWithDiscount = (cost: number): number => {
		return partnerDiscount ? +cost - +cost * partnerDiscount : cost;
	};

	return {
		// Note that price in the plan does not have discount or tax applied
		price: removeFormatting
			? getCostPerDuration(getCostWithDiscount(plan.price)).toString()
			: formatCost(getCostPerDuration(getCostWithDiscount(plan.price))),
		// Note that totalPrice in the plan already has discount and tax applied
		totalPrice: removeFormatting
			? getCostPerDuration(plan.totalPrice).toString()
			: formatCost(getCostPerDuration(plan.totalPrice)),
	};
};

export const getYearOptions = (yearEstablished?: number) => {
	const years: number[] = [];
	const currentYear = new Date().getFullYear();

	for (
		let year = currentYear;
		year >=
		(yearEstablished && yearEstablished > 2014 ? yearEstablished : 2014);
		year--
	) {
		years.push(year);
	}

	return years;
};
