import styled from "@emotion/styled";
import { Link } from "react-router-dom";

// Types
import type { PositionProps } from "./types";

export const CloseContainer = styled.div<PositionProps>(({ leftPosition }) => ({
	width: "100%",
	display: "flex",
	justifyContent: leftPosition ? "flex-start" : "flex-end",
}));

export const CloseButton = styled(Link)(({ theme }) => ({
	cursor: "pointer",
	background: theme.colors.White,
	width: "3.2rem",
	height: "3.2rem",
	borderRadius: "50%",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	border: "none",
	"&:hover,:focus": {
		boxShadow: theme.shadow,
		"> svg > path": {
			fill: theme.colors.primary,
		},
	},
	"&:active": {
		boxShadow: "none",
		transform: "translateY(2px) scale(.98)",
	},
}));
