import React from "react";

// Components
import CheckBoxFormik from "../CCW-CheckBoxFormik";

// Styles
import { Container, Link, Text } from "./styles";

// Types
import { TermsProps } from "./types";

/** Terms and conditions plus email list */
export const Terms: React.FC<TermsProps> = ({
	style,
	className,
	customTerms,
	...props
}) => (
	<Container style={style} className={className}>
		<CheckBoxFormik label="I agree to&nbsp;" {...props} />
		{customTerms ? (
			<Text>
				<strong>{customTerms}</strong>
			</Text>
		) : (
			<Text>
				the
				<Link
					href="https://climatecleverweb.cdn.prismic.io/climatecleverweb/31347415-15ea-46a9-8669-486914ade15b_ClimateClever_Terms_%26_Conditions_2023.pdf"
					target="_blank"
					rel="noopener noreferrer">
					Terms and Conditions
				</Link>
			</Text>
		)}
	</Container>
);

export default Terms;
