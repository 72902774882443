import React from "react";

// Local
import DefaultSetFirstPassword from "./Components/DefaultSetFirstPassword";

const SetFirstPasswordPage = () => {
	return <DefaultSetFirstPassword />;
};

export default SetFirstPasswordPage;
