import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import type { JSONApi, DataHookResponse, ResourceObject } from "Types/JSONApi";
import type { SubscriptionPeriod, SubscriptionPlan } from "Types/Subscription";
import type Partner from "Types/Partner";
import type Account from "Types/Account";
import Platform from "Types/Platform";

/** If a discount is present the partner or account will be linked */
type PlanRelationships = {
	partner: {
		links: { related: string };
		data: { type: "partners"; id: string };
	};
	/** Only included if the account type is not partner */
	account: {
		links: { related: string };
		data: { type: "accounts"; id: string };
	};
};

type Included = (
	| ResourceObject<Partner, undefined, "partners">
	| ResourceObject<Account, undefined, "accounts">
)[];

type PlansRelated = {
	partner: Partner;
	account: Account;
}[];

type PlansResponse = JSONApi<
	SubscriptionPlan[],
	PlanRelationships,
	Included,
	undefined,
	PlansRelated
>;

/**
 * Get the Subscription plans for a platform type
 * @param accountType Type of account for which we want to fetch the subscription plans available
 * @param billingPeriod Billing period (monthly or yearly)
 * @returns a DataHookResponse tuple of SubscriptionPlan, Error, Mutate, Related, the full response and pagination options
 */
const useSubscriptionPlans = (
	accountType?: Platform,
	billingPeriod?: SubscriptionPeriod,
): DataHookResponse<SubscriptionPlan[], PlansRelated, PlansResponse> => {
	const queryString = new URLSearchParams({
		...(accountType ? { "account-type": accountType } : {}),
		...(billingPeriod ? { "billing-period": billingPeriod } : {}),
	}).toString();

	const { data, error, mutate } = useSWR<
		JSONApi<
			SubscriptionPlan[],
			PlanRelationships,
			Included,
			undefined,
			PlansRelated
		>,
		Error
	>(
		accountType && billingPeriod && queryString
			? [
					`${
						import.meta.env.VITE_BASE_URL
					}executive/subscriptions/plans?${queryString}`,
					1,
			  ]
			: null,
		getReq,
		{
			shouldRetryOnError: true,
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	);

	// Prefetch the other billing period so that when the user toggles the data is already cached in SWR
	const prefetchQueryString = new URLSearchParams({
		...(accountType ? { "account-type": accountType } : {}),
		...(billingPeriod
			? { "billing-period": billingPeriod === "monthly" ? "yearly" : "monthly" }
			: {}),
	}).toString();

	const prefetch = useSWR(
		prefetchQueryString
			? [
					`${
						import.meta.env.VITE_BASE_URL
					}executive/subscriptions/plans?${prefetchQueryString}`,
					1,
			  ]
			: null,
		getReq,
		{
			shouldRetryOnError: false,
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	);

	const [plans, related, fullJSONApiResponse] = transformAPIResponse(data, []);

	return [plans, error, mutate, related, fullJSONApiResponse];
};

export default useSubscriptionPlans;
