import styled from "@emotion/styled";

// Helpers
import mediaQueries from "Helpers/WebUIHelpers/Breakpoints";

// Base Styles
import ButtonLink from "CCW-Components/CCW-ButtonLink";
import CCPartnerButton from "CCW-Components/CCW-PartnerButton";

// Styles
export const Heading = styled.h4(({ theme }) => ({
	...theme.webFonts.h4,
	color: theme.colors.D1,
	width: "32rem",
	whiteSpace: "pre-wrap",
	[mediaQueries[2]]: {
		width: "54rem",
	},
}));

export const Highlighted = styled.span(({ theme }) => ({
	color: theme.colors.secondary,
}));

export const PrimaryText = styled.span(({ theme }) => ({
	...theme.webFonts.F5Bold,
	color: theme.colors.D1,
}));

export const SecondaryText = styled.span(({ theme }) => ({
	...theme.webFonts.F5,
	color: theme.colors.D3,
}));

export const PickerContainer = styled.div(() => ({
	marginTop: "2.4rem",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "space-between",
	gap: "1.6rem",
	[mediaQueries[2]]: {
		flexDirection: "row",
	},
}));

export const PartnerButton = styled(CCPartnerButton)(() => ({
	width: "100%",
	[mediaQueries[2]]: {
		maxWidth: "26rem",
	},
}));

export const OptOutContainer = styled.div(() => ({
	marginTop: "1.5rem",
	display: "flex",
	alignItems: "center",
	[mediaQueries[2]]: {
		marginTop: "2rem",
	},
}));

export const RightAlign = styled.div(() => ({
	textAlign: "right",
}));

export const Button = styled(ButtonLink)(() => ({
	marginTop: "4rem",
}));
